import React from 'react';
import ReactDOM from 'react-dom';
import {usePromiseTracker} from 'react-promise-tracker';

const Loader = () => {
    const {promiseInProgress} = usePromiseTracker();

    promiseInProgress ? document.querySelector('html').classList.add('overlay') : document.querySelector('html').classList.remove('overlay');

    return ReactDOM.createPortal(
        promiseInProgress ?
            <div className="loader uiloader">
                <svg className="spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                </svg>
            </div> : null,
        document.querySelector('#root')
    );
};

export default Loader;
