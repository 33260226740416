import React, { Component } from 'react';

class Checkbox extends Component {
    state = {};

    render() {
        return (
            <label className="checkbox-wrapper">
                {this.props.label}
                <input type="checkbox" disabled={this.props.disabled ? this.props.disabled :false } id={this.props.name} onChange={this.props.onChangeHandler} checked={this.props.checked !== undefined ? this.props.checked : false}/>
                <span className="checkmark"></span>
            </label>
        );
    }
}

export default Checkbox;