import { connect } from "react-redux";
import VisitPlan from "../components/Visit/VisitPlan";
import {
  SET_CUSTOMER_ADDRESS,
  SET_FORM_PROGRESS,
  UPDATE_CUSTOMER_INFO,
  SET_MOCK_DATA,
  SET_QUICK_SCAN_INFO,
  RESET_VISIT_FORM,
  UPDATE_DESIGN_IMAGE,
  ADD_ROOF_TYPES,
  SET_VISIT_ID,
  SET_CALCULATION_ID,
  SET_CUSTOMER_QUOTE,
  RESET_FORM_PROGRESS,
  SET_NEW_VISIT,
  SET_VISIT_STATUS,
} from "../state/actions/actionTypes";

const mapStateToProps = (state) => {
  return {
    visit: state.visit,
    progress: state.progress,
    contractor: state.contractor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAddressHandler: (address) => {
      dispatch({
        type: SET_CUSTOMER_ADDRESS,
        address: address,
      });
    },
    submitCustomerInfo: (customer) => {
      dispatch({
        type: UPDATE_CUSTOMER_INFO,
        customer: customer,
      });
    },
    setQuickScanInfo: () => {
      dispatch({
        type: SET_QUICK_SCAN_INFO,
      });
    },
    setFormProgress: (progress) => {
      dispatch({
        type: SET_FORM_PROGRESS,
        progress: progress,
      });
    },
    submitMockData: (mock) => {
      dispatch({
        type: SET_MOCK_DATA,
        mock: mock,
      });
    },
    resetForm: () => {
      dispatch({
        type: RESET_VISIT_FORM,
      });
      dispatch({
        type: RESET_FORM_PROGRESS,
      });
    },
    setDesign: (image) => {
      dispatch({
        type: UPDATE_DESIGN_IMAGE,
        design: image,
      });
    },
    setRoofs: (roofs) => {
      dispatch({
        type: ADD_ROOF_TYPES,
        roofs: roofs,
      });
    },
    setVisitId: (id) => {
      dispatch({
        type: SET_VISIT_ID,
        id: id,
      });
    },
    setVisitStatusId: (visitStatusId) => {
      dispatch({
        type: SET_VISIT_STATUS,
        visitStatusId: visitStatusId,
      });
    },
    setCalculationId: (id) => {
      dispatch({
        type: SET_CALCULATION_ID,
        id: id,
      });
    },
    setCustomerQuote: (quote) => {
      dispatch({
        type: SET_CUSTOMER_QUOTE,
        quote: quote,
      });
    },
    setIsNewVisit: (isNewVisit) => {
      dispatch({
        type: SET_NEW_VISIT,
        isNewVisit: isNewVisit,
      });
    },
  };
};

const VisitPlanContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VisitPlan);

export default VisitPlanContainer;
