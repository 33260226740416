import React, { Component } from 'react';
import { useSelector } from 'react-redux';

const Button = (props) => {
    const installer = useSelector((state) => state.contractor);

    const {onClickHandler} = props;

        return (
            <button className={`btn ${props.class} ${props.color} ${props.size} ${props.iconRight ? 'right' : ''}`}
                    style={{backgroundColor:installer.theme}}
                    disabled={props.isDisabled}
                    onClick={onClickHandler}
                    type={props.type}
                    form={props.form}
            >
                {props.icon ? props.icon : false}
                {props.label}
            </button>
        );
}

export default Button;
