import React, { Component } from "react";
import Button from "../Ui/Buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faPlus,
  faPlusCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { ChromePicker } from "react-color";
import LogoUpload from "./LogoUpload";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import constants from '../../global/Constants'

class Style extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
      color: "#0094c5",
      installer:
        this.props.contractor.installer.token !== ""
          ? this.props.contractor
          : JSON.parse(sessionStorage.getItem("installer")),
      popup: false,
      logoImg: this.props.contractor.companyLogo,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updatePhoto = this.updatePhoto.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.handleStyleUpdate = this.handleStyleUpdate.bind(this);
  }

  handleClick() {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  }

  handleClose() {
    this.setState({ displayColorPicker: false });
  }

  handleChange(color) {
    this.setState({ color: color.hex });
  }
  togglePopup() {
    this.setState({ popup: !this.state.popup });
  }

  updatePhoto() {
    this.togglePopup();
  }

  updateSession = (logo) => {
      const installerStyleUpdate = this.state.installer;
      if(logo) {
          installerStyleUpdate.companyLogo = logo;
      }
      installerStyleUpdate.colorCode = this.state.color;
      sessionStorage.setItem("installer", JSON.stringify(installerStyleUpdate));
      this.props.setInstallerHandler(installerStyleUpdate);
  }

  postColorCode = () => {
      console.log("postColorCode");
      //const formdata = new FormData();
      //formdata.append('installer', JSON.stringify({id:this.state.installer.installer.installerId}));
      //formdata.append('colorCode',this.state.color)    
      let formdata = {
        id: this.state.installer.installer.installerId,
        colorCode: this.state.color
      };
      console.log("formdata: "+JSON.stringify(formdata));
      const config = { headers: {'Content-Type': 'application/json', Authorization:`Bearer ${this.state.installer.installer.token}`}};
      trackPromise(
          axios.put(`${constants.api.api}${constants.api.colorCodeUpdate}`,formdata, config )
          .then((response) => {
              console.log(response.data);
          })
          .catch((error) => console.error(error))
      );
  }

  handleStyleUpdate() {
      if(typeof this.state.logoImg === "object") {
          const formdata = new FormData();
          formdata.append('installer', JSON.stringify({id:this.state.installer.installer.installerId}));
          formdata.append('companyLogo',this.state.logoImg)    
          const config = { headers: {'Content-Type': 'multipart/form-data', Authorization:`Bearer ${this.state.installer.installer.token}`}};
          trackPromise(
              axios.put(`${constants.api.api}${constants.api.companyLogoUpdate}`,formdata, config )
              .then((response) => {
                  this.updateSession(response.data.companyLogo)
              })
              .catch((error) => console.log(error))
          );
      }else {
          this.updateSession(null);
      }
      this.postColorCode();
  }

  handleImgSelect = (img) => {
    this.setState({ logoImg: img })
  };

  render() {
    const popover = {
      position: "absolute",
      zIndex: "2",
    };
    const cover = {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    };
    return (
      <React.Fragment>
        <LogoUpload
          showPopup={this.state.popup}
          submitImageHandler={(img) => this.handleImgSelect(img)}
          handleClose={this.togglePopup}
        />
        <div className="row-padding flex-center">
          <div className="column small-12 medium-6">
            {/* <h2>Application style customisation</h2> */}
            <h2>Aanpassen</h2>
            <div className="text-wrapper">
              {/* <label>Pick color theme</label> */}
              <div
                style={{ background: this.state.color }}
                className="color-display"
              >
                <button onClick={this.handleClick} className="style-picker-btn">
                  {/* Pick Color */}
                  Selecteer een kleur
                </button>
                {this.state.displayColorPicker ? (
                  <div style={popover}>
                    <div style={cover} onClick={this.handleClose} />
                    <ChromePicker
                      color={this.state.color}
                      onChange={this.handleChange}
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div className="logo-update"> 
             <div className= "container-hov"   >
              <img
                
                src={
                  typeof this.state.logoImg === "object"
                    ? URL.createObjectURL(this.state.logoImg)
                    : this.state.logoImg
                }
                alt={this.state.logoImg}
                className="upload-photo"
                key={this.state.logoImg}
                onClick={(event) => this.updatePhoto(event)}
              /> 
                     
               <div className="middle">
               {/* <h3 class="content-title">This is a title</h3> */}
                  <div className="text"  onClick={(event) => this.updatePhoto(event)}> 
                      {/* Click to change the image */}
                      Klik om logo aan te passen
                  </div>
               </div>
            
              </div>
            </div>
           <div className = "button-update-theme">
            <Button
              size="large"
              color="blue"
              // label="Update Theme"
              label="Sla wijzigingen op"
              isDisabled={false}
              onClickHandler={this.handleStyleUpdate}
            />
            </div> 
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Style;
