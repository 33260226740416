import React, {useState} from 'react';
import { useSelector } from 'react-redux';

const Tabs = ({tabItems}) => {
    const [active, setActive] = useState(0);
    const contractor = useSelector((state) => state.contractor);

    const tabList = tabItems.map((tab, index) => {
        return (
            <div key={index} className={active === index ? 'tab-button tab-button--active' : 'tab-button'} title={tab.title} onClick={() => setActive(index)}>
                {tab.title}
            </div>
        );
    });

    const contentList = tabItems.map((tab, index) => {
        return (
            <div key={index} className={active === index ? 'tab-item tab-item--active' : 'tab-item'}>
                {tab.content}
            </div>
        );
    });

    return (
        <div className="tabs-wrapper">
            <div className="tabs-buttons">
                {tabList}
            </div>
            <div className="tabs-content">
                {contentList}
            </div>
            <style>
                
                <style>
                {`
                \
                .tabs-buttons .tab-button.tab-button--active{\
                    
                    background-color:${contractor.theme};\
                }\
                \
                .tabs-content{\
                    border-top: 3px solid ${contractor.theme};\
                }\
                
                `}
                </style>
            </style>
        </div>
    )
};

export default Tabs;
