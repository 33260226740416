import React, { Component } from 'react';
import TopHeader from '../components/Ui/TopHeader';
import ContentSection from '../components/Ui/ContentSection';
import Footer from '../components/Ui/Footer';
import VisitProductsContainer from "../containers/VisitProductsContainer";

class VisitProductsView extends Component {
    render() {
        const productsVisit = <VisitProductsContainer history={this.props.history} />;

        return (
            <React.Fragment>
                <TopHeader title="Solar4All" />
                <ContentSection content={productsVisit} />
                <Footer image={false} />
            </React.Fragment>
        );
    }
}

export default VisitProductsView;
