import React, { Component } from "react";
import ContractorContainer from "../../containers/ContractorContainer";
import Button from "../Ui/Buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import ResetPopup from "../Ui/ResetPopup";
import NavigationPrompt from "react-router-navigation-prompt";
import ProgressBar from "../Ui/ProgressBar";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// import {Document, Page} from 'react-pdf';
import { fetchWithDelay } from "../../global/FetchWithDelay";
import { trackPromise } from "react-promise-tracker";
import constants from "../../global/Constants";

class VisitInfo extends Component {
  constructor(props) {
    super(props);

    this.next = "/visit-resume";
    this.previous = "/visit-setup";
    this.redirect = "/visit-plan";

    this.state = {
      formStep: 3,
      numberPages: [],
      pageNumber: 1,
      installer:this.props.contractor.installer.token !== "" ? this.props.contractor.installer : JSON.parse(sessionStorage.getItem("installer")),
      themeColor:this.props.contractor,
      pdfFile: "",
      pdfDataRecived: false,
    };
  }

  onDocumentLoadSuccess = (document) => {
    let pages = [];

    for (let i = 0; i < document.numPages; i++) {
      pages.push(i);
    }

    this.setState({
      numberPages: pages,
    });
  };

  previousPage = () => {
    this.props.setFormProgress([1, 2]);
    this.props.history.push(this.previous);
  };

  nextPage = (event) => {
    event.preventDefault();
    this.props.setFormProgress([1, 2, 3, 4]);
    this.props.history.push(this.next);
  };

  componentDidMount() {
    const apiUrl = `${constants.api.getPdfFile}/${this.props.visit.id}`;
    trackPromise(
      fetchWithDelay(apiUrl, 0, this.state.installer.token).then(async(data) => {
        if (data.isSuccess) {
          await this.setState({ pdfFile: data.quotePdf, pdfDataRecived: true });
        }
      })
    );
  }


  render() {
    const {theme} = this.state.themeColor;
    return (
      <React.Fragment>
        <NavigationPrompt
          when={(current, target) =>
            ![this.previous, this.next, current.pathname].includes(
              target.pathname
            )
          }
          afterConfirm={() => this.props.resetForm()}
        >
          {({ onConfirm, onCancel }) => (
            <ResetPopup
              showPopup={true}
              onConfirm={onConfirm}
              onCancel={onCancel}
            />
          )}
        </NavigationPrompt>
        <div className="section-logo flex-end flex-align-center">
          <h2 className="address-title" style={{color:theme}}>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            {`${this.props.visit.customer.address.street} ${
              this.props.visit.customer.address.number
            }, ${
              this.props.visit.customer.address.city
            }, ${this.props.visit.customer.address.country.toUpperCase()}`}
          </h2>
          <ContractorContainer />
        </div>
        {!this.props.visit.visitPageLocation && (
          <ProgressBar progress={this.props.progress} />
        )}


        <div className="row-padding flex-center ">
          <div className={`column small-12 ${this.props.history ? 'medium-8':'' }`}>
            <h3 className="display-inline" style={{color:theme}}>Offerte</h3>
            

            <form onSubmit={(event) => this.nextPage(event)} id="visitInfo" className="quote-pdf"
              style={{borderTopColor:theme}}
            >
             
     
           <embed 
           src={this.state.pdfDataRecived? this.state.pdfFile: require("../../assets/pdf/no_pdf_found.pdf")} 
           className="pdf-file-dimentions"  pluginspage="http://www.adobe.com/products/acrobat/readstep2.html" 
           key={this.state.pdfDataRecived? this.state.pdfFile: require("../../assets/pdf/no_pdf_found.pdf")}
           /> 
        
            </form>
          </div>
        </div>


        {!this.props.visit.visitPageLocation && (
          <div className="row-padding navigation-buttons">
            <Button
              icon={<FontAwesomeIcon icon={faChevronLeft} />}
              size="large"
              color="green"
              label="Vorige"
              onClickHandler={this.previousPage}
              isDisabled={false}
            />
            {this.state.pdfDataRecived && (
              <a
                className="visit-pdf-download"
                download="file.pdf"
                href={this.state.pdfFile}
                target="_blank"
              >
                Download
              </a>
            )}
             
            <Button
              icon={<FontAwesomeIcon icon={faChevronRight} />}
              iconRight={true}
              size="large"
              color="green"
              label="Volgende"
              type="submit"
              form="visitInfo"
              isDisabled={false}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default VisitInfo;
