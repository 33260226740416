import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle, faCamera} from "@fortawesome/free-solid-svg-icons";
import Button from "../../Ui/Buttons/Button";
import Input from "../../Ui/Inputs/Input";
import {clearAllBodyScrollLocks, disableBodyScroll} from "body-scroll-lock";

const PhotoPreview = ({
    showPopup,
    handleClose,
    details
}) => {
   
    const bodyElement = document.querySelector('body');

    const handleCloseClick = () => {
        handleClose();
    };

    
   
   

    showPopup ? document.querySelector('html').classList.add('overlay') : document.querySelector('html').classList.remove('overlay');
    showPopup ? disableBodyScroll(bodyElement) : clearAllBodyScrollLocks();

    return ReactDOM.createPortal(
        showPopup ?
            <div className="popup-container">
                <div className="row-padding flex-center popup-wrapper">
                    <div className="column small-12 medium-8 large-6 popup-content">
                        <div className="popup-top">
                            <h2>Foto maken</h2>
                            <FontAwesomeIcon icon={faTimesCircle} className="popup-close" onClick={event => handleCloseClick(event)} />
                        </div>
                        <div className="popup-photo-upload">
                            <h3>Foto</h3>
                            <div className="upload-wrapper">
                                
                                <div className={`upload-preview show-preview`}>
                                    <img id="previewImage" alt="Preview" 
                                    src={typeof details.photoContent === "object" ? URL.createObjectURL(details.photoContent) :details.photoContent }
                                    
                                    />
                                </div>
                            </div>
                            <Input
                                type="text"
                                name="description"
                                label="Omschrijving"
                                placeholder="Omschrijving"
                                defaultValue={details.photoDescription}
                            />
                            
                        </div>
                    </div>
                </div>
            </div> : null,
        document.querySelector('#root')
    );
};

export default PhotoPreview;
