import React, { Component } from "react";
import TableToday from "./TableToday";
import VisitTableTomorrow from "./TableTomorrow";
import Button from "../Ui/Buttons/Button";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      installer: this.props.contractor.installer.token !== "" ? this.props.contractor.installer : JSON.parse(sessionStorage.getItem("installer")),
      themeColor:this.props.contractor
    };
  }


  submitQuickScan = () => {
    this.props.resetForm();
    this.props.setQuickScan();
    this.props.history.push("/visit-plan");
  };

  render() {
    return (
      <React.Fragment>
        <div className="row-padding flex-center">
          <div className="column small-12 medium-12">
            <TableToday installer={this.state.installer} {...this.props} theme={this.props.contractor} />
            {this.props.from && (
              <div className="visits-toolbar flex-end flex-align-baseline">
                <Link to={"/visit-plan"}>
                  <Button
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    size="small"
                    color="yellow"
                    label="Nieuw bezoek"
                    isDisabled={false}
                  />
                </Link>
              </div>
            )}

            <VisitTableTomorrow
              installer={this.state.installer}
              {...this.props}
              from={this.props.from}
              theme={this.props.contractor}
            />
            <div className="flex-center">
              <Button
                icon={false}
                onClickHandler={this.submitQuickScan}
                size="large"
                color="green"
                label="Quick scan"
                isDisabled={false}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Table;
