// Home		https://platformvisions.retool.com/embedded/public/310b3444-f3f7-43c9-b6cf-b57b997b0281?id=[InstallerId]
// Afspraken		https://platformvisions.retool.com/embedded/public/132fc746-e246-41d8-bb01-610ec633e536?id=[InstallerId]
// Planning		Remove Item
// Projecten		https://platformvisions.retool.com/embedded/public/847fc3c4-fd81-4bcd-a878-7e841ae716e0?id=[InstallerId]
// Oplevering		https://platformvisions.retool.com/embedded/public/d38b81fb-8182-4b8d-9b7b-703d19e8f6da?id=[InstallerId]
// Taken		https://platformvisions.retool.com/embedded/public/2d96ae55-8714-4d48-ba27-758f77957592?id=[InstallerId]
// Dashboard		https://platformvisions.retool.com/embedded/public/0fbaf882-8e5d-42ec-829d-acb3b34896b6?id=[InstallerId]
// Tickets		No change needed
// Instellingen		No change needed
// Uitloggen		No change needed
// https://platformvisions.retool.com/embedded/public/9e9317f3-e086-4d76-961a-fd9cc381595f#InstallerId={{urlparams.id}}&CustomerId={{GetCustomerById.data.CustomerId['0']}}&SelectTab=1

export const urls = {
    home:`https://platformvisions.retool.com/embedded/public/310b3444-f3f7-43c9-b6cf-b57b997b0281?id=`,
    Afspraken:`https://platformvisions.retool.com/embedded/public/132fc746-e246-41d8-bb01-610ec633e536?id=`,
    Projecten:'https://platformvisions.retool.com/embedded/public/847fc3c4-fd81-4bcd-a878-7e841ae716e0?id=',
    Oplevering:'https://platformvisions.retool.com/embedded/public/9e9317f3-e086-4d76-961a-fd9cc381595f#InstallerId=',
    taken:'https://platformvisions.retool.com/embedded/public/2d96ae55-8714-4d48-ba27-758f77957592?id=',
    Dashboard:'https://platformvisions.retool.com/embedded/public/0fbaf882-8e5d-42ec-829d-acb3b34896b6?id=',
    Tickets:'',
    Instellingen:'',
    Uitloggen:'',

}

