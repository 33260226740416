export const UPDATE_CUSTOMER_INFO = "UPDATE_CUSTOMER_INFO";
export const SET_QUICK_SCAN = "SET_QUICK_SCAN";
export const SET_QUICK_SCAN_INFO = "SET_QUICK_SCAN_INFO";
export const UPDATE_CONTRACTOR_INFORMATION = "UPDATE_CONTRACTOR_INFORMATION";
export const UPDATE_VISIT_IMAGES = "UPDATE_VISIT_IMAGES";
export const REMOVE_VISIT_IMAGES = "REMOVE_VISIT_IMAGES";
export const UPDATE_DESIGN_IMAGE = "UPDATE_DESIGN_IMAGE";
export const SET_PLAIN_DESIGN = "SET_PLAIN_DESIGN";
export const SET_MARKED_DESIGN = "SET_MARKED_DESIGN";
export const UPDATE_ROOF_INFO = "UPDATE_ROOF_INFO";
export const ADD_ROOF_TYPES = "ADD_ROOF_TYPES";
export const SET_CUSTOMER_ADDRESS = "SET_CUSTOMER_ADDRESS";
export const SET_FORM_PROGRESS = "SET_FORM_PROGRESS";
export const RESET_FORM_PROGRESS = "RESET_FORM_PROGRESS";
export const RESET_VISIT_FORM = "RESET_VISIT_FORM";
export const SET_MOCK_DATA = "SET_MOCK_DATA";
export const SET_PRODUCT_CHOISE = "SET_PRODUCT_CHOISE";
export const SET_COMMENTS = "SET_COMMENTS";
export const SET_CUSTOMER_SIGNATURE = "SET_CUSTOMER_SIGNATURE";
export const REMOVE_CUSTOMER_SIGNATURE = "REMOVE_CUSTOMER_SIGNATURE";
export const SET_CUSTOMER_NEWSLETTER = "SET_CUSTOMER_NEWSLETTER";
export const SET_CONTRACTOR_INSTALLER = "SET_CONTRACTOR_INSTALLER";
export const SET_VISIT_ID = "SET_VISIT_ID";
export const SET_NEW_VISIT = "SET_NEW_VISIT";
export const SET_CALCULATION_ID = "SET_CALCULATION_ID";
export const SET_CUSTOMER_QUOTE = "SET_CUSTOMER_QUOTE";
export const SET_VISIT_IMAGES = "SET_VISIT_IMAGES";
export const RESET_QUICK_SCAN = "RESET_QUICK_SCAN";
export const CUSTOMER_DETAILS = "CUSTOMER_DETAILS";
export const SET_VISIT_STATUS = "SET_VISIT_STATUS";
export const SET_VISIT_PG_LOCATION = "SET_VISIT_PG_LOCATION";
export const SET_QUOTE_STATUS = "SET_QUOTE_STATUS";