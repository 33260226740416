import React, { Component, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';
import SideBar from './SideBar';
import {headerBackBtnPath, routePath} from '../../global/RouteConstants';
import { useSelector } from 'react-redux';

const TopHeader = (props) => {
    const installer = useSelector((state) => state.contractor);

    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => setShowModal(!showModal);


        showModal ? document.querySelector('html').classList.add('overlay') : document.querySelector('html').classList.remove('overlay');

        return (
            <React.Fragment>
                {!props.hideSideBar ?
                    <SideBar
                        show={showModal}
                        handleModalClose={toggleModal}
                        history={props.history}
                        theme={installer}
                    >
                    </SideBar>
                    : null}
                    {/*  */}
                <div className="topheader" style={{background:installer.theme}} >
                    
                    <header className="container" >
                        <nav className="navbar flex-start flex-align-center">
                        
                            {!props.hideSideBar ?
                                <div className="navbar-control">
                                    <FontAwesomeIcon icon={faBars} className="button" onClick={toggleModal} />
                                </div>
                                : null}
                                {/* <span> {"<"} </span> */}
                            <div className="navbar-title">
                                <h3>{props.title}</h3>
                            </div>
                            {!props.hideSideBar && 
                            headerBackBtnPath.includes(window.location.pathname) && 
                            <span className="navbar-goBack">
                                <FontAwesomeIcon  icon={faChevronCircleLeft} className="button" onClick={() => window.location.pathname === routePath.userDetail ?
                                    props.history.push(routePath.crm):props.history.push(routePath.home)} />
                                        {/*   */}
                            </span>
                            }
                        </nav>
                    </header>
                </div>
            </React.Fragment>
        );
    
}

export default TopHeader;