import React from 'react'
import { useSelector } from 'react-redux'
import Footer from '../components/Ui/Footer'
import TopHeader from '../components/Ui/TopHeader'

const Home = (props) => {
    // const {installerId} = useSelector((state) => state.contractor);
    
    return (
        <React.Fragment>
        <TopHeader title="Admin" history={props.history} />
        <div className="user-manager">
          <iframe
          allowFullScreen
          allowTransparency
          height="100%"
          width="100%"
            id="hubspot-frame"
            src={`https://platformvisions.retool.com/embedded/public/7a3a4c67-2d1b-43aa-9a20-994917cc5580`}
          >
          </iframe>
        </div>
        <Footer image={false} />
        <script>
          
        </script>
      </React.Fragment>
    )
}

export default Home
