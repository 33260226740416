export const DeleteWithDelay = (url, time, token) => {
    const promise = new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(fetch(url, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json()));
        }, time)
    });

    return promise;
};
