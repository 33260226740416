import { connect } from 'react-redux';
import VisitResume from "../components/Visit/VisitResume";
import {
    SET_FORM_PROGRESS,
    RESET_VISIT_FORM,
    SET_CUSTOMER_SIGNATURE,
    SET_CUSTOMER_NEWSLETTER,
    REMOVE_CUSTOMER_SIGNATURE,
    RESET_FORM_PROGRESS,
    RESET_QUICK_SCAN
} from '../state/actions/actionTypes'

const mapStateToProps = (state) => {
    return {
        visit: state.visit,
        progress: state.progress,
        contractor: state.contractor
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        submitNewsletter: (newsletter) => {
            dispatch({
                type: SET_CUSTOMER_NEWSLETTER,
                newsletter: newsletter
            });
        },
        submitSignature: (signature) => {
            dispatch({
                type: SET_CUSTOMER_SIGNATURE,
                signature: signature
            });
        },
        removeSignature: () => {
            dispatch({
                type: REMOVE_CUSTOMER_SIGNATURE
            });
        },
        setFormProgress: (progress) => {
            dispatch({
                type: SET_FORM_PROGRESS,
                progress: progress
            });
        },
        resetForm: () => {
            dispatch({
                type: RESET_VISIT_FORM
            });
            dispatch({
                type: RESET_FORM_PROGRESS
            });
        },
        resetQuickScan: () => {
            dispatch({
                type: RESET_QUICK_SCAN
            });
        }
    };
};

const VisitResumeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(VisitResume);

export default VisitResumeContainer;
