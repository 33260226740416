export const routePath = {
  login: "/",
  home: "/home",
  ResourcePlanner: "/resourceplanner",
  visitPlan: "/visit-plan",
  visitInfo: "/visit-info",
  visitRoof: "/visit-roof",
  visitSetup: "/visit-setup",
  visitResume: "/visit-resume",
  settings: "/settings",
  crm: "/crm",
  userDetail: "/user-detail",
  managementSystem: "/projecten",
  dashboard:"/dashboard",
  tickets:"/tickets",
  assemblyPlanning:"/planning",
  adminHome:"/admin/home",
  admin:"/admin",
  taken:"/taken",
  adminCrm:"/admin/crm",
  projecten:"/Projecten",
  mijn_agenda:"/mijn-agenda",
  afspraken:'/afspraken',
  oplevering:'/Oplevering'
};

export const headerBackBtnPath = [
  routePath.visitPlan,
  routePath.settings,
  routePath.crm,
  routePath.userDetail,
];
