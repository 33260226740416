import { connect } from 'react-redux';
import ResourcePlanner from '../components/Resourceplanning/ResourcePlanner'

const mapStateToProps = (state) => {
    return {
        contractor: state.contractor,
    };
};


const ResourcePlannerContainer = connect(mapStateToProps)(ResourcePlanner);

export default ResourcePlannerContainer;
