import React, { Component } from 'react';
import TopHeader from '../components/Ui/TopHeader';
import ContentSection from '../components/Ui/ContentSection';
import Footer from '../components/Ui/Footer';
import VisitInfoContainer from "../containers/VisitInfoContainer";

class VisitInfoView extends Component {
    render() {
        const infoVisit = <VisitInfoContainer history={this.props.history} />;

        return (
            <React.Fragment>
                <TopHeader title="Solar4All" />
                <ContentSection content={infoVisit} />
                <Footer image={false} />
            </React.Fragment>
        );
    }
}

export default VisitInfoView;
