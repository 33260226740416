import { connect } from "react-redux";
import Style from "../components/Styles/Style";
import { SET_CONTRACTOR_INSTALLER } from "../state/actions/actionTypes";


const mapStateToProps = (state) => {
  return {
    contractor: state.contractor
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      setInstallerHandler: (installer) => {
          dispatch({
              type: SET_CONTRACTOR_INSTALLER,
              installer: installer
          });
      }
  };
};
const StyleContainer = connect(mapStateToProps, mapDispatchToProps)(Style)

export default StyleContainer;
