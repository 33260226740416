import { connect } from "react-redux";
import VisitInfo from "../components/Visit/VisitInfo";
import {
  SET_FORM_PROGRESS,
  UPDATE_ROOF_INFO,
  RESET_VISIT_FORM,
  RESET_FORM_PROGRESS,
} from "../state/actions/actionTypes";

const mapStateToProps = (state) => {
  return {
    visit: state.visit,
    progress: state.progress,
    contractor: state.contractor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRoofsHandler: (roofs) => {
      dispatch({
        type: UPDATE_ROOF_INFO,
        roofs: roofs,
      });
    },
    setFormProgress: (progress) => {
      dispatch({
        type: SET_FORM_PROGRESS,
        progress: progress,
      });
    },
    resetForm: () => {
      dispatch({
        type: RESET_VISIT_FORM,
      });
      dispatch({
        type: RESET_FORM_PROGRESS,
      });
    },
  };
};

const VisitInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VisitInfo);

export default VisitInfoContainer;
