import React, { Component } from 'react';
import ContractorContainer from "../../containers/ContractorContainer";
import Button from "../Ui/Buttons/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight, faMapMarkerAlt, faCheckCircle, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import ResetPopup from "../Ui/ResetPopup";
import NavigationPrompt from "react-router-navigation-prompt";
import constants from "../../global/Constants";
import Select from "../Ui/Inputs/Select";
import {trackPromise} from "react-promise-tracker";
import {fetchWithDelay} from "../../global/FetchWithDelay";
import Popup from "../Ui/Popup";
import Calculation from "./Calculation/Calculation";
import  ProgressBar  from "../Ui/ProgressBar";
class VisitProducts extends Component {
    constructor(props) {
        super(props);

        this.next = '/visit-resume';
        this.previous = '/visit-info';
        this.redirect = '/visit-plan';

        this.state = {
            formStep: 4,
            popup: {
                show: false,
                message: '',
                title: ''
            },
            panels: [],
            converters: []
        };

        this.placeholerImage = constants.placeholderImage;
    }

    componentDidMount() {
        this.getProductsInfo();
    }

    getProductsInfo = () => {
        trackPromise(
            fetchWithDelay(constants.api.products, 20)
                .then((data) => {
                    this.setState({
                        panels: data.results.panels,
                        converters: data.results.converters,
                    });
                })
                .catch(error => {
                    this.showPopup(error.toString(), 'Foutmelding');
                })
        );
    };

    setProductSpecifications = (choise, type) => {
        const chosenProduct = this.state[type].find(product => product.type === (choise.split(' - ')[1]));
        const emptyProduct = {};

        this.props.submitProductChoise(chosenProduct !== undefined ? chosenProduct : emptyProduct, type);
    };

    createProductNames = (products) => {
        let names = ['Kies een product'];

        products.forEach((product) => {
            names.push(product.brand + ' - ' + product.type)
        });

        return names;
    };

    showPopup = (messagePopup, titlePopup) => {
        this.setState({
            popup: {
                show: true,
                message: messagePopup,
                title: titlePopup
            }
        });
    };

    hidePopup = () => {
        this.setState({
            popup: {
                show: false,
                message: '',
                title: ''
            }
        });
    };

    previousPage = () => {
        this.props.setFormProgress([1, 2, 3]);
        this.props.history.push(this.previous);
    };

    nextPage = (event) => {
        event.preventDefault();
        this.props.setFormProgress([1, 2, 3, 4, 5]);
        this.props.history.push(this.next);
    };

    render() {
        if (!this.props.progress.includes(this.state.formStep)) {
            this.props.history.push(this.redirect);

            return null;
        } else {
            const products = this.props.visit.products;

            return (
                <React.Fragment>
                    <NavigationPrompt
                        when={(current, target) => ![this.previous, this.next, current.pathname].includes(target.pathname)}
                        afterConfirm={() => this.props.resetForm()}
                    >
                        {({ onConfirm, onCancel }) => (
                            <ResetPopup
                                showPopup={true}
                                onConfirm={onConfirm}
                                onCancel={onCancel}
                            />
                        )}
                    </NavigationPrompt>
                    <Popup
                        messagePopup={this.state.popup.message}
                        titlePopup={this.state.popup.title}
                        showPopup={this.state.popup.show}
                        handleClose={this.hidePopup}
                    />
                    <div className="section-logo flex-end flex-align-center">
                        <h2 className="address-title">
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                            {`${this.props.visit.customer.address.street} ${this.props.visit.customer.address.number}, ${this.props.visit.customer.address.city}, ${this.props.visit.customer.address.country}`}
                        </h2>
                        <ContractorContainer/>
                    </div>
                    <ProgressBar progress={this.props.progress}></ProgressBar>
                    <form onSubmit={(event) => {this.nextPage(event)}} id="visitProducts">
                        <div className="row-padding flex-center">
                            <div className="column small-12 medium-7">
                                <h3>Selectie producten</h3>
                                <div className="column small-12 medium-6">
                                    <div className="product-choise-wrapper">
                                        <div className="product-choise-selection">
                                            <img src={products.panels.image !== undefined ? products.panels.image : this.placeholerImage} alt="Zonnepaneel"/>
                                            <Select
                                                options={this.createProductNames(this.state.panels)}
                                                onChangeHandler={(event) => this.setProductSpecifications(event.target.value, 'panels')}
                                                name='panels'
                                                label="Zonnepanelen"
                                            />
                                        </div>
                                        {Object.keys(products.panels).length !== 0 ?
                                            <div className="product-choise-specifications">
                                                {products.panels.stock === 0 ?
                                                    <p className="product-choise-stock__normal">
                                                        <FontAwesomeIcon icon={faCheckCircle} />
                                                        Op voorraad
                                                    </p>
                                                    :
                                                    <p className="product-choise-stock__low">
                                                        <FontAwesomeIcon icon={faExclamationCircle} />
                                                        Bijna uitverkocht
                                                    </p>
                                                }
                                                <h4>Specificaties</h4>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>Merk:</td>
                                                            <td>{products.panels.brand}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Type:</td>
                                                            <td>{products.panels.type}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Lengte:</td>
                                                            <td>{products.panels.length}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Breedte:</td>
                                                            <td>{products.panels.width}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Hoogte:</td>
                                                            <td>{products.panels.height}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Gewicht:</td>
                                                            <td>{products.panels.weight}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Module-efficiëntie:</td>
                                                            <td>{products.panels.efficiency}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Fabrieksgarantie:</td>
                                                            <td>{products.panels.factoryWarranty}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Garantieperiode:</td>
                                                            <td>{products.panels.periodWarranty}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Capaciteitstolerantie:</td>
                                                            <td>{products.panels.capacity}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dikte van frame:</td>
                                                            <td>{products.panels.heightFrame}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Connectortype:</td>
                                                            <td>{products.panels.connectorType}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Eind vermogen:</td>
                                                            <td>{products.panels.power}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Frame kleur:</td>
                                                            <td>{products.panels.color}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className="column small-12 medium-6">
                                    <div className="product-choise-wrapper">
                                        <div className="product-choise-selection">
                                            <img src={products.converters.image !== undefined ? products.converters.image : this.placeholerImage} alt="Omvormer"/>
                                            <Select
                                                options={this.createProductNames(this.state.converters)}
                                                onChangeHandler={(event) => this.setProductSpecifications(event.target.value, 'converters')}
                                                name='converters'
                                                label="Omvormer"
                                            />
                                        </div>
                                        {Object.keys(products.converters).length !== 0 ?
                                            <div className="product-choise-specifications">
                                                {products.converters.stock === 0 ?
                                                    <p className="product-choise-stock__normal">
                                                        <FontAwesomeIcon icon={faCheckCircle} />
                                                        Op voorraad
                                                    </p>
                                                    :
                                                    <p className="product-choise-stock__low">
                                                        <FontAwesomeIcon icon={faExclamationCircle} />
                                                        Bijna uitverkocht
                                                    </p>
                                                }
                                                <h4>Specificaties</h4>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>Merk:</td>
                                                            <td>{products.converters.brand}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Type:</td>
                                                            <td>{products.converters.type}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Fabrieksgarantie:</td>
                                                            <td>{products.converters.factoryWarranty}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Nom. AC vermogen (W):</td>
                                                            <td>{products.converters.acPower}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Max. uitgangstroom:</td>
                                                            <td>{products.converters.maxOutput}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Start DC spanning:</td>
                                                            <td>{products.converters.startDCvoltage}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Beschermingsgraad:</td>
                                                            <td>{products.converters.protectionFactor}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Max. DC spanning:</td>
                                                            <td>{products.converters.maxDCvoltage}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Aantal fasen:</td>
                                                            <td>{products.converters.phaseNumber}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Display:</td>
                                                            <td>{products.converters.display}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Connector type:</td>
                                                            <td>{products.converters.connectorType}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Aantal MPPT:</td>
                                                            <td>{products.converters.numberMTTP}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Type omvormer:</td>
                                                            <td>{products.converters.typeConverter}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Communicatie interface:</td>
                                                            <td>{products.converters.communicationInterface}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Kwh teller:</td>
                                                            <td>{products.converters.kwhCount}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="column small-12 medium-3">
                                <h3>Overzicht offerte</h3>
                                <div className="calculation-table-wrapper">
                                    <Calculation
                                        roofs={this.props.visit.roofs}
                                        panelsCosts={this.props.visit.products.panels.price}
                                        converterCosts={this.props.visit.products.converters.price}
                                        otherCosts={this.props.contractor.basicCosts}
                                        currentCosts={this.props.visit.customer.info.price}
                                        kwhYearly={this.props.visit.customer.info.consumption}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="row-padding navigation-buttons">
                        <Button
                            icon={<FontAwesomeIcon icon={faChevronLeft} />}
                            size="large"
                            color="green"
                            label="Vorige"
                            onClickHandler={this.previousPage}
                            isDisabled={false}
                        />
                        <Button
                            icon={<FontAwesomeIcon icon={faChevronRight} />}
                            iconRight={true}
                            size="large"
                            color="green"
                            label="Volgende"
                            type="submit"
                            form="visitProducts"
                            isDisabled={!(Object.keys(this.props.visit.products.panels).length !== 0 && Object.keys(this.props.visit.products.converters).length !== 0)}
                        />
                    </div>
                </React.Fragment>
            );
        }
    }
}

export default VisitProducts;
