import React from 'react'
import { useEffect } from 'react'
import "./Loader.css"

const Loader = (props) => {
    
    const IFRAME_ID = props.iframe;
    const PROGRESS_DURATION = props.duration ? (props.duration): 3; //default is 3 otherwise from properties
    const HAS_SPINNER = props.hasSpinner ? props.hasSpinner: false; //this is to hide svg spinner
    const PROGRESS_HEIGHT = 171;
    const PROGRESS_TIMEOUT = 50;
    const PROGRESS_HEIGHT_INCREASE = ((PROGRESS_HEIGHT / (PROGRESS_DURATION*1000))*PROGRESS_TIMEOUT);

    // console.log("PROGRESS_DURATION: "+PROGRESS_DURATION+","+PROGRESS_HEIGHT_INCREASE);

    let currentHeight = PROGRESS_HEIGHT;

    const toggleProgress = () => {
        let preloader = document.getElementById("preloader");
        let progress = document.getElementById("loaderProgress");
        let iframe=document.getElementById(IFRAME_ID);
        setTimeout(()=>{
            currentHeight = currentHeight - PROGRESS_HEIGHT_INCREASE;
            if (currentHeight < 0){
                currentHeight=0;
            }
            progress.style.height = currentHeight+"px";
            if (currentHeight>0){
                toggleProgress();
            }else{
                preloader.style.display="none";
                iframe.style.display="block";
            }
        },PROGRESS_TIMEOUT);
    }

    useEffect(() => {
        let iframe=document.getElementById(IFRAME_ID);
        iframe.style.display="none";
        if (HAS_SPINNER){
            let loaders=document.getElementsByClassName("loader");
            console.log("loaders: "+loaders.length);
            for (let a=0;a<loaders.length;a++){
                let spinners=loaders[a].getElementsByTagName("svg");
                console.log("spinners: "+spinners.length);
                if (spinners.length>0){
                    loaders[a].style.display="none";
                    break;
                }
            }
        }
        toggleProgress();
    }, []);
    

    return (
        <div className="preloader-container" id="preloader" style={{display:"block"}}>
            <div className="preloader-image">
                <div className="preloader-progress" style={{height:"100%"}} id="loaderProgress"></div>
            </div>
        </div>
    )
}

export default Loader
