import React, { Fragment } from 'react'
import TopHeader from '../components/Ui/TopHeader';
import ContentSection from '../components/Ui/ContentSection';
import UserDetailContainer from '../containers/UserDetailContainer';
import Footer from '../components/Ui/Footer';

 const UserDetail = (props) => {
    const visitTable = <UserDetailContainer history={props.history} />;
    return (
       <Fragment>
           <TopHeader title="User Detail" history={props.history}/>
                <ContentSection content={visitTable} />
                <Footer image={false} />
       </Fragment>
    )
}

export default UserDetail;