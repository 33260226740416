import React, { Component } from 'react';
import constants from '../../../global/Constants';

class InputRange extends Component {
    constructor(props) {
        super(props);

        this.state  = {
            inputValue: this.props.value
        };

        this.sliderThumbOffset = 1;

        this.handleChange = this.handleChange.bind(this);
        this.handleRangeChange = this.handleRangeChange.bind(this);
    }

    handleChange(event) {
        this.props.onChangeHandler(event);
    }

    handleRangeChange(value, rangeInput, numberInput) {
        const percentValue = ((value * 100) / this.props.max) - this.sliderThumbOffset;

        rangeInput.style.background = `linear-gradient(to right, ${constants.colors.blue} 0%, ${constants.colors.blue} ${percentValue}%, ${constants.colors.lightGrey} ${percentValue}%, ${constants.colors.lightGrey} 100%)`;
        rangeInput.value = value;
        numberInput.value = value;

        this.setState({inputValue: value});
    }

    componentDidMount() {
        this.handleRangeChange(this.props.value, document.querySelector(`#${this.props.name}Range`), document.querySelector(`#${this.props.name}Input`));
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({
                inputValue: this.props.value
            });
            this.handleRangeChange(this.props.value, document.querySelector(`#${this.props.name}Range`), document.querySelector(`#${this.props.name}Input`));
        }
    }

    render() {
        const {
            name,
            label,
            required,
            onChangeHandler,
            min,
            max,
            disabled
        } = this.props;

        return (
            <div className="range-wrapper">
                <label htmlFor={`${name}Range`}>{`${label}:`}{required ? <span className="mandatory"> *</span> : null}</label>
                <input type="range"
                       onChange={onChangeHandler}
                       required={required}
                       min={min}
                       max={max}
                       value={this.state.inputValue}
                       name={name}
                       id={`${name}Range`}
                       disabled={disabled}
                       onInput={event => this.handleRangeChange(event.target.value, event.target, document.querySelector(`#${name}Input`))}
                />
                <input type="number"
                       onChange={onChangeHandler}
                       value={this.state.inputValue}
                       id={`${name}Input`}
                       min={min}
                       max={max}
                       disabled={disabled}
                       onInput={event => this.handleRangeChange(event.target.value, document.querySelector(`#${name}Range`), event.target)}
                />
            </div>
        );
    }
}

InputRange.defaultProps = {
    onChangeHandler: () => {}
};

export default InputRange;
