import { connect } from 'react-redux';
import VisitSetup from "../components/Visit/VisitSetup";
import {
    UPDATE_VISIT_IMAGES,
    REMOVE_VISIT_IMAGES,
    SET_FORM_PROGRESS,
    RESET_VISIT_FORM, UPDATE_ROOF_INFO, RESET_FORM_PROGRESS, SET_VISIT_IMAGES
} from '../state/actions/actionTypes'

const mapStateToProps = (state) => {
    return {
        visit: state.visit,
        progress: state.progress,
        contractor: state.contractor
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        submitImageHandler: (image, uploadType) => {
            dispatch({
                type: UPDATE_VISIT_IMAGES,
                photo: image,
                upload: uploadType
            });
        },
        removeImageHandler: (identifier, uploadType) => {
            dispatch({
                type: REMOVE_VISIT_IMAGES,
                identifier: identifier,
                upload: uploadType
            });
        },
        updateRoofsHandler: (roofs) => {
            dispatch({
                type: UPDATE_ROOF_INFO,
                roofs: roofs
            });
        },
        setFormProgress: (progress) => {
            dispatch({
                type: SET_FORM_PROGRESS,
                progress: progress
            });
        },
        resetForm: () => {
            dispatch({
                type: RESET_VISIT_FORM
            });
            dispatch({
                type: RESET_FORM_PROGRESS
            });
        },
        setCustomerImages: (images) => {
            dispatch({
                type: SET_VISIT_IMAGES,
                images: images
            });
        }
    };
};

const VisitSetupContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(VisitSetup);

export default VisitSetupContainer;
