import React, { Component } from "react";
import ContractorContainer from "../../containers/ContractorContainer";
import RoofDesign from "./RoofDesign/RoofDesign";
import Tabs from "../Ui/Tabs/Tabs";
import RoofDataAdditional from "./RoofData/RoofDataAdditional";
import Button from "../Ui/Buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import constants from "../../global/Constants";
import ResetPopup from "../Ui/ResetPopup";
import NavigationPrompt from "react-router-navigation-prompt";
import { trackPromise } from "react-promise-tracker";
import { fetchWithDelay } from "../../global/FetchWithDelay";
import ProgressBar from "../Ui/ProgressBar";
import { postWithDelay } from "../../global/PostWithDelay";

function search(nameKey, myArray, searchFor) {
  for (var i = 0; i < myArray.length; i++) {
    if (myArray[i][searchFor] === nameKey) {
      return myArray[i];
    }
  }
}

class VisitRoof extends Component {
  constructor(props) {
    super(props);

    this.imgRef = React.createRef();
    this.next = "/visit-setup";
    this.previous = "/visit-plan";
    this.redirect = "/visit-plan";

    this.state = {
      roofQualityData: [],
      roofTypeData: [],
      formStep: 1,
      popup: {
        show: false,
        message: "Er is een onbekende fout opgetreden",
        title: "Foutmelding",
      },
      confirmLeave: false,
      photoUpload: false,
      photoUploadType: null,
      iframeUrl: "",
      installer:
        this.props.contractor.installer.token !== ""
          ? this.props.contractor.installer
          : JSON.parse(sessionStorage.getItem("installer")),
      roofImg:"",
      themeColor:this.props.contractor
    };
  }

  componentDidMount() {
    if (this.props.visit.calculationId !== 0) this.getIframeUrl();
    this.setRoofStatus();
    this.setRoofTypes();
    this.imgRef.current = 0;
    this.setState({roofImg:this.props.visit.design.plain});
  }

  setRoofTypes = () => {
    const apiUrl = `${constants.api.api}/rooftype`;

    trackPromise(
      fetchWithDelay(apiUrl, 0, this.state.installer.token)
        .then((data) => {
          this.setState({ roofTypeData: data });
        })
        .catch((error) => {
          this.showPopup(error.toString(), "Foutmelding");
        })
    );
  };

  setRoofStatus = () => {
    const apiUrl = `${constants.api.api}/roofqualities`;

    trackPromise(
      fetchWithDelay(apiUrl, 0, this.state.installer.token)
        .then((data) => {
          this.setState({ roofQualityData: data });
        })
        .catch((error) => {
          this.showPopup(error.toString(), "Foutmelding");
        })
    );
  };

  setCustomerVisit(visitData) {
    let dateStr = visitData.visitDateDisplay.split("-");
    let date = `${dateStr[2]}-${dateStr[1]}-${dateStr[0]}`;
    const customerInfo = {
      firstName: visitData.customer.firstName,
      lastName: visitData.customer.lastName,
      emailAddress: visitData.customer.emailAddress,
      phoneNumber: visitData.customer.mobileNumber,
      residents: visitData.building.buildingAmountResidents,
      consumption: visitData.building.buildingYearlyUsage,
      price: visitData.building.buildingkWhPrice,
      date: date,
      time: visitData.visitTime,
      customerId: visitData.customer.customerId,
    };

    const roofsInfo = [];

    visitData.surfaces.map((roof) => {
      if (!roof.isDeleted && roof.isActive) {
        roofsInfo.push({
          id: roof.roofSurfaceId,
          title: roof.roofName,
          type: roof.roofTypeId
            ? search(roof.roofTypeId, this.state.roofTypeData, "roofTypeId")
                .roofTypeName
            : "",
          status: roof.roofQualityId
            ? search(
                roof.roofQualityId,
                this.state.roofQualityData,
                "roofQualityId"
              ).roofQualityName
            : "",
          panels: roof.numberOfPanels,
          height: "",
          width: "",
          length: "",
          tilt: roof.roofInclination,
          tiles: "",
          side: roof.roofOrientation,
          area: roof.roofArea,
          roofTypeId: roof.roofTypeId,
          roofImg:"",
        });
      }
    });

    const customerDesign = {
      plain: visitData.building.surfaceDesign[0].surfaceDesignUrl,
      marked: visitData.building.surfaceDesign[0].surfaceDesignUrl,
    };
    this.setState({roofImg:visitData.building.surfaceDesign[0].surfaceDesignUrl});
    this.imgRef.current += 1;
    this.props.setCustomerInfo(customerInfo);
    this.props.setDesign(customerDesign);
    this.props.setRoofs(roofsInfo);
    this.props.setCustomerQuote(visitData.quote);

  }

  getCustomerVisit(visitId) {
    const apiUrl = `${constants.api.visit}/${visitId}`;

    trackPromise(
      fetchWithDelay(apiUrl, 0, this.state.installer.token)
        .then((data) => {
          setTimeout(() => {
            this.setCustomerVisit(data);
          }, 0);
        })
        .catch((error) => {
          this.showPopup(error.toString(), "Foutmelding");
        })
    );
  }

  setNovasoleVisit = () => {
    trackPromise(
      postWithDelay(
        `${constants.api.GetDesign}/${this.props.visit.id}/${this.props.visit.calculationId}`,
        0,
        {},
        this.state.installer.token
      )
        .then((data) => {
          if (data.isSuccess) {
            this.getCustomerVisit(this.props.visit.id);
          }
        })
        .catch((error) => {
          this.showPopup(error.toString(), "Foutmelding");
        })
    );
  };

  getIframeUrl = () => {
    const apiUrl = `${constants.api.api}/Novasole/urltodesign/${this.props.visit.calculationId}`;
    trackPromise(
      fetchWithDelay(apiUrl, 0, this.state.installer.token)
        .then((data) => {
          this.setState({ iframeUrl: data.urlToDesign });
        })
        .catch((error) => {
          this.showPopup(error.toString(), "Foutmelding");
        })
    );
  };

  updateRoofs = () => {
    let updatedRoofs = [];
    const roofs = this.props.visit.roofs;

    roofs.map((roof) => {
      updatedRoofs.push({
        id: roof.id,
        title: roof.title,
        type: roof.type,
        status: roof.status,
        panels: parseInt(
          document.querySelector("input[name=" + "panels-" + roof.id + "]")
            .value
        ),
        height: roof.height,
        width: roof.width,
        length: roof.length,
        tilt: parseInt(
          document.querySelector("input[name=" + "tilt-" + roof.id + "]").value
        ),
        tiles: roof.tiles,
        side: roof.side,
        area: roof.area,
        roofTypeId: roof.roofTypeId,
        roofQualityId: roof.roofQualityId,
        roofTile: "",
      });
    });

    this.props.updateRoofsHandler(updatedRoofs);
  };

  reloadDesign = () => {
    this.setNovasoleVisit();
    this.setState({roofImg:null});
  };

  previousPage = () => {
    this.props.setFormProgress([]);
    this.props.history.push(this.previous);
  };

  nextPage = (event) => {
    event.preventDefault();
    this.updateRoofs();
    this.props.setFormProgress([1, 2]);
    this.props.history.push(this.next);
  };

 
  render() {
    const {theme} = this.state.themeColor;
    if (!this.props.progress.includes(this.state.formStep)) {
      this.props.history.push(this.redirect);

      return null;
    } else {
      const roofTabs = this.props.visit.roofs.map((roof) => ({
        ...roof,
        content: <RoofDataAdditional roof={roof} />,
      }));

      return (
        <React.Fragment>
          <NavigationPrompt
            when={(current, target) =>
              ![this.previous, this.next, current.pathname].includes(
                target.pathname
              )
            }
            afterConfirm={() => this.props.resetForm()}
          >
            {({ onConfirm, onCancel }) => (
              <ResetPopup
                showPopup={true}
                onConfirm={onConfirm}
                onCancel={onCancel}
              />
            )}
          </NavigationPrompt>
          <div className="section-logo flex-end flex-align-center">
            <h2 className="address-title" style={{color:theme}}>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              {`${this.props.visit.customer.address.street} ${
                this.props.visit.customer.address.number
              }, ${
                this.props.visit.customer.address.city
              }, ${this.props.visit.customer.address.country.toUpperCase()}`}
            </h2>
            <ContractorContainer />
          </div>
          <ProgressBar progress={this.props.progress} />
          <div className="row-padding flex-center">
            <div className="column small-12 medium-8">
              <h3 style={{color:theme}}>Dakvlak</h3>
              <form
                onSubmit={(event) => {
                  this.nextPage(event);
                }}
                id="visitRoof"
              >
                <Tabs tabItems={roofTabs} />
              </form>
              <h3 style={{color:theme}}>Ontwerp</h3>
              <RoofDesign
                plainDesignUrl={this.state.roofImg}
                reloadDesign={this.reloadDesign}
                imgKey={this.imgRef.current}
                iframeUrl={this.state.iframeUrl}
                calculationId={this.props.visit.calculationId}
              />
            </div>
          </div>
          <div className="row-padding navigation-buttons">
            <Button
              icon={<FontAwesomeIcon icon={faChevronLeft} />}
              size="large"
              color="green"
              label="Vorige"
              onClickHandler={this.previousPage}
              isDisabled={false}
            />
            <Button
              icon={<FontAwesomeIcon icon={faChevronRight} />}
              iconRight={true}
              size="large"
              color="green"
              label="Volgende"
              type="submit"
              form="visitRoof"
              isDisabled={false}
            />
          </div>
        </React.Fragment>
      );
    }
  }
}

export default VisitRoof;
