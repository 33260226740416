import React, { Component } from 'react';
import { connect } from 'react-redux';

class StepFormInput extends Component {
    constructor(props) {
        super(props);

        this.state  = {
            inputValue: this.props.value
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({inputValue:event.target.value})
        this.props.onChangeHandler(event);
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({inputValue: this.props.value});
        }
    }

    render() {
        const {theme} = this.props.contractor
        const {
            type,
            required,
            readonly,
            name,
            placeholder,
            min,
            max,
            step,
            label,
            pattern,
            style,
            parentStyle
        } = this.props;

        const capitalizeString = (string) => {
            if (typeof string !== 'string') return '';
            return string.charAt(0).toUpperCase() + string.slice(1)
        };

        return (
            <div className="input-wrapper"  style={parentStyle}>
                <input
                    type={type}
                    required={required}
                    readOnly={readonly}
                    name={name}
                    id={`${name}${capitalizeString(type)}`}
                    value={this.state.inputValue}
                    placeholder={placeholder}
                    pattern={pattern}
                    min={min}
                    max={max}
                    step={step}
                    onChange={this.handleChange}
                    style={style}
                />
                <label htmlFor={`${name}${capitalizeString(type)}`}>
                    {label}{required ? <span className="mandatory"> *</span> : null}
                </label>
                <style>
                {`
                \
                .input-wrapper input:not(:placeholder-shown) + label, .input-wrapper input:focus + label{\
                    color:${theme};\
                }\
                `}
                </style>
            </div>
        );
    }
}

StepFormInput.defaultProps = {
    onChangeHandler: () => {}
};

const mapStateToProps = (value) => {
    return {contractor: value.contractor}
  }


export default connect(mapStateToProps)(StepFormInput);
