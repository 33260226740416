import React from 'react';
import { useSelector } from 'react-redux';

const ProgressBar = (props) => {
    const {progress} = props;
       const contractor = useSelector((state) => state.contractor);

        return (
            <div className="row">
                <div className="column small-12">
                    <div className="progress-bar-wrapper">
                        <ul className="progress-bar">
                            <li className="active">Bezoek</li>
                            <li className={progress.includes(1) ? 'active' : ''}>Ontwerp</li>
                            <li className={progress.includes(2) ? 'active' : ''}>Inspectie</li>
                            <li className={progress.includes(3) ? 'active' : ''}>Offerte</li>
                            <li className={progress.includes(4) ? 'active' : ''}>Ondertekening</li>
                        </ul>
                    </div>
                </div>

                <style>
                {`
                \
                .progress-bar li.active:before{\
                    border-color:${contractor.theme};\
                    background-color:${contractor.theme};\
                }\

                \
                .progress-bar .active:after {\
                    background-color: ${contractor.theme};\
                }
                \
                `}
                </style>
            </div>
        )
}

export default ProgressBar;
