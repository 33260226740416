import React, { Component } from "react";
import { trackPromise } from "react-promise-tracker";
import { Redirect } from "react-router-dom";
import { fetchWithDelay } from "../../global/FetchWithDelay";
import StepFormInput from "../Ui/Inputs/StepFormInput";
import Tabs from "../Ui/Tabs/Tabs";
import constants from "../../global/Constants";
import UserDetailList from "./UserDetailList";

export default class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      installer:this.props.contractor.installer.token !== "" ? this.props.contractor.installer : JSON.parse(sessionStorage.getItem("installer")),
      themeColor:this.props.contractor,
      visitList: [],
    };
    this.crmPage = "/crm";
    this.getCustomerList = this.getCustomerList.bind(this);
  }

  componentDidMount() {
    this.getCustomerList();
  }

  getCustomerList() {
    if (this.state.installer !== undefined && this.props.customer.customer) {
      const customerId = this.props.customer.customer.customerId;
      const apiurl = `${constants.api.api}/Customer/${customerId}/visits`;
      trackPromise(
        fetchWithDelay(apiurl, 0, this.state.installer.token)
          .then((data) => {
            this.setState({ visitList: data });
          })
          .catch((error) => {
            this.showPopup(error.toString(), "Foutmelding");
          })
      );
    }
  }

  render() {
    const {theme} = this.state.themeColor;
    const { customer } = this.props.customer;
    let tabData = [];
    tabData.push({
      title: "Visit",
      content: (
        <UserDetailList
          visitList={this.state.visitList}
          token={this.state.installer.token}
          reduxProps={this.props}
          themeColor={this.state.themeColor}
        />
      ),
    });

    return (
      <React.Fragment>
        {customer ? (
          <React.Fragment>
            <div id="visitPlan">
              <div className="row-padding flex-start detils ">
                <div className="column small-12 addres">
                  <h3 style={{backgroundColor:theme}}>Adres gegevens</h3>
                </div>
              </div>
              <div className="row-padding flex-center detils ">
                <div className="column small-12 medium-6">
                  <StepFormInput
                    type="text"
                    name="street"
                    label="Straat"
                    placeholder="Straat"
                    value={customer.streetName}
                  />
                  <div className="flex-space-between input-row">
                    <StepFormInput
                      type="text"
                      name="number-custom"
                      label="Huisnummer"
                      placeholder="Huisnummer"
                      value={customer.houseNumber}
                    />

                    <StepFormInput
                      type="email"
                      required={false}
                      name="addition-custom"
                      label="Toevoeging"
                      placeholder="Toevoeging"
                      value={
                        customer.houseNumberAddition === ""
                          ? "-"
                          : customer.houseNumberAddition
                      }
                    />
                  </div>
                </div>
                <div className="column small-12 medium-6 detils">
                  <StepFormInput
                    type="text"
                    name="city"
                    label="Plaats"
                    placeholder="Plaats"
                    value={customer.city}
                  />

                  <StepFormInput
                    type="text"
                    name="zipcode-custom"
                    label="Postcode"
                    placeholder="Postcode"
                    value={customer.postalCode}
                  />
                </div>
              </div>
              <div className="row-padding flex-start detils paad">
                <div className="column small-12 addres">
                  <h3 style={{backgroundColor:theme}}>Basis gegevens</h3>
                </div>
              </div>
              <div className="row-padding flex-center">
                <div className="column small-12 medium-6">
                  <StepFormInput
                    type="text"
                    name="firstname"
                    label="Voornaam"
                    placeholder="Voornaam"
                    // defaultValue={customer.info.firstName}
                    value={customer.firstName}
                  />

                  <StepFormInput
                    type="text"
                    name="lastname"
                    label="Achternaam"
                    placeholder="Achternaam"
                    value={customer.lastName}
                  />
                </div>
                <div className="column small-12 medium-6">
                  <StepFormInput
                    type="email"
                    name="email"
                    label="E-mailadres"
                    placeholder="E-mailadres"
                    value={customer.emailAddress}
                  />

                  <StepFormInput
                    type="tel"
                    name="phone"
                    label="Telefoonnummer"
                    placeholder="Telefoonnummer"
                    value={customer.mobileNumber}
                  />
                </div>
              </div>

              <div className="row-padding mr10">
                <div id="userDetailTab">
                  <Tabs tabItems={tabData} />
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <Redirect to={this.crmPage} />
        )}
      </React.Fragment>
    );
  }
}
