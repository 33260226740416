import React, { Component } from "react";
import { trackPromise } from "react-promise-tracker";
import constants from "../../global/Constants";
import { fetchWithDelay } from "../../global/FetchWithDelay";

class CRMTableList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customerDetails: [],
      firstName: "",
      lastName: "",
      emailAddress: "",
      mobileNumber: "",
      streetName: "",
      houseNumber: "",
      houseNumberAddition: "",
      city: "",
      postalCode: "",
      quoteStatus: "",
    };
    this.getCustomers = this.getCustomers.bind(this);
    this.customerListView = this.customerListView.bind(this);
    this.redirectCustomerDetailPage =
      this.redirectCustomerDetailPage.bind(this);
    // this.updateState =  this.updateState.bind(this)
  }

  componentDidMount() {
    this.getCustomers();
  }
  getCustomers() {
    if (this.props.installer.token !== undefined) {
      trackPromise(
        fetchWithDelay(
          `${constants.api.getCustomers}/${this.props.installer.installerId}`,
          0,
          this.props.installer.token
        )
          .then((data) => {
            this.setState({ customerDetails: data });
          })
          .catch((error) => {
            this.showPopup(error.toString(), "Foutmelding");
          })
      );
    }
  }

  updateState = async (event) => {
    event.persist();
    await this.setState({ [event.target.name]: event.target.value });
  };

  customerListView() {
    const {
      customerDetails,
      firstName,
      lastName,
      emailAddress,
      mobileNumber,
      streetName,
      houseNumber,
      houseNumberAddition,
      city,
      quoteStatus,
    } = this.state;
    var customerLists = customerDetails;
    const filter = {};
    if (lastName !== "") filter["lastName"] = lastName;
    if (firstName !== "") filter["firstName"] = firstName;
    if (emailAddress !== "") filter["emailAddress"] = emailAddress;
    if (mobileNumber !== "") filter["mobileNumber"] = mobileNumber;
    if (streetName !== "") filter["streetName"] = streetName;
    if (houseNumber !== "") filter["houseNumber"] = houseNumber;
    if (houseNumberAddition !== "")
      filter["houseNumberAddition"] = houseNumberAddition;
    if (city !== "") filter["city"] = city;
    if (quoteStatus !== "") filter["quoteStatus"] = quoteStatus;

    customerLists = customerLists.filter(function (item) {
      for (var key in filter) {
        if (
          item[key] === undefined ||
          !item[key]
            .toString()
            .toLocaleLowerCase()
            .includes(filter[key].toString().toLocaleLowerCase())
        )
          return false;
      }
      return true;
    });
    // const record = filteredRecord.length > 0 ? filteredRecord : customerLists
    return customerLists.map((customer, index) => {
      return (
        <tr
          key={index}
          onClick={() => this.redirectCustomerDetailPage(customer)}
          className="td-text-align"
        >
          <td>{customer.lastName}</td>
          <td>{customer.firstName}</td>
          <td>{customer.emailAddress}</td>
          <td>{customer.mobileNumber}</td>
          <td>{customer.streetName} </td>
          <td>{customer.houseNumber} </td>
          {/* <td>
            {customer.houseNumberAddition !== ""
              ? customer.houseNumberAddition
              : "-"}
          </td> */}
          <td>{customer.city} </td>
          <td>{customer.postalCode} </td>
          <td>{customer.quoteStatus}</td>
        </tr>
      );
    });
  }

  redirectCustomerDetailPage(customer) {
    this.props.customerDetail(customer);
    this.props.history.push("/user-detail");
  }

  render() {
    const { customerDetails } = this.state;
    const {theme} = this.props.themeColor
    return (
      <React.Fragment>
        <div className="visits-toolbar flex-end flex-align-baseline">
          <h2 style={{color:theme}}>Klanten</h2>
        </div>
        <table id="visitsToday" className="visits-today list-1" >
          <thead>
            <tr className="th-align-text" style={{backgroundColor:theme}}>
              <th>Achternaam</th>
              <th>Voornaam</th>
              <th>Email</th>
              <th>Mobiel</th>
              <th>straat</th>
              <th>Huisnummer</th>
              {/* <th>Toevoeging</th> */}
              <th>Plaats</th>
              <th>Postcode</th>
              <th>Status</th>
            </tr>
            <tr className="td-align-item">
              <td>
                <input
                  type="text"
                  size="6"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={(event) => this.updateState(event)}
                />
              </td>
              <td>
                <input
                  type="text"
                  size="6"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={(event) => this.updateState(event)}
                />
              </td>
              <td>
                <input
                  size="9"
                  type="text"
                  name="emailAddress"
                  value={this.state.emailAddress}
                  onChange={(event) => this.updateState(event)}
                />
              </td>
              <td>
                <input
                  size="6"
                  type="text"
                  name="mobileNumber"
                  value={this.state.mobileNumber}
                  onChange={(event) => this.updateState(event)}
                />
              </td>
              <td>
                <input
                  size="6"
                  type="text"
                  name="streetName"
                  value={this.state.streetName}
                  onChange={(event) => this.updateState(event)}
                />
              </td>
              <td>
                <input
                  size="6"
                  type="text"
                  name="houseNumber"
                  value={this.state.houseNumber}
                  onChange={(event) => this.updateState(event)}
                />
              </td>
              {/* <td>
                <input
                  size="6"
                  type="text"
                  name="houseNumberAddition"
                  value={this.state.houseNumberAddition}
                  onChange={(event) => this.updateState(event)}
                />
              </td> */}
              <td>
                <input
                  size="6"
                  type="text"
                  name="city"
                  value={this.state.city}
                  onChange={(event) => this.updateState(event)}
                />
              </td>
              <td>
                <input
                  size="6"
                  type="text"
                  name="postalCode"
                  value={this.state.postalCode}
                  onChange={(event) => this.updateState(event)}
                />
              </td>
              <td>
                <input
                  size="6"
                  type="text"
                  name="quoteStatus"
                  value={this.state.quoteStatus}
                  onChange={(event) => this.updateState(event)}
                />
              </td>
            </tr>
            {customerDetails[0] !== undefined ? (
              <React.Fragment>{this.customerListView()}</React.Fragment>
            ) : null}
          </thead>
          
        </table>
        {customerDetails[0] === undefined ? (
            <p>Geen resultaten gevonden</p>
          ) : null}
      
        <style>
          {`
          \
          .th-align-text th{\
              background-color:${theme} !important;\
          }\
          \
          table tr:hover{\
              background-color:${theme} !important;\
          }\
          
          
          `}
        </style>
      </React.Fragment>
    );
  }
}

export default CRMTableList;
