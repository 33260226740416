import { combineReducers } from 'redux';
import visit from './visit';
import contractor from './contractor';
import progress from './progress';
import crmPage from './crmReducer';

const state = combineReducers({
    visit,
    contractor,
    progress,
    crmPage
});


export default state;
