import React, { Component } from 'react';

class Radio extends Component {
    constructor(props) {
        super(props);

        this.state  = {
            selectedOption: this.props.defaultOption.name + this.props.name
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
    }

    handleChange(event) {
        this.props.onChangeHandler(event);
    }

    handleOptionChange(event) {
        this.setState({
            selectedOption: event.target.id
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.name !== this.props.name) {
            this.setState({
                selectedOption: this.props.defaultOption.name + this.props.name
            });
        }
    }

    render() {
        const {
            options,
            name,
            label,
            onChangeHandler,
            disabled
        } = this.props;

        const radioList = options.map((option, index) => {
            return (
                <React.Fragment key={index}>
                    <input type="radio"
                           className="radio-tab"
                           name={name}
                           onChange={event => this.handleOptionChange(event)}
                           value={option.value}
                           id={option.name + name}
                           checked={this.state.selectedOption === (option.name + name)}
                           disabled={disabled}
                    />
                    <label htmlFor={option.name + name}>{option.name}</label>
                </React.Fragment>
            );
        });

        return (
            <div className="radio-wrapper">
                <label>{label}</label>
                <div className="radio-tabs" onChange={onChangeHandler}>
                    {radioList}
                </div>
            </div>
        );
    }
}

Radio.defaultProps = {
    onChangeHandler: () => {}
};

export default Radio;
