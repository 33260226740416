import React, {useRef} from 'react';
import ReactDOM from 'react-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import Button from "./Buttons/Button";
import {clearAllBodyScrollLocks, disableBodyScroll} from "body-scroll-lock";
import { useSelector } from 'react-redux';

const Popup = ({
    showPopup,
    titlePopup,
    messagePopup,
    handleClose
}) => {
    const contractor = useSelector((state) => state.contractor);
    const popup = useRef(null);
    const bodyElement = document.querySelector('body');

    const handlePopupClick = (event) => {
        if (event.target === popup.current) {
            handleClose();
            event.preventDefault();
        }
    };

    const handleCloseClick = (event) => {
        handleClose();
        event.preventDefault();
    };

    showPopup ? document.querySelector('html').classList.add('overlay') : document.querySelector('html').classList.remove('overlay');
    showPopup ? disableBodyScroll(bodyElement) : clearAllBodyScrollLocks();

    return ReactDOM.createPortal(
        showPopup ?
            <div className="popup-container">
                <div className="row-padding flex-center popup-wrapper" ref={popup} onClick={event => handlePopupClick(event)}>
                    <div className="column small-12 medium-6 popup-content">
                        <div className="popup-top">
                            <h2 style={{color:contractor.theme}}>{titlePopup}</h2>
                            <FontAwesomeIcon icon={faTimesCircle} className="popup-close" onClick={event => handleCloseClick(event)} />
                        </div>
                        <div className="popup-message">
                            {messagePopup}
                        </div>
                        <Button
                            icon={<FontAwesomeIcon icon={faTimes} />}
                            onClickHandler={handleClose}
                            size="small"
                            color="yellow"
                            label="Sluiten"/>
                    </div>
                </div>
            </div> : null,
        document.querySelector('#root')
    );
};

export default Popup;
