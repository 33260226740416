import React from 'react';

const Calculation = ({
    roofs,
    quote
}) => {
    const panelsNumberTotal = () => {
        const panels = [];

        roofs.forEach((roof) => {
            panels.push(roof.panels);
        });

        return panels.reduce((a, b) => {
            return a + b;
        }, 0);
    };

    const formatEuro = (amount) => {
        return new Intl.NumberFormat('nl-NL', {currency: 'EUR', minimumFractionDigits: 2}).format(amount);
    };

    return (
        <React.Fragment>
            <table className="calculation-table">
                <tbody>
                    <tr>
                        <td>{`Panelen (${panelsNumberTotal()})`}</td>
                    </tr>
                    <tr>
                        <td>Omvormer</td>
                    </tr>
                    <tr>
                        <td>Installatie</td>
                    </tr>
                    <tr>
                        <td>Voorrijkosten</td>
                    </tr>
                    <tr>
                        <td>Klein materiaal</td>
                    </tr>
                    <tr className="total-row">
                        <td>Totaal:</td>
                        <td>{`€${formatEuro(quote.qouteTotalAmount)}`}</td>
                    </tr>
                </tbody>
            </table>

            <table className="calculation-table">
                <tbody>
                    <tr>
                        <td>Terugverdientijd</td>
                        <td>{`${quote.qouteReturnOnInvestmentTime} jaar`}</td>
                    </tr>
                    <tr>
                        <td>Rendement</td>
                        <td>{`${quote.qouteReturnOnInvestmentPercentage}%`}</td>
                    </tr>
                    <tr>
                        <td>Maandopbr.</td>
                        <td>{`€${formatEuro(quote.qouteMonthlyEarnings)}`}</td>
                    </tr>
                    <tr>
                        <td>Overgebleven maand.</td>
                        <td>{`€${formatEuro(quote.qouteRemainingMonthyCosts)}`}</td>
                    </tr>
                </tbody>
            </table>
        </React.Fragment>
    )
};

export default Calculation;
