import React, { Component } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
    faCamera,
    faPlusCircle,
    faTimesCircle,
    faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";
import Button from "../Ui/Buttons/Button";
import Popup from "../Ui/Popup";
import ContractorContainer from "../../containers/ContractorContainer";
import PhotoUpload from "./PhotoUpload/PhotoUpload";
import NavigationPrompt from "react-router-navigation-prompt";
import ResetPopup from "../Ui/ResetPopup";
import RoofStatus from "./RoofData/RoofStatus";
import Tabs from "../Ui/Tabs/Tabs";
import ProgressBar  from "../Ui/ProgressBar";
import constants from "../../global/Constants";
import {trackPromise} from "react-promise-tracker";
import {fetchWithDelay} from "../../global/FetchWithDelay";
import {putWithDelay} from "../../global/PutWithDelay";
import axios from 'axios';
import PhotoPreview from './PhotoUpload/PhotoPreview';

function search(nameKey, myArray, searchFor){
    for (var i=0; i < myArray.length; i++) {
        if (myArray[i][searchFor] === nameKey) {
            return myArray[i];
        }
    }
}

class VisitSetup extends Component {
    constructor(props) {
        super(props);

        this.next = '/visit-info';
        this.previous = '/visit-roof';
        this.redirect = '/visit-plan';

        this.state = {
            formStep: 2,
            popup: {
                    show: false,
                    message: 'Er is een onbekende fout opgetreden',
                    title: 'Foutmelding'
                },
            confirmLeave: false,
            photoUpload: false,
            photoUploadType: null,
            roofTypes: [],
            roofTypeData:[],
            roofStatus: [],
            roofQualityData:[],
            installer: this.props.contractor.installer.token !== '' ? this.props.contractor.installer : JSON.parse(sessionStorage.getItem('installer')),
            buildingPhotos: [],
            preview:false,
            previewDetails:null,
            themeColor:this.props.contractor
        }
    }

    handlePreview = (details, from) => {
        this.setState({
            preview:from == "click" ? true:false,
            previewDetails: details
        })
    }

    showPhotoUpload = (event, uploadType) => {
        this.setState({
            photoUploadType: uploadType,
            photoUpload: true
        });
    };

    hidePhotoUpload = () => {
        this.setState({
            photoUpload: false
        });
    };

    removePhoto = (event, id, uploadType) => {
        const formdata = new FormData();
        formdata.append('buildingPhoto', JSON.stringify({
            "buildingId": event.buildingId,
           "photoTypeId": event.photoTypeId,
           "photoContent": "",
           "photoTitle": event.photoTitle,
           "photoDescription": event.photoDescription,
           "buildingPhotoId": event.buildingPhotoId,
           "isDeleted": true,
           }));
        const config = { headers: {'Content-Type': 'multipart/form-data', Authorization:`Bearer ${this.state.installer.token}`} };
        trackPromise(
            axios.put(constants.api.buildingPhotos,formdata, config )
            .then((response) => {
                console.log(response);
                this.props.removeImageHandler(id, uploadType)
            })
            .catch((error) => console.log(error))
        );
        // this.props.removeImageHandler(id, uploadType)
    };

    showPopup = (messagePopup, titlePopup) => {
        this.setState({
            popup: {
                show: true,
                message: messagePopup,
                title: titlePopup
            }
        });
    };

    hidePopup = () => {
        this.setState({
            popup: {
                show: false
            }
        });
    };

    getVisitPhotos = () => {
        const apiUrl = `${constants.api.buildingPhotos}/${this.props.visit.id}`;

        trackPromise(
            fetchWithDelay(apiUrl, 0, this.state.installer.token)
                .then((data) => {
                    this.setState({
                        buildingPhotos: data
                    });
                })
                .then(() => {
                    setTimeout(() => {
                        const customerImages = {
                            fuseBox: this.state.buildingPhotos.filter(image => image.photoTypeId === '09796bf4-d4b3-d0f5-0b69-006a5a9ad16b'),
                            cableRoute: this.state.buildingPhotos.filter(image => image.photoTypeId === 'a25987bb-e18f-734e-75c2-00807dd9bd9e'),
                            other: this.state.buildingPhotos.filter(image => image.photoTypeId === '1fc2f63c-08b1-7630-3858-00a0e7c57734')
                        };

                        this.props.setCustomerImages(customerImages);
                    }, 0);
                })
                .catch(error => {
                    this.showPopup(error.toString(), 'Foutmelding');
                })
        );
    };

    setVisitPhotos = (reload, data) => {
        const reloadImages = reload !== undefined ? reload : true;
        const buildingPhotos = [...this.props.visit.images.cableRoute, ...this.props.visit.images.fuseBox, ...this.props.visit.images.other];

        if (buildingPhotos.length > 0 && buildingPhotos !== this.state.buildingPhotos) {
            const uploadObj = {
                "buildingId": '',
               "photoTypeId": '',
               "photoContent": "",
               "photoTitle": '',
               "photoDescription": '',
               "buildingPhotoId": "",
               "isDeleted": false,
               
            };
       let buildingPhotoImage = null
       buildingPhotos.map((value, index) => {
           if(typeof value.photoContent === "object") {
               uploadObj.buildingId = value.buildingId;
               uploadObj.photoTypeId = value.photoTypeId;
               uploadObj.photoTitle = value.photoTitle;
               uploadObj.photoDescription = value.photoDescription;
               buildingPhotoImage = value.photoContent
           }
       });

       const formdata = new FormData();
       formdata.append('buildingPhoto', JSON.stringify(uploadObj));

       formdata.append('buildingPhotoImage', buildingPhotoImage);

         
       const config = { headers: {'Content-Type': 'multipart/form-data', Authorization:`Bearer ${this.state.installer.token}`} };
         
       trackPromise(
           axios.put(constants.api.buildingPhotos,formdata, config )
           .then((response) => {
               this.getVisitPhotos();
           })
           .catch((error) => console.log(error))
       );
        }
    };

    setRoofTypes = () => {
        const apiUrl = `${constants.api.api}/rooftype`;

        trackPromise(
            fetchWithDelay(apiUrl, 0, this.state.installer.token)
                .then((data) => {
                    const roofTypes = [];
                    data.forEach((type => roofTypes.push(type.roofTypeName)));

                    this.setState({
                        roofTypes: roofTypes.reverse(),
                        roofTypeData:data
                    });
                })
                .catch(error => {
                    this.showPopup(error.toString(), 'Foutmelding');
                })
        );
    };

    setRoofStatus = () => {
        const apiUrl = `${constants.api.api}/roofqualities`;

        trackPromise(
            fetchWithDelay(apiUrl, 0, this.state.installer.token)
                .then((data) => {
                    const roofStatus = [];
                    data.forEach((type => roofStatus.push(type.roofQualityName)));

                    this.setState({
                        roofStatus: roofStatus,
                        roofQualityData:data
                    });
                })
                .catch(error => {
                    this.showPopup(error.toString(), 'Foutmelding');
                })
        );
    };

    updateRoofs = () => {
        let updatedRoofs = [];
        const roofs = this.props.visit.roofs;
        const updatedFields = document.querySelectorAll('#roofTypes .tab-item');

        roofs.forEach((roof, index) => {
            updatedRoofs.push({
                id: roof.id,
                title: roof.title,
                type: search(updatedFields[index].querySelectorAll('select')[0].value, this.state.roofTypeData, "roofTypeName").roofTypeName,
                status: search(updatedFields[index].querySelectorAll('select')[1].value, this.state.roofQualityData, "roofQualityName").roofQualityName,
                panels: roof.panels,
                height: roof.height,
                width: roof.width,
                length: roof.length,
                tilt: roof.tilt,
                tiles: updatedFields[index].querySelector('.radio-tabs input:checked').value,
                side: roof.side,
                area: roof.area,
                roofQualityId:search(updatedFields[index].querySelectorAll('select')[1].value, this.state.roofQualityData, "roofQualityName").roofQualityId,
                roofTile: updatedFields[index].querySelector('.radio-tabs input:checked').value,
                // roofTypeId:roof.roofTypeId
                roofTypeId:search(updatedFields[index].querySelectorAll('select')[0].value, this.state.roofTypeData, "roofTypeName").roofTypeId
            })
        });
        this.props.updateRoofsHandler(updatedRoofs);
    };

  

    previousPage = () => {
        this.setVisitPhotos(false, null);
        this.props.setFormProgress([1]);
        this.props.history.push(this.previous);
    };

    nextPage = (event) => {
        event.preventDefault();
        this.updateRoofs();
        // this.setVisitPhotos(false, null);
        this.props.setFormProgress([1, 2, 3]);
        this.props.history.push(this.next);
    };

    componentDidMount() {
        this.setRoofTypes();
        this.setRoofStatus();
        this.getVisitPhotos();
    }

   
    render() {
        const {theme} = this.state.themeColor;
        if (!this.props.progress.includes(this.state.formStep)) {
            this.props.history.push(this.redirect);

            return null;
        } else {
            const roofTabs = this.props.visit.roofs.map((roof) => ({...roof, content: <RoofStatus roof={roof} roofTypes={this.state.roofTypes} roofStatus={this.state.roofStatus} />}));
            const fuseBoxImages = this.props.visit.images.fuseBox.filter(image => !image.isDeleted);
            const cableRouteImages = this.props.visit.images.cableRoute.filter(image => !image.isDeleted);
            const otherImages = this.props.visit.images.other.filter(image => !image.isDeleted);
            return (
                <React.Fragment>
                    <NavigationPrompt
                        when={(current, target) => ![this.previous, this.next, current.pathname].includes(target.pathname)}
                        afterConfirm={() => this.props.resetForm()}
                    >
                        {({ onConfirm, onCancel }) => (
                            <ResetPopup
                                showPopup={true}
                                onConfirm={onConfirm}
                                onCancel={onCancel}
                            />
                        )}
                    </NavigationPrompt>
                    <Popup
                        messagePopup={this.state.popup.message}
                        titlePopup={this.state.popup.title}
                        showPopup={this.state.popup.show}
                        handleClose={this.hidePopup}
                    />
                    <div className="section-logo flex-end flex-align-center">
                        <h2 className="address-title" style={{color:theme}}>
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                            {`${this.props.visit.customer.address.street} ${this.props.visit.customer.address.number}, ${this.props.visit.customer.address.city}, ${this.props.visit.customer.address.country.toUpperCase()}`}
                        </h2>
                        <ContractorContainer/>
                    </div>
                    <ProgressBar progress={this.props.progress} />
                    <div className="row-padding flex-center">
                        <div className="column small-12 medium-8">
                            <h3 style={{color:theme}}>Legplan</h3>
                            <div className="photo-upload flex-space-between">
                                <PhotoUpload
                                    showPopup={this.state.photoUpload}
                                    uploadType={this.state.photoUploadType}
                                    submitImageHandler={this.props.submitImageHandler}
                                    buildingId={this.props.visit.customer.address.id}
                                    setVisitPhotos={this.setVisitPhotos}
                                    handleClose={this.hidePhotoUpload}
                          
                                />

                                <PhotoPreview
                                    showPopup={this.state.preview}
                                    handleClose={() => this.handlePreview(null, "")}
                                    details={this.state.previewDetails}
                                />
                                <div className="upload-photo-module">
                                    <p>Meterkast:</p>
                                    <div className={`upload-photo-wrapper ${fuseBoxImages.length > 0 ? 'multiply' : ''}`}>
                                        {this.props.visit.images.fuseBox.map((image, index) => (
                                            <React.Fragment key={index}>
                                                {!image.isDeleted ? (
                                                    <div className="upload-photo-container">
                                                        <FontAwesomeIcon icon={faTimesCircle} onClick={event => this.removePhoto(image, image.buildingPhotoId, 'fuseBox')} className="upload-photo-remove"/>
                                                        <img
                                                            src={typeof image.photoContent == "object" ? URL.createObjectURL(image.photoContent) : image.photoContent}
                                                            alt={image.photoTitle}
                                                            className="upload-photo"
                                                            onClick={() => this.handlePreview(image, "click")}
                                                        />
                                                    </div>
                                                ) : null}
                                            </React.Fragment>
                                        ))}
                                        {this.props.visit.images.fuseBox.length < 7 ?
                                            <button className="upload-button" onClick={event => this.showPhotoUpload(event, 'fuseBox')}>
                                                    <FontAwesomeIcon icon={fuseBoxImages.length > 0 ? faPlusCircle : faCamera} className="upload-button" />
                                                    {fuseBoxImages.length > 0 ? '' : 'Maak foto'}
                                            </button>
                                            : null}
                                    </div>
                                </div>
                                <div className="upload-photo-module">
                                    <p>Kabeltraject:</p>
                                    <div className={`upload-photo-wrapper ${cableRouteImages.length > 0 ? 'multiply' : ''}`}>
                                        {this.props.visit.images.cableRoute.map((image, index) => (
                                            <React.Fragment key={index}>
                                                {!image.isDeleted ? (
                                                    <div className="upload-photo-container">
                                                        <FontAwesomeIcon icon={faTimesCircle} onClick={event => this.removePhoto(image, image.buildingPhotoId, 'cableRoute')} className="upload-photo-remove" />
                                                        <img
                                                            src={typeof image.photoContent == "object" ? URL.createObjectURL(image.photoContent) : image.photoContent}
                                                            alt={image.photoTitle}
                                                            className="upload-photo"
                                                            onClick={() =>  this.handlePreview(image, "click")}
                                                        />
                                                    </div>
                                                ) : null}
                                            </React.Fragment>
                                        ))}
                                        {this.props.visit.images.cableRoute.length < 7 ?
                                            <button className="upload-button" onClick={event => this.showPhotoUpload(event, 'cableRoute')} >
                                                    <FontAwesomeIcon icon={cableRouteImages.length > 0 ? faPlusCircle : faCamera} className="upload-button" />
                                                    {cableRouteImages.length > 0 ? '' : 'Maak foto'}
                                                </button>
                                            : null}
                                    </div>
                                </div>
                                <div className="upload-photo-module">
                                    <p>Extra foto's:</p>
                                    <div className={`upload-photo-wrapper ${otherImages.length > 0 ? 'multiply' : ''}`}>
                                        {this.props.visit.images.other.map((image, index) => (
                                            <React.Fragment key={index}>
                                                {!image.isDeleted ? (
                                                    <div className="upload-photo-container" key={index}>
                                                        <FontAwesomeIcon icon={faTimesCircle} onClick={event => this.removePhoto(image, image.buildingPhotoId, 'other')} className="upload-photo-remove"/>
                                                        <img
                                                            src={typeof image.photoContent == "object" ? URL.createObjectURL(image.photoContent) : image.photoContent}
                                                            alt={image.photoTitle}
                                                            className="upload-photo"
                                                            onClick={() =>  this.handlePreview(image, "click")}
                                                        />
                                                    </div>
                                                ) : null}
                                            </React.Fragment>
                                        ))}
                                        {this.props.visit.images.other.length < 7 ?
                                            <button
                                                class="upload-button"
                                                onClick={event => this.showPhotoUpload(event, 'other')} >
                                                    <FontAwesomeIcon icon={otherImages.length > 0 ? faPlusCircle : faCamera} className="upload-button" />
                                                    {otherImages.length > 0 ? '' : 'Maak foto'}
                                                </button>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row-padding flex-center">
                        <div className="column small-12 medium-8">
                            <h3 style={{color:theme}}>Dakvlakken</h3>
                            <div id="roofTypes">                                                            
                                <Tabs tabItems={roofTabs} />
                            </div>
                        </div>
                    </div>
                    <div className="row-padding navigation-buttons">
                        <Button
                            icon={<FontAwesomeIcon icon={faChevronLeft} />}
                            size="large"
                            color="green"
                            label="Vorige"
                            onClickHandler={this.previousPage}
                            isDisabled={false}
                        />
                        <Button
                            icon={<FontAwesomeIcon icon={faChevronRight} />}
                            iconRight={true}
                            size="large"
                            color="green"
                            label="Volgende"
                            type="submit"
                            onClickHandler={this.nextPage}
                            form="visitSetup"
                            isDisabled={false}
                        />
                    </div>
                </React.Fragment>
            );
        }
    }
}

export default VisitSetup;
