import {SET_FORM_PROGRESS, RESET_FORM_PROGRESS} from '../actions/actionTypes';
import constants from '../../global/Constants';

const contractor = (state = constants.state.progress, action) => {
    switch (action.type) {
        case SET_FORM_PROGRESS:
            return action.progress;

        case RESET_FORM_PROGRESS:
            return [];

        default:
            return state;
    }
};

export default contractor;
