import React from "react";
import TopHeader from "../components/Ui/TopHeader";
import Footer from "../components/Ui/Footer";
import { useSelector } from "react-redux";


const Crm = (props) =>  {
    const {installerId} = useSelector((state) => state.contractor);
    return (
      <React.Fragment>
        <TopHeader title="Projecten" history={props.history} />
        <div className="user-manager">
          <iframe
          allowFullScreen
          allowTransparency
          height="100%"
          width="100%"
            id="hubspot-frame"
            src={`https://platformvisions.retool.com/embedded/public/ce142543-7b81-46ba-9372-af2171226660?id=${installerId}`}
          >
          </iframe>
        </div>
        <Footer image={false} />
        <script>
          
        </script>
      </React.Fragment>
    );
  
  };

export default Crm
