import React from 'react';
import TopHeader from "../components/Ui/TopHeader";
import Footer from "../components/Ui/Footer";
import { useSelector } from "react-redux";
import Loader from './Loader';

const Tickets = (props) => {
    const {installerId} = useSelector((state) => state.contractor);
    
    return (
        <React.Fragment>
          <TopHeader title="Tickets" history={props.history} />
          <div className="user-manager">
              <Loader iframe="hubspot-frame" duration="1.5"  />
              <iframe
                  allowFullScreen
                  allowTransparency
                  height="100%"
                  width="100%"
                  id="hubspot-frame"
                  src={`https://platformvisions.retool.com/embedded/public/7a99ef44-00d3-43b8-8602-648815db2fa7?id=${installerId}`}
              >
              </iframe>
          </div>
          <Footer image={false} />
          <script>
            
          </script>
        </React.Fragment>
      );
}

export default Tickets
