import { connect } from 'react-redux';
import VisitRoof from "../components/Visit/VisitRoof";
import {
    UPDATE_DESIGN_IMAGE,
    UPDATE_ROOF_INFO,
    SET_MARKED_DESIGN,
    SET_PLAIN_DESIGN,
    SET_FORM_PROGRESS,
    RESET_VISIT_FORM,
    SET_COMMENTS, RESET_FORM_PROGRESS, UPDATE_CUSTOMER_INFO, ADD_ROOF_TYPES, SET_CUSTOMER_QUOTE
} from '../state/actions/actionTypes'

const mapStateToProps = (state) => {
    return {
        visit: state.visit,
        progress: state.progress,
        contractor: state.contractor
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        submitDesignHandler: (image) => {
            dispatch({
                type: UPDATE_DESIGN_IMAGE,
                design: image
            });
        },
        setMarkedDesignHandler: (image) => {
            dispatch({
                type: SET_MARKED_DESIGN,
                design: image
            });
        },
        setPlainDesignHandler: (image) => {
            dispatch({
                type: SET_PLAIN_DESIGN,
                design: image
            });
        },
        updateRoofsHandler: (roofs) => {
            dispatch({
                type: UPDATE_ROOF_INFO,
                roofs: roofs
            });
        },
        submitCommentsHandler: (comments) => {
            dispatch({
                type: SET_COMMENTS,
                comments: comments
            });
        },
        setFormProgress: (progress) => {
            dispatch({
                type: SET_FORM_PROGRESS,
                progress: progress
            });
        },
        resetForm: () => {
            dispatch({
                type: RESET_VISIT_FORM
            });
            dispatch({
                type: RESET_FORM_PROGRESS
            });
        },
        setCustomerInfo: (customer) => {
            dispatch({
                type: UPDATE_CUSTOMER_INFO,
                customer: customer
            });
        },
        setDesign: (image) => {
            dispatch({
                type: UPDATE_DESIGN_IMAGE,
                design: image
            });
        },
        setRoofs: (roofs) => {
            dispatch({
                type: ADD_ROOF_TYPES,
                roofs: roofs
            });
        },
        setCustomerQuote: (quote) => {
            dispatch({
                type: SET_CUSTOMER_QUOTE,
                quote: quote
            });
        }
    };
};

const VisitRoofContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(VisitRoof);

export default VisitRoofContainer;
