import React, { Component } from 'react';
import TopHeader from '../components/Ui/TopHeader';
import ContentSection from '../components/Ui/ContentSection';
import Footer from '../components/Ui/Footer';
// import LoginContainer from "../containers/LoginContainer";
import ResourcePlannerContainer from '../containers/ResourcePlannerContainer';
import Loader from './Loader';

 const ResourcePlanning = (props) => {
    
        const ResrcePlannerContainer = < ResourcePlannerContainer/>;

        return (
            <React.Fragment>
                <TopHeader title="Resource Planner" history={props.history}/>
                <Loader iframe="hubspot-frame" duration="1.5" hasSpinner={true} />
                <div id="hubspot-frame">
                    <ContentSection content={ResrcePlannerContainer} />
                </div>
                <Footer image={false} />
            </React.Fragment>
        );
   
}

export default ResourcePlanning;
