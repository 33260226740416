import React, { Component } from 'react';
import TopHeader from '../components/Ui/TopHeader';
import ContentSection from '../components/Ui/ContentSection';
import Footer from '../components/Ui/Footer';
// import Style from '../components/Styles/Style';
import StyleContainer from '../containers/StyleContainer';


class Home extends Component {
    render() {
        const styleGuide = <StyleContainer  setToken={this.props.setToken} />;

        return (
            <React.Fragment>
                <TopHeader title="Instellingen" history={this.props.history} />
                <ContentSection content={styleGuide} history={this.props.history} />
                <Footer image={false} />
            </React.Fragment>
        );
    }
}

export default Home;
