import React, { Component } from 'react';

class Select extends Component {
    constructor(props) {
        super(props);

        this.state = {
            option: this.props.defaultOption !== undefined ? this.props.defaultOption : this.props.options[0]
        };
    }

    handleChange(event) {
        this.props.onChangeHandler(event);
    }

    handleOptionChange(event) {
        this.setState({
            option: event.target.value
        });
    }

    render() {
        const {
            options,
            name,
            label,
            required,
            onChangeHandler,
            isRow
        } = this.props;

        const selectList = options.map((option, index) => {
            return (
                <option key={index} >{option}</option>
            );
        });

        return (
            <div className={`select-wrapper${isRow ? ' row' : ''}`} onChange={onChangeHandler}>
                <label htmlFor={`${name}Select`}>{label}{required ? <span className="mandatory"> *</span> : null}</label>
                <select name={name} id={`${name}Select`} value={this.state.option} onChange={event => this.handleOptionChange(event)}>
                    {selectList}
                </select>
            </div>
        );
    }
}

Select.defaultProps = {
    onChangeHandler: () => {}
};

export default Select;
