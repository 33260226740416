import React from 'react';
import ReactDOM from 'react-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import Button from "./Buttons/Button";
import { useSelector } from 'react-redux';

const ResetPopup = ({
    showPopup,
    onConfirm,
    onCancel
}) => {
    const contractor = useSelector((state) => state.contractor);
    
    const handleCloseClick = () => {
        onCancel();
    };

    const handleSubmit = () => {
        onConfirm();
    };

    showPopup ? document.querySelector('html').classList.add('overlay') : document.querySelector('html').classList.remove('overlay');

    return ReactDOM.createPortal(
        showPopup ?
            <div className="popup-container">
                <div className="row-padding flex-center popup-wrapper">
                    <div className="column small-12 medium-6 popup-content">
                        <div className="popup-top">
                            <h2 style={{color:contractor.theme}}>Pagina verlaten</h2>
                            <FontAwesomeIcon icon={faTimesCircle} className="popup-close" onClick={handleCloseClick} />
                        </div>
                        <div className="popup-message">Weet je zeker dat je de pagina wil verlaten? Alle wijzigingen gaan hierdoor verloren</div>
                        <div className="flex-space-evenly popup-buttons">
                        <Button
                                icon={false}
                                onClickHandler={handleCloseClick}
                                size="small"
                                color="yellow"
                                label="Nee"
                            />
                            <Button
                                icon={false}
                                onClickHandler={handleSubmit}
                                size="small"
                                color="yellow"
                                label="Ja"
                            />
                            
                        </div>
                    </div>
                </div>
            </div> : null,
        document.querySelector('#root')
    );
};

export default ResetPopup;
