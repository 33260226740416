import React, { Component } from 'react';
import TopHeader from '../components/Ui/TopHeader';
import ContentSection from '../components/Ui/ContentSection';
import Footer from '../components/Ui/Footer';
import LoginContainer from "../containers/LoginContainer";

class Login extends Component {
    render() {
        const login = <LoginContainer setToken={this.props.setToken} />;

        return (
            <React.Fragment>
                <TopHeader title="" hideSideBar={true} />
                <ContentSection content={login} />
                <Footer image={true} />
            </React.Fragment>
        );
    }
}

export default Login;
