import React, { useEffect, useState } from "react";
import TopHeader from "../components/Ui/TopHeader";
import Footer from "../components/Ui/Footer";
import { useSelector } from "react-redux";
import Loader from "./Loader";
import { urls } from "../global/iframe_urls";

const Projecten = (props) =>  {
    const {installerId} = useSelector((state) => state.contractor);

    const [customerId, setCustomerId] = useState('');
    useEffect(() => {
      const search = props.history.location.search;
     if(search !== "") {
       let customerId = search.includes('customerId') 
       if(customerId) {
         setCustomerId(search.split('=')[1]);
       }
     }
    },[])

    

    return (
      <React.Fragment>
        <TopHeader title={`Projecten`} history={props.history} />
        <div className="user-manager">
            <Loader iframe="hubspot-frame" duration="1.5" />
            <iframe
                allowFullScreen
                allowTransparency
                height="100%"
                width="100%"
                id="hubspot-frame"
                src={`${urls.Projecten}${installerId}${customerId !== "" ? `&customerId=${customerId}`:''}`}
            >
            </iframe>
        </div>
        <Footer image={false} />
        <script>
          
        </script>
      </React.Fragment>
    );
  
  };

export default Projecten
