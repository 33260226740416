import { connect } from 'react-redux';
import Login from "../components/Login/Login";
import {SET_CONTRACTOR_INSTALLER} from '../state/actions/actionTypes'

const mapStateToProps = (state, ownProps) => {
    return {
        contractor: state.contractor,
        setToken: ownProps.setToken
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setInstallerHandler: (installer) => {
            dispatch({
                type: SET_CONTRACTOR_INSTALLER,
                installer: installer
            });
        }
    };
};

const LoginContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);

export default LoginContainer;
