import React, { Component } from 'react';
import TopHeader from '../components/Ui/TopHeader';
import ContentSection from '../components/Ui/ContentSection';
import Footer from '../components/Ui/Footer';
import VisitResumeContainer from "../containers/VisitResumeContainer";

class VisitResumeView extends Component {
    render() {
        const resumeVisit = <VisitResumeContainer history={this.props.history} />;

        return (
            <React.Fragment>
                <TopHeader title="Solar4All" />
                <ContentSection content={resumeVisit} />
                <Footer image={false} />
            </React.Fragment>
        );
    }
}

export default VisitResumeView;
