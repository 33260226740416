import React, { Component } from 'react';
import TopHeader from '../components/Ui/TopHeader';
import ContentSection from '../components/Ui/ContentSection';
import Footer from '../components/Ui/Footer';
import VisitRoofContainer from "../containers/VisitRoofContainer";

class VisitRoofView extends Component {
    render() {
        const roofVisit = <VisitRoofContainer history={this.props.history} />;

        return (
            <React.Fragment>
                <TopHeader title="Solar4All" />
                <ContentSection content={roofVisit} />
                <Footer image={false} />
            </React.Fragment>
        );
    }
}

export default VisitRoofView;
