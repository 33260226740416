import React, { Component } from 'react';
import TopHeader from '../components/Ui/TopHeader';
import ContentSection from '../components/Ui/ContentSection';
import Footer from '../components/Ui/Footer';
import TableContainer from "../containers/TableContainer";

class AdminHome extends Component {
    render() {
        const visitTable = <TableContainer history={this.props.history} />;

        return (
            <React.Fragment>
                <TopHeader title="Admin Home" history={this.props.history}/>
                <ContentSection content={visitTable} />
                <Footer image={false} />
            </React.Fragment>
        );
    }
}

export default AdminHome;
