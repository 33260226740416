import { connect } from 'react-redux';
import VisitProducts from "../components/Visit/VisitProducts";
import {
    SET_FORM_PROGRESS,
    RESET_VISIT_FORM,
    SET_PRODUCT_CHOISE,
    RESET_FORM_PROGRESS
} from '../state/actions/actionTypes'

const mapStateToProps = (state) => {
    return {
        visit: state.visit,
        progress: state.progress,
        contractor: state.contractor
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        submitProductChoise: (product, type) => {
            dispatch({
                type: SET_PRODUCT_CHOISE,
                product: product,
                property: type
            });
        },
        setFormProgress: (progress) => {
            dispatch({
                type: SET_FORM_PROGRESS,
                progress: progress
            });
        },
        resetForm: () => {
            dispatch({
                type: RESET_VISIT_FORM
            });
            dispatch({
                type: RESET_FORM_PROGRESS
            });
        }
    };
};

const VisitProductsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(VisitProducts);

export default VisitProductsContainer;
