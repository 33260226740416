import { useState } from 'react';

export const useToken = () => {
    const getToken = () => {
        const tokenString = sessionStorage.getItem('installer');
        const userToken = JSON.parse(tokenString);

        return userToken !== undefined ? userToken : undefined;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        sessionStorage.setItem('installer', JSON.stringify(userToken));
        setToken(userToken);
    };

    return {
        setToken: saveToken,
        token
    }
};
