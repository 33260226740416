import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Footer from '../components/Ui/Footer'
import TopHeader from '../components/Ui/TopHeader'
import { urls } from '../global/iframe_urls'
import Loader from './Loader'

const Home = (props) => {
    const {installerId} = useSelector((state) => state.contractor);

    const [customerId, setCustomerId] = useState('');
   useEffect(() => {
     const search = props.history.location.search;
     console.log(search)
    if(search !== "") {
      let customerId = search.includes('customerId') 
      if(customerId) {
        setCustomerId(search.split('=')[1]);
      }
    }
   },[])

    return (
        <React.Fragment>
        <TopHeader title={`Home`} history={props.history} />
        <div className="user-manager">
            <Loader iframe="hubspot-frame" duration="1.5" />
            <iframe
                allowFullScreen
                height="100%"
                width="100%"
                id="hubspot-frame"
                src={`${urls.home}${installerId}${customerId !== "" ? `&customerId=${customerId}`:''}`}
            >
          </iframe>
        </div>
        <Footer image={false} />
        <script>
            
        </script>
      </React.Fragment>
    )
}

export default Home
