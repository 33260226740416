import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import "./assets/scss/styles.scss";
import { routePath } from "./global/RouteConstants";
// import Home from "./view-containers/AdminHome";
import VisitPlanView from "./view-containers/VisitPlan";
import VisitSetupView from "./view-containers/VisitSetup";
import VisitInfoView from "./view-containers/VisitInfo";
import VisitRoofView from "./view-containers/VisitRoof";
import VisitProductsView from "./view-containers/VisitProducts";
import VisitResumeView from "./view-containers/VisitResume";
import Settings from "./view-containers/Settings";
import Login from "./view-containers/Login";
import Loader from "./components/Ui/Loader";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import store from "./state/configureStore";
import { useToken } from "./global/UseToken";
import AdminCrm from "./view-containers/AdminCrm";
import UserDetail from "./view-containers/UserDetail";
import HubSpot from "./view-containers/HubSpot";
import AssemblyPlanning from "./view-containers/AssemblyPlanning";
import Dashboard from './view-containers/Dashboard';
import Tickets from './view-containers/Tickets';
import AdminHome from "./view-containers/AdminHome";
import Admin from "./view-containers/Admin";
import Home from "./view-containers/Home";
import Taken from "./view-containers/Taken";
import Crm from "./view-containers/Crm";

import ResourcePlanner from "./view-containers/ResourcePlanner";
import Projecten from "./view-containers/Projecten";
import Agenda from "./view-containers/Agenda";
import Afspraken from "./view-containers/Afspraken";
import Oplevering from "./view-containers/Oplevering";

const App = () => {
  const history = createBrowserHistory();
  const { token, setToken } = useToken();

  return (
    <Provider store={store}>
      <BrowserRouter history={history}>
        <Switch>
          <Route exact path={routePath.login} component={() => token === null ? (<Login setToken={setToken} />):(<Redirect to="/home" /> )}/>
          <Route exact path={routePath.home} component={(props) => token === null ? <Redirect to="/" /> : <Home {...props} />} />
          <Route exact path={routePath.visitPlan} component={(props) => token === null ? (<Redirect to="/" />) : (<VisitPlanView {...props} />)}/>
          <Route exact path={routePath.visitSetup} component={(props) => token === null ? ( <Redirect to="/" />) : (<VisitSetupView {...props} />)}/>
          <Route exact path={routePath.visitInfo} component={(props) =>token === null ? (<Redirect to="/" />) : (<VisitInfoView {...props} />)}/>
          <Route exact path={routePath.visitRoof} component={(props) =>   token === null ? (<Redirect to="/" />   ) : (<VisitRoofView {...props} />) }/>
          <Route exact path={routePath.visitResume} component={(props) =>   token === null ? (<Redirect to="/" />) : (<VisitResumeView {...props} />) }/>
          <Route exact path={routePath.settings} component={(props) =>   token === null ? <Redirect to="/" /> : <Settings {...props} /> }/>
          <Route exact path={routePath.adminCrm} component={(props) =>   token === null ? <Redirect to="/" /> : <AdminCrm {...props} /> }/>
          <Route exact path={routePath.userDetail} component={(props) =>   token === null ? <Redirect to="/" /> : <UserDetail {...props} /> }/>
          <Route exact path={routePath.managementSystem} component={(props) =>   token === null ? <Redirect to="/" /> : <HubSpot {...props} /> }/>
          <Route exact path={routePath.assemblyPlanning} component={(props) =>   token === null ? <Redirect to="/" /> : <AssemblyPlanning {...props} /> }/>
          <Route exact path={routePath.dashboard} component={(props) =>   token === null ? <Redirect to="/" /> : <Dashboard {...props} /> }/>
          <Route exact path={routePath.tickets} component={(props) =>   token === null ? <Redirect to="/" /> : <Tickets {...props} /> }/>
          <Route exact path={routePath.taken} component={(props) =>   token === null ? <Redirect to="/" /> : <Taken {...props} /> }/>
          <Route exact path={routePath.adminHome} component={(props) =>   token === null ? <Redirect to="/" /> : <AdminHome {...props} />  }/>
          <Route exact path={routePath.admin} component={(props) => token === null ? <Redirect to="/"/> : <Admin {...props} />} />
          <Route exact path={routePath.crm} component={(props) =>   token === null ? <Redirect to="/" /> : <Crm {...props} /> }/>
          <Route exact path={routePath.ResourcePlanner} component={(props) => token === null ? <Redirect to="/" /> : <ResourcePlanner {...props} />} />
          <Route exact path={routePath.projecten} component={(props) => token === null ? <Redirect to="/" /> : <Projecten {...props} />} />
          <Route exact path={routePath.mijn_agenda} component={(props) => token === null ? <Redirect to="/" /> : <Agenda {...props} />} />
          <Route exact path={routePath.afspraken} component={(props) => token === null ? <Redirect to="/" /> : <Afspraken {...props} />} />
          <Route exact path={routePath.oplevering} component={(props) => token === null ? <Redirect to="/" /> : <Oplevering {...props} />} />
          {/* Afspraken */}
          <Route component={() => <Redirect to="/" />} />
        </Switch>
      </BrowserRouter>
      <Loader />
    </Provider>
  );
};

export default App;
