import React from 'react';
import InputRange from "../../Ui/Inputs/InputRange";
import Radio from "../../Ui/Inputs/Radio";
import constants from "../../../global/Constants";

const RoofDataAdditional = ({
    roof
}) => {
    const radioButtonValue = constants.roofSide.find(side => roof.side === side.value) !== undefined ? constants.roofSide.find(side => roof.side === side.value) : constants.roofSide[0];

    return (
        <React.Fragment>
            <InputRange
                min={1}
                max={100}
                value={roof.panels}
                name={`panels-${roof.id}`}
                label="Aantal panelen"
                disabled={true}
            />
            <InputRange
                min={1}
                max={60}
                value={roof.tilt}
                name={`tilt-${roof.id}`}
                label="Hellingshoek"
                disabled={true}
            />
            <InputRange
                min={1}
                max={150}
                value={roof.area}
                name={`area-${roof.id}`}
                label="Oppervlakte"
                disabled={true}
            />
             <Radio
                options={constants.roofSide}
                defaultOption={radioButtonValue}
                name={`side-${roof.id}`}
                label="Zijde dakvlak"
                disabled={true}
            />
        </React.Fragment>
    )
};

export default RoofDataAdditional;
