import React,{ Component } from 'react';
import {ScheduleComponent,ResourcesDirective,ResourceDirective,ViewsDirective,ViewDirective,Inject,TimelineViews,TimelineMonth} from "@syncfusion/ej2-react-schedule";
// import './external-drag-drop.css';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { extend, closest, remove, addClass, L10n, isNullOrUndefined } from "@syncfusion/ej2-base";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
// syncfusion editor window editing
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars"

// API CALLS IMPORTS
import { fetchWithDelay } from "../../global/FetchWithDelay";
import { trackPromise } from "react-promise-tracker";
import constants from "../../global/Constants";
import "./ResourcePlanner.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers,faCalendar, faMapMarked, faMapMarker, faFunnelDollar} from '@fortawesome/free-solid-svg-icons';
import { postWithDelay } from '../../global/PostWithDelay';
import { DeleteWithDelay } from '../../global/DeleteWithDelay';
import moment from 'moment';

L10n.load({
  'en-US': {
      'schedule': {
          'saveButton': 'Aanmaken',
          'cancelButton': 'Sluiten',
          'deleteButton': 'Verwijderen',
          'newEvent': 'Nieuw evenement',
          'today':'vandaag',
          "editEvent": "Updaten evenement",
          "deleteEvent":"Verwijder evenement",
          "deleteContent": "Weet je zeker dit evenement te willen verwijderen?",
          "delete": "Verwijderen",
          "cancel": "Annuleren",
      },
  }
});

export default class ResourcePlanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      installer:
        this.props.contractor.installer.token !== ""
          ? this.props.contractor.installer
          : JSON.parse(sessionStorage.getItem("installer")),
      events:[],
      resources:[],
      teams:[],
      leads: {
        dataSource: [],
        id: "customerId",
        text: "name",
      },
      leadsDropdown:[],
      eventTypeDropdown:[],
      eventStatusDropdown:[],
      leadTypesDrowpdown:[],
      eventTypeField: { text: 'eventTypeName', value: 'eventTypeId' },
      teamsDropdownField:{ text: 'name', value: 'teamId'},
      resourcesDropdownField:{ text: 'installerName', value: 'installerId'},
      eventStatusDropdownField:{ text: 'eventStatusName', value: 'eventStatusId'},
      leadDrowpdownField:{ text: 'displayName', value: 'visitId'},
      leadTypesDrowpdownField:{text:'name', value:'leadTypeId'},
      teamDropdownValue:null,
      checkboxState:false,
      removeEventId:""
    };
    this.checkbox = false
  };

  componentDidMount() {
    this.getLeads();
    this.getResources();
    this.getEventTypes();
    this.getEventStatus();
    this.getLeadType();
    this.getCustomers();
  }

  onItemDrag(event) {
    if (this.scheduleObj.isAdaptive) {
        let classElement = this.scheduleObj.element.querySelector('.e-device-hover');
        if (classElement) {
            classElement.classList.remove('e-device-hover');
        }
        if (event.target.classList.contains('e-work-cells')) {
            addClass([event.target], 'e-device-hover');
        }
    }
    if (document.body.style.cursor === 'not-allowed') {
        document.body.style.cursor = '';
    }
    if (event.name === 'nodeDragging') {
        let dragElementIcon = document.querySelectorAll('.e-drag-item.treeview-external-drag .e-icon-expandable');
        for (let i = 0; i < dragElementIcon.length; i++) {
            dragElementIcon[i].style.display = 'none';
        }
    }
}
onActionBegin(event) {
    if (event.requestType === 'eventCreate' && this.isTreeItemDropped) {
        let treeViewdata = this.treeObj.fields.dataSource;
        const filteredPeople = treeViewdata.filter((item) => item.Id !== parseInt(this.draggedItemId, 10));
        this.treeObj.fields.dataSource = filteredPeople;
        let elements = document.querySelectorAll('.e-drag-item.treeview-external-drag');
        for (let i = 0; i < elements.length; i++) {
            remove(elements[i]);
        }
    }
}

onActionComplete(event) {
  if(event.requestType === "eventRemoved" && !isNullOrUndefined(event.data)) {
    this.dltEvent(event.data[0].eventId);
  }
}

onTreeDragStop(event) {
    let treeElement = closest(event.target, '.e-treeview');
    let classElement = this.scheduleObj.element.querySelector('.e-device-hover');
    if (classElement) {
        classElement.classList.remove('e-device-hover');
    }
    if (!treeElement) {
        event.cancel = true;
        let scheduleElement = closest(event.target, '.e-content-wrap');
        if (scheduleElement) {
            let treeviewData = this.treeObj.fields.dataSource;
            if (event.target.classList.contains('e-work-cells')) {
                const filteredData = treeviewData.filter((item) => item.customerId === event.draggedNodeData.id);
                let cellData = this.scheduleObj.getCellDetails(event.target);
                let resourceDetails = this.scheduleObj.getResourcesByIndex(cellData.groupIndex);
                const inProgressEvent = this.state.eventStatusDropdown.filter((item) => item.eventStatusName === "In progress")
                let eventData = {
                  installerId: resourceDetails.resourceData.installerId,
                  teamId: resourceDetails.resourceData.teamId,
                  eventTypeId:filteredData[0].eventTypeId,
                  eventStatusId:inProgressEvent[0].eventStatusId,
                  visitId:filteredData[0].visitId,
                  leadTypeId:filteredData[0].leadTypeId,
                  eventId:filteredData[0].eventId,
                  customerId:filteredData[0].customerId,
                  eventStart: cellData.startTime,
                  eventEnd: cellData.endTime,
                };
                this.scheduleObj.openEditor(eventData, 'Add', true);
                this.isTreeItemDropped = true;
                this.draggedItemId = event.draggedNodeData.id;
            }
        }
    }
}

getCustomers = () => {
  const apiUrl = `${constants.api.getCustomersList}?installerId=${this.state.installer.installerId}`;
  trackPromise(
    fetchWithDelay(apiUrl, 0, this.state.installer.token)
      .then((data) => {
        data = data.map(({firstName,lastName,streetName,houseNumber,houseNumberAddition,city,postalCode, ...value}) => (
          {
            displayName:`${firstName}${" "}${lastName}${", "}${streetName}${" "}${houseNumber} ${houseNumberAddition}${", "}${postalCode}${", "}${city}`,
            firstName,lastName,streetName,houseNumber,houseNumberAddition,city,postalCode,
            ...value
          }
       ));
       this.setState({leadsDropdown:data});
      })
      .catch((error) => {
        console.log("errors", error);
      })
  );
}

  getLeads =() => {
    const apiUrl = `${constants.api.getLeads}/?installerId=${this.state.installer.installerId}`;
    trackPromise(
      fetchWithDelay(apiUrl, 0, this.state.installer.token)
        .then((data) => {
          data = data.map(({firstName,lastName,streetName,houseNumber,houseNumberAddition,city,postalCode, ...value}) => (
            {
              displayName:`${firstName}${" "}${lastName}${", "}${streetName}${" "}${houseNumber} ${houseNumberAddition}${", "}${postalCode}${", "}${city}`,
              firstName,lastName,streetName,houseNumber,houseNumberAddition,city,postalCode,
              ...value
            }
         ));
          this.setState({ leads:{
            dataSource:data,
            id: "customerId",
            text: "eventTypeName",
          }, 
        });
        })
        .catch((error) => {
          console.log("errors", error);
        })
    );
  }

  getEvents = (removeEvent) => {
    const apiUrl = `${constants.api.getEvents}/?installerId=${this.state.installer.installerId}`;
    trackPromise(
      fetchWithDelay(apiUrl, 0, this.state.installer.token)
        .then((data) => {
          let list = data.filter((item) => item.eventStatusId !== removeEvent);
          // console.log(JSON.stringify(list, undefined,2))
          this.setState({events:list})
        })
        .catch((error) => {
          console.log("errors", error);
        })
    );
  }

  getResources =() => {
    const apiUrl = `${constants.api.getResources}/?installerId=${this.state.installer.installerId}`;
    trackPromise(
      fetchWithDelay(apiUrl, 0, this.state.installer.token)
        .then((data) => {
          data = data.map(({installerFirstName, installerLastName, ...value}) => (
            {
              installerName:`${installerFirstName}${" "}${installerLastName}`,
              installerFirstName, installerLastName,
              ...value
            }
          ))
          this.setState({resources:data}, () => {
            this.getTeams(data)
          });
        })
        .catch((error) => {
          console.log("errors", error);
        })
    );
  }

  getTeams =(resources) => {
    const apiUrl = `${constants.api.getTeams}/?installerId=${this.state.installer.installerId}`;
    trackPromise(
      fetchWithDelay(apiUrl, 0, this.state.installer.token)
        .then((data) => { 
          for(let i = 0; i < data.length; i++) {
            for(let j = 0 ; j< resources.length; j++) {
            if(data[i].name === resources[j].name) {
              data[i]["teamId"] = resources[j].teamId;
            }
            }
          };
          this.setState({teams:data});
        })

        .catch((error) => {
          console.log("errors", error);
        })
    );
  }


 getEventTypes = () => {
   const url = `${constants.api.getEventType}`
    trackPromise(
      fetchWithDelay(url, 0, this.state.installer.token)
        .then((data) => { 
          this.setState({eventTypeDropdown:data});
        })

        .catch((error) => {
          console.log("errors", error);
        })
    );
}

getEventStatus = () => {
  const url = `${constants.api.getEventStatus}`;
  trackPromise(
    fetchWithDelay(url, 0, this.state.installer.token)
      .then((data) => { 
        let eventStat = data.filter((item) => item.eventStatusName === "To be planned")
          this.setState({removeEventId:eventStat[0].eventStatusId}, ()=> {
            this.getEvents(eventStat[0].eventStatusId);
          })
        this.setState({eventStatusDropdown:data});
      })
      .catch((error) => {
        console.log("errors", error);
      })
  );
}

getLeadType = () => {
  const url = `${constants.api.getLeadTypes}`;
  trackPromise(
    fetchWithDelay(url, 0, this.state.installer.token)
      .then((data) => { 
        this.setState({leadTypesDrowpdown:data});
      })
      .catch((error) => {
        console.log("errors", error);
      })
  );
};

createEvent = (eventObject) => {
  trackPromise(
    postWithDelay(constants.api.createEvent, 0,eventObject,this.state.installer.token)
      .then((data) => { 
        this.getEvents(this.state.removeEventId);
        this.getLeads();
      })
      .catch((error) => {
        console.log("errors", error);
      })
  );
}

dltEvent = (eventId) => {
  const url = `${constants.api.deleteEvent}/?eventId=${eventId}`
  trackPromise(
    DeleteWithDelay(url, 0,this.state.installer.token)
      .then((data) => { 
        this.getEvents(this.state.removeEventId)
      })
      .catch((error) => {
        console.log("errors", error);
      })
  );
}

updateEvent = (eventObject) => {
  const url = `${constants.api.updateEvent}`;
  trackPromise(
    postWithDelay(url, 0,eventObject,this.state.installer.token)
      .then((data) => { 
        this.getEvents(this.state.removeEventId)
        this.getLeads();
      })
      .catch((error) => {
        console.log("errors", error);
      })
  );
};

updateCheckboxState = (event) => {
  this.checkbox = event.checked;
}


  treeTemplate(props) {
    return (
      <div id="waiting" className = "wait">
        <div id="waitdetails">
          <div id="waitlist">
            {props.firstName}{" "}{props.lastName}
            <div>
              {props.streetName}{" "}{props.houseNumber}
            </div>
            <div>{props.postalCode}{" "}{props.city}</div>
          </div>
          <div id="waitcategory">
          {'LeadType'}:{' '}{props.name}
          </div>
        </div>
      </div>
    );
  }

  onPopupOpen(args) {
    if (args.type === 'Editor') {
        let desc = args.element.querySelector("#eventDescription");
        desc.value =  args.data.eventDescription !== undefined ? args.data.eventDescription : "";
        let eventType = document.getElementById('_dialog_wrapper_title').getElementsByClassName('e-title-text')[0].textContent;
               
        if(eventType === "Updaten evenement") {
          let leadSection = document.getElementById('leads-section');
          let disableSpan = leadSection.getElementsByClassName('e-input-group e-control-wrapper e-ddl e-lib e-keyboard e-valid-input')
          disableSpan[0].classList.add('e-disabled');
        }
        
      }
}



onPopupClose(args) {
  if (args.type === 'Editor' && !isNullOrUndefined(args.data)) { 
    let descriptionElement = args.element.querySelector('#eventDescription');
    let eventId = args.element.querySelector('#event').value;
    let customerId = args.element.querySelector('#customer').value;
    let eventType = document.getElementById('_dialog_wrapper_title').getElementsByClassName('e-title-text')[0].textContent;
    let createEventObject = {};

    var formatter= 'YYYY-MM-DD[T]HH:mm:ss';
    createEventObject["visitId"] = args.data.visitId;
    createEventObject["installerId"] = args.data.installerId;
    createEventObject["eventTypeId"] = args.data.eventTypeId;
    createEventObject["eventStatusId"] = args.data.eventStatusId;
    createEventObject["eventDescription"] = descriptionElement.value;
    createEventObject["leadTypeId"] = args.data.leadTypeId;
    createEventObject["eventStart"] = moment(new Date(args.data.eventStart)).format(formatter);
    createEventObject["eventEnd"] = moment(new Date(args.data.eventEnd)).format(formatter); 

    if(this.checkbox) {
      const {installer} = this.props.contractor;
      var original = document.getElementById('visitId').ariaLabel;
     let destinationAddress = original.split(',');
     let trimedArray = destinationAddress.map(Function.prototype.call, String.prototype.trim);
     trimedArray.shift();
      createEventObject["googleDistance"] = {
        origin:`${installer.installerStreet} ${installer.installerHousenumber}${installer.installerHousenumberAddition !== null ? installer.installerHousenumberAddition : ""}, ${installer.installerPostalCode}, ${installer.installaerCity}`,
        destination:trimedArray.join(', ')
      };
    }
   
    if(eventType === "Updaten evenement") {
      createEventObject["eventId"] = eventId;
      createEventObject["customerId"] = customerId;
    }
    if(eventId !=="" && customerId !=="" && eventType === "Nieuw evenement") {
      createEventObject["eventId"] = eventId;
      createEventObject["customerId"] = customerId;
      this.updateEvent(createEventObject);
    }else if(eventType !=="Updaten evenement") {
      this.createEvent(createEventObject)
    }
    if(eventType === "Updaten evenement") {
      this.updateEvent(createEventObject);
    } 
    this.checkbox = false;
    console.log(createEventObject);
        
  }
}

onChange = (args) => {
  this.autoDropdownSelect.value = args.itemData ? args.itemData.leadTypeId : null
}

editorTemplate(props){
    return props !== undefined ? (
      <table className="custom-event-editor" style={{ width: "100%", cellpadding: "5" }}>
        <tbody>
          <tr className="popup-header-container lead-tr">
          <hr className="lead-border"/>
          <td className="leads" valign="middle" align="center"><FontAwesomeIcon icon={faUsers} className="bg-color-white" />{" "}<span className="bg-color-white" >Medewerker</span> </td>
        </tr>
        <input id="eventTitle" className="e-field e-input" type="text" name="eventTitle" style={{ display:"none"}}/>
        <p className ="title">Team</p>
          <tr >
            <td colSpan={4}>
            <DropDownListComponent
                id="team"
                placeholder="Choose team"
                data-name="teamId"
                className="e-field"
                style={{ width: "100%" }}
                dataSource={[...this.state.teams]}
                fields={this.state.teamsDropdownField}
                value={props.teamId || null}
              ></DropDownListComponent>
            </td>
          </tr>

          <p className ="title">Medewerker</p>
          <tr >
            <td colSpan={4}>
            <DropDownListComponent
                id="medewerker"
                placeholder="Select Resource"
                data-name="installerId"
                className="e-field"
                style={{ width: "100%" }}
                dataSource={this.state.resources}
                fields={this.state.resourcesDropdownField}
                value={props.installerId || null}
              ></DropDownListComponent>
            </td>
          </tr>
        <tr className="popup-header-container lead-tr">
        <hr className="lead-border"/>
          <td className="leads" valign="middle" align="center"><FontAwesomeIcon icon={faCalendar} className = "icons bg-color-white"/>{" "}<span className="bg-color-white"> Evenementen </span></td>
        </tr>


        <p className ="title">Tijd</p>
        <div className = "popup-date-picker">
           <div>
        <tr>
            <td colSpan={4}>
              <DateTimePickerComponent
                format="dd/MM/yy HH:mm"
                timeFormat="HH:mm"
                id="eventStart"
                data-name="eventStart"
                value={new Date(props.startTime || props.StartTime)}
                className="e-field"
              ></DateTimePickerComponent>
            </td>
          </tr>
          </div>
              
              <div>
          <tr>
            <td colSpan={4}>
              <DateTimePickerComponent
                format="dd/MM/yy HH:mm"
                timeFormat="HH:mm"
                id="eventEnd"
                data-name="eventEnd"
                value={new Date(props.endTime || props.EndTime)}
                className="e-field"
              ></DateTimePickerComponent>
            </td>
          </tr>
             </div>
         </div>
          <p className ="title">Evenement Type</p>
          <tr>
            <td colSpan={4}>
              <DropDownListComponent
                id="EventType"
                placeholder="Choose Event Type"
                data-name="eventTypeId"
                className="e-field"
                style={{ width: "100%" }}
                dataSource={this.state.eventTypeDropdown}
                fields={this.state.eventTypeField}
                value={props.EventType || null}
              ></DropDownListComponent>
            </td>
          </tr>

          <p className ="title">Evenement Status</p>
          <tr>
            <td colSpan={4}>
              <DropDownListComponent
                id="Evenement-status"
                placeholder="Choose Evenement status"
                data-name="eventStatusId"
                className="e-field"
                style={{ width: "100%" }}
                dataSource={this.state.eventStatusDropdown}
                fields={this.state.eventStatusDropdownField}
                value={props.eventStatusId || null}
              ></DropDownListComponent>
            </td>
          </tr>
          <tr className="popup-header-container lead-tr">
            <hr className="lead-border"/>
          <td className="leads" valign="middle" align="center"  > <FontAwesomeIcon icon={faFunnelDollar} className="bg-color-white"/>{" "} <span className="bg-color-white">Leads</span> </td>
        </tr>
        <tr>
            <td colSpan={4} id='leads-section'>
              <DropDownListComponent
                id="visitId"
                placeholder="Choose Lead"
                data-name="visitId"
                className="e-field"
                style={{ width: "100%" }}
                dataSource={this.state.leadsDropdown}
                fields={this.state.leadDrowpdownField}
                change={this.onChange.bind(this)}
                value={props.visitId || null}
              ></DropDownListComponent>
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
            <CheckBoxComponent 
              label="Bereken reistijd" 
              id="bereken-reistijd"
              name="bereken-reistijd"
              checked={this.checkbox} 
              change={this.updateCheckboxState}
              />  
            </td>
            
          </tr>

          <tr>
            <td colSpan={4}>
            <DropDownListComponent
                id="LeadType"
                placeholder="Choose LeadType"
                data-name="leadTypeId"
                className="e-field"
                style={{ width: "100%" }}
                dataSource={this.state.leadTypesDrowpdown}
                fields={this.state.leadTypesDrowpdownField}
                ref={scope => { 
                  this.autoDropdownSelect = scope; 
              }} 
                value={props.EventType || null}
              ></DropDownListComponent>
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
                <textarea 
                placeholder="opmerkingen"
                  id="eventDescription" 
                  className="e-input" 
                  name="eventDescription" 
                  // value={props.eventDescription || null}
                  rows={3} cols={50} 
                  style={{ 
                    width: '100%', 
                    height: '60px !important', 
                    resize: 'vertical' 
                  }}>
                </textarea>
                <input id="event" name="event" value={props.eventId} style={{display:"none"}}/>
                <input id="customer" name="customer" value={props.customerId} style={{display:"none"}}/>
            </td>
          </tr>
        
        </tbody>
      </table>
    ) : (
      <div></div>
    );
}



  render() {
    let date = new Date();
    return (
      <div className="schedule-control-section">
      <div className="col-lg-12 control-section">
        <div
          className="control-wrapper drag-sample-wrapper"
          style={{ display: "flex" }}
        >
          <div className="schedule-container">
            <div className="title-container">
              <h1 className="title-text">Resource planner</h1>
            </div>
         
       <ScheduleComponent  
        ref={(schedule) => (this.scheduleObj = schedule)}
        height="750px"
        width='100%' 
        currentView='TimelineMonth'
        selectedDate={new Date(date.getFullYear(),date.getMonth(),date.getDate())}
        timeFormat="HH:mm"
        editorTemplate={this.editorTemplate.bind(this)} 
        showQuickInfo={false}
        popupClose={this.onPopupClose.bind(this)}
        popupOpen={this.onPopupOpen.bind(this)}
        eventSettings={{
            dataSource: this.state.events,
            fields: {
              
              subject: { name: 'team', title: 'teamId' },
              subject: { name: 'medewerker', title: 'installerId' },
              subject: { name: 'EventType', title: 'eventTypeId' },
              subject: { name: 'Evenement-status', title: 'eventStatusId' },
              subject: { name: 'lead', title: 'visitId' },
              subject: { name: 'LeadType', title: 'leadTypeId' },
              subject:{name:"event", title:"eventId"},
              subject:{name:"customer", title:"customerId"},
              subject:{name:"bereken-reistijd", title:"bereken-reistijd"},
              description: { name: 'eventDescription', title: 'eventDescription' },
              endTime: { title: "To", name: "eventEnd" },
              startTime: { title: "From", name: "eventStart" },
              subject:{title:'eventTitle', name:'eventTitle'},
            },
          }}
          group={{
            resources: ["Teams","Resources"],
          }}
          
          actionBegin={this.onActionBegin.bind(this)}
          drag={this.onItemDrag.bind(this)}
          actionComplete={this.onActionComplete.bind(this)}
        >
             

            <ResourcesDirective>
              <ResourceDirective 
                field='teamId' 
                title='Teams' 
                name='Teams' 
                dataSource={this.state.teams} 
                allowMultiple={false}
                textField='name' 
                idField='teamId' 
                colorField='RoomColor'
                ></ResourceDirective>

              <ResourceDirective
                field="installerId"
                title="Resource Name"
                name="Resources"
                allowMultiple={false}
                dataSource={this.state.resources}
                textField="installerFirstName"
                idField="installerId"
                groupIDField="teamId"
                colorField="color"
              ></ResourceDirective>

            </ResourcesDirective>
            <ViewsDirective>
              <ViewDirective option='TimelineMonth' displayName="Maand" showWeekend={false}/>
              <ViewDirective option='TimelineWeek' displayName="Week"/>
            </ViewsDirective>

            <Inject services={[TimelineViews, TimelineMonth]}/>
        </ScheduleComponent>
        </div>
        <div className="treeview-container">
              <div className="title-container">
                <h1 className="title-text header-align">Leads</h1>
              </div>
              <TreeViewComponent
                ref={(tree) => (this.treeObj = tree)}
                cssClass="treeview-external-drag"
                dragArea=".drag-sample-wrapper"
                nodeTemplate={this.treeTemplate.bind(this)}
                fields={this.state.leads}
                nodeDragStop={this.onTreeDragStop.bind(this)}
                nodeDragging={this.onItemDrag.bind(this)}
                allowDragAndDrop={true}

              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}


