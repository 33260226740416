import React,{ Fragment }  from 'react';
import CRMTable from '../components/CRM/CRMTable';
import ContentSection from '../components/Ui/ContentSection';
import Footer from '../components/Ui/Footer';
import TopHeader from '../components/Ui/TopHeader';
import CRMTableContainer from '../containers/CRMTableContainer';

const AdminCrm = (props) => {
    const visitTable = <CRMTableContainer history={props.history} />;
    return (
       <Fragment>
           <TopHeader title="CRM" history={props.history}/>
         

                <ContentSection content={visitTable} />
                <Footer image={false} />
       </Fragment>
    )
}

export default AdminCrm
