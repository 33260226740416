import { createStore } from "redux";

import rootReducer from "./reducers";

let loginDetails = JSON.parse(sessionStorage.getItem("installer") || "{}");

const contractor = {
    name: "Solar4All",
    logo: "../assets/images/logo.png",
    basicCosts: 300,
    companyLogo: "../assets/images/logo.png",
    installerId: "",
    profileId: null,
    token: null,
    theme: "#0094c5",
    installer: {
      token: "",
      installerId: "",
      installerHousenumber: "",
      installerHousenumberAddition: "",
      installerPostalCode: "",
      installerStreet: "",
      installaerCity:""
    },
  }


if(loginDetails.token) {
  contractor.companyLogo = loginDetails.companyLogo;
  contractor.installerId = loginDetails.installerId; 
  contractor.profileId = loginDetails.profileId; 
  contractor.token = loginDetails.token;
  contractor.installer.token = loginDetails.token;
  contractor.installer.installerId = loginDetails.installerId; 
  contractor.theme = loginDetails.colorCode;

  contractor.installer.installerHousenumber = loginDetails.installerHousenumber;
  contractor.installer.installerHousenumberAddition = loginDetails.installerHousenumberAddition;
  contractor.installer.installerPostalCode = loginDetails.installerPostalCode;
  contractor.installer.installerStreet = loginDetails.installerStreet;
  contractor.installer.installaerCity = loginDetails.installaerCity
}

const preloadedState = {
  contractor:contractor
}

const store = createStore( rootReducer, preloadedState);

export default store