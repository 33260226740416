import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import VisitPlanContainer from "../../containers/VisitPlanContainer";
import Tabs from "../Ui/Tabs/Tabs";
import VisitInfoContainer from "../../containers/VisitInfoContainer";

const UserDetailPopup = ({ showPopup, handleClose }) => {
  const popup = useRef(null);
  const handlePopupClick = (event) => {
    if (event.target === popup.current) {
      handleClose();
    }
  };

  const handleCloseClick = () => {
    handleClose();
  };

  const disabledScroll = () => {
    document.body.ontouchmove = (event) => {
      event.preventDefault();
    };
  };

  const clearDisabledScroll = () => {
    document.body.ontouchmove = (event) => {
      return true;
    };
  };

  showPopup
    ? document.querySelector("html").classList.add("overlay")
    : document.querySelector("html").classList.remove("overlay");
  showPopup ? disabledScroll() : clearDisabledScroll();

  let tabData = [];
  tabData.push({ title: "Profile Info", content: <VisitPlanContainer /> });
  tabData.push({ title: "Document", content: <VisitInfoContainer /> });

  return ReactDOM.createPortal(
    showPopup ? (
      <div className="popup-container">
        <div
          className="row-padding flex-center popup-wrapper"
          ref={popup}
          onClick={(event) => handlePopupClick(event)}
        >
          <div className="column small-12 medium-10 large-7 popup-sign">
            <div className="popup-top">
              {/* <h2>Uw offerte</h2> */}
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="popup-close"
                onClick={() => handleCloseClick()}
              />
            </div>
            <div className="flex-center">
              <div className="row-padding">
                <div id="user-detail-popup">
                  <Tabs tabItems={tabData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null,
    document.querySelector("#root")
  );
};

export default UserDetailPopup;
