import React, { Component } from 'react';
import TopHeader from '../components/Ui/TopHeader';
import ContentSection from '../components/Ui/ContentSection';
import Footer from '../components/Ui/Footer';
import VisitSetupContainer from "../containers/VisitSetupContainer";

class VisitSetupView extends Component {
    render() {
        const setupVisit = <VisitSetupContainer history={this.props.history} />;

        return (
            <React.Fragment>
                <TopHeader title="Solar4All" />
                <ContentSection content={setupVisit} />
                <Footer image={false} />
            </React.Fragment>
        );
    }
}

export default VisitSetupView;
