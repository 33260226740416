import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Button from '../Ui/Buttons/Button';
import CRMTableList from './CRMTableList';

 class CRMTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            installer: this.props.contractor.installer.token !== '' ? this.props.contractor.installer : JSON.parse(sessionStorage.getItem('installer')),
            themeColor:this.props.contractor
        };

    }

    render() {
        return (
            <React.Fragment>
                <div className="row-padding flex-center">
                    <div className="column small-12 medium-12">
                        <CRMTableList installer={this.state.installer} themeColor={this.state.themeColor} {...this.props} />
                        <div className="flex-center">
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default CRMTable;
