import React, { Component } from 'react';
import ProgressBar from '../Ui/ProgressBar';

class ContentSection extends Component {
    render() {
        return (
            <main className="main-content">                              
                <div className="container">
                    {this.props.content}
                </div>
            </main>
        );
    }
}

export default ContentSection;