import React from 'react';
import { useSelector } from 'react-redux';
import FooterBackground from "../../assets/images/footer.jpg";

const Footer = (props) => {
    const installer = useSelector((state) => state.contractor);


    return (
        <footer className="footer" >
            <div className={`section-footer${!props.image ? '--line' : ''}`} style={{background:installer.theme}} >
                {props.image ? <img className="section-footer-image" src={FooterBackground} alt="Footer"/> : null}
            </div>
        </footer>
    );
}

export default Footer;
