import React, { Component } from "react";
import Input from "../Ui/Inputs/Input";
import Button from "../Ui/Buttons/Button";
import Popup from "../Ui/Popup";
import { trackPromise } from "react-promise-tracker";
import { fetchWithDelay } from "../../global/FetchWithDelay";
import ContractorContainer from "../../containers/ContractorContainer";
import constants from "../../global/Constants";
import ResetPopup from "../Ui/ResetPopup";
import NavigationPrompt from "react-router-navigation-prompt";
import ProgressBar from "../Ui/ProgressBar";
import { postWithDelay } from "../../global/PostWithDelay";
import StepFormInput from "../Ui/Inputs/StepFormInput";
import Checkbox from "../Ui/Inputs/Checkbox";
class VisitPlan extends Component {
  constructor(props) {
    super(props);

    this.next = "/visit-roof";
    this.quick = "/visit-resume";

    this.state = {
      formStep: 0,
      popup: {
        show: false,
        message: "Er is een onbekende fout opgetreden",
        title: "Foutmelding",
      },
      installer:this.props.contractor.installer.token !== "" ? this.props.contractor.installer : JSON.parse(sessionStorage.getItem("installer")),
      themeColor:this.props.contractor,
      profileId: localStorage.getItem("profileId"),
      newVisit: [],
      visit: [],
      formFieldUpdate: false,
      updateFlag: false,
      minimumTime: "00:00",
      persistFlag: true,
    };

    this.zipcodeRegex = constants.patterns.zipcode;
  }

  getAddressInfo = () => {
    trackPromise(
      fetchWithDelay(constants.api.address, 0)
        .then((data) => {
          document.querySelector('input[name="street"]').value =
            data.results[0].address.street;
          document.querySelector('input[name="number-custom"]').value =
            data.results[0].address.number;
          document.querySelector('input[name="addition-custom"]').value = data
            .results[0].address.addition
            ? data.results[0].address.addition
            : "";
          document.querySelector('input[name="city"]').value =
            data.results[0].address.city;
          document.querySelector('input[name="zipcode-custom"]').value =
            data.results[0].address.postal;
        })
        .catch((error) => {
          this.showPopup(error.toString(), "Foutmelding");
        })
    );
  };

  fillMockData = () => {
    this.setState({ formFieldUpdate: true });
    let hours = new Date().getHours();
    let min = new Date().getMinutes();
    trackPromise(
      fetchWithDelay(constants.api.customer, 0)
        .then((data) => {
          document.querySelector('input[name="street"]').value = data.customer.address.street;
          document.querySelector('input[name="number-custom"]').value = data.customer.address.number;
          document.querySelector('input[name="addition-custom"]').value = data.customer.address.addition;
          document.querySelector('input[name="city"]').value = data.customer.address.city;
          document.querySelector('input[name="zipcode-custom"]').value = data.customer.address.zipcode;
          document.querySelector('input[name="firstname"]').value =  data.customer.info.firstName;
          document.querySelector('input[name="lastname"]').value = data.customer.info.lastName;
          document.querySelector('input[name="email"]').value = data.customer.info.emailAddress;
          document.querySelector('input[name="phone"]').value = data.customer.info.phoneNumber;
          document.querySelector('input[name="residents"]').value = data.customer.info.residents;
          document.querySelector('input[name="consumption"]').value = data.customer.info.consumption;
          document.querySelector('input[name="price"]').value = data.customer.info.price;
          document.querySelector('input[name="date"]').value = new Date().toISOString().split("T")[0];
          document.querySelector('input[name="time"]').value = `${hours}:${min}`;
          this.props.submitMockData(data);
        })
        .catch((error) => {
          this.showPopup(error.toString(), "Foutmelding");
        })
    );
  };

  showPopup = (messagePopup, titlePopup) => {
    this.setState({
      popup: {
        show: true,
        message: messagePopup,
        title: titlePopup,
      },
    });
  };

  setCustomerVisit = (quickScan, newVisit) => {
    const visitData = this.state.visit;
    const isQuickScan = quickScan !== undefined && quickScan;
    if (newVisit) {
      let dateStr = visitData.visitDateDisplay.split("-");
      let date = `${dateStr[2]}-${dateStr[1]}-${dateStr[0]}`;
      const customerAddress = {
        street: visitData.customer.streetName,
        number: visitData.customer.houseNumber,
        addition: visitData.customer.houseNumberAddition,
        city: visitData.customer.city,
        zipcode: visitData.customer.postalCode,
        country: "nl",
        id: visitData.building !== null ? visitData.building.buildingId : 0,
      };

      const customerInfo = {
        firstName: visitData.customer.firstName,
        lastName: visitData.customer.lastName,
        emailAddress: visitData.customer.emailAddress,
        phoneNumber: visitData.customer.mobileNumber,
        residents: visitData.building.buildingAmountResidents,
        consumption: visitData.building.buildingYearlyUsage,
        price: visitData.building.buildingkWhPrice,
        date: date,
        visitDateDisplay: visitData.visitDateDisplay,
        time: visitData.visitTime,
        customerId: visitData.customer.customerId,
      };

      const roofsInfo = [];

      visitData.surfaces.map((roof, index) => {
        if (!roof.isDeleted && roof.isActive) {
          roofsInfo.push({
            id: roof.roofSurfaceId,
            title: roof.roofName,
            type: "",
            status: "",
            panels: roof.numberOfPanels,
            height: "",
            width: "",
            length: "",
            tilt: roof.roofInclination,
            tiles: "",
            side: roof.roofOrientation,
            area: roof.roofArea,
            roofQualityId: "",
            roofTile: "",
            roofTypeId: roof.roofTypeId,
          });
        }
      });

      const customerDesign = {
        plain: visitData.building.surfaceDesign[0].surfaceDesignUrl,
        marked: visitData.building.surfaceDesign[0].surfaceDesignUrl,
      };

      this.props.setAddressHandler(customerAddress);
      this.props.submitCustomerInfo(customerInfo);
      this.props.setDesign(customerDesign);
      this.props.setRoofs(roofsInfo);
      this.props.setVisitId(visitData.visitId);
      this.props.setVisitStatusId(visitData.visitStatusId);
      this.props.setCalculationId(visitData.calculationId);
      this.props.setCustomerQuote(visitData.quote);
    }

    this.props.setIsNewVisit(false);

    if (isQuickScan) {
      this.props.setFormProgress([1, 2, 3, 4]);
      this.props.history.push(this.quick);
    } else {
      this.props.setFormProgress([1]);
      this.props.history.push(this.next);
    }
  };

  getCustomerVisit = (visitId) => {
    const apiUrl = `${constants.api.visit}/${visitId}`;

    trackPromise(
      fetchWithDelay(apiUrl, 0, this.state.installer.token)
        .then((data) => {
          this.setState({
            visit: data,
          });
        })
        .then(() => {
          this.setCustomerVisit(this.props.visit.quickScan, true);
        })
        .catch((error) => {
          this.showPopup(error.toString(), "Foutmelding");
        })
    );
  };

  setNovasoleVisit = () => {
    let recivedDate = !this.props.visit.quickScan ? new Date(document.querySelector('input[name="date"]').value) : new Date();
    let timeSplitter = document.querySelector('input[name="time"]').value.split(":");
    let dateSplitter = new Date(recivedDate).toISOString().split("T");
    let timeUpdate = dateSplitter[1].split(":");
    timeUpdate[0] = timeSplitter[0];
    timeUpdate[1] = timeSplitter[1];
    dateSplitter[1] = timeUpdate.join(":");
    const visitData = {
      installerId: this.state.installer.installerId,
      visitDate: dateSplitter.join("T"),
      visitStatusId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      isActive: true,
      isDeleted: false,
      calculationId: 0,
      profileId: this.state.installer.profileId,
      customer: {
        firstName: document.querySelector('input[name="firstname"]').value,
        lastName: document.querySelector('input[name="lastname"]').value,
        emailAddress: document.querySelector('input[name="email"]').value,
        mobileNumber: document.querySelector('input[name="phone"]').value,
        streetName: document.querySelector('input[name="street"]').value,
        houseNumber: parseInt(
          document.querySelector('input[name="number-custom"]').value
        ),
        houseNumberAddition: document.querySelector(
          'input[name="addition-custom"]'
        ).value,
        city: document.querySelector('input[name="city"]').value,
        postalCode: document.querySelector('input[name="zipcode-custom"]').value,
        newsletter: false,
      },
      building: {
        buildingAmountResidents: parseInt(
          document.querySelector('input[name="residents"]').value
        ),
        buildingYearlyUsage: parseInt(
          document.querySelector('input[name="consumption"]').value
        ),
        buildingkWhPrice: parseFloat(
          document.querySelector('input[name="price"]').value
        ),
      },
      novasoleScannerVisitModel: {
        campaignId: "652",
        postalCode: document.querySelector('input[name="zipcode-custom"]').value,
        number: document.querySelector('input[name="number-custom"]').value,
        additive: document.querySelector('input[name="addition-custom"]').value,
        street: document.querySelector('input[name="street"]').value && document.querySelector('input[name="street"]').value.trim(),
        usage: document.querySelector('input[name="consumption"]').value,
        countryCode: "nl",
        commercial: "false",
        // persist: this.state.persistFlag.toString(),
        persist: this.props.visit.quickScan  ? 'false': 'true',
      },
      // ShouldCallNovasole: this.props.visit.newVisit ? true : this.props.visit.quickScan ? true : this.state.formFieldUpdate ? false : true,
      ShouldCallNovasole: this.state.formFieldUpdate ? false : true,
    };
    if (this.props.visit.newVisit ||this.props.visit.quickScan ||this.state.updateFlag) visitData.ShouldCallNovasole = true;

    if (!this.props.visit.newVisit) {
      visitData["visitId"] = this.props.visit.id;
      visitData.customer["customerId"] = this.props.visit.customer.info.customerId;
      visitData.calculationId = this.props.visit.calculationId

    }

    console.log(JSON.stringify(visitData, undefined, 2))
    trackPromise(
      postWithDelay(
        constants.api.addVisit,
        0,
        visitData,
        this.state.installer.token
      )
        .then(
          (data) => {
            this.setState({ newVisit: data }, () => {
              if (this.state.newVisit.isSuccess) {
                this.getCustomerVisit(this.state.newVisit.visitId);
              } else if (
                !this.state.newVisit.isSuccess &&
                this.state.newVisit.length > 0
              ) {
                if (this.state.newVisit.length > 0) {
                  const visitId = this.state.newVisit.filter(({key, value}) => key === "VisitId" && value)
                  this.getCustomerVisit(visitId[0].value);
                }
              } else {
                this.showPopup(this.state.newVisit.message.toString(),"Foutmelding");
              }
            });
          },
          (error) => {
            this.showPopup(error.toString(), "Foutmelding");
          }
        )
        .catch((error) => {
          this.showPopup(error.toString(), "Foutmelding");
        })
    );
  };

  hidePopup = () => this.setState({popup: {show: false}});

  nextPage = (event) => {
    if (this.props.visit.newVisit) {
      event.preventDefault();
      this.setNovasoleVisit();
    } else {
      event.preventDefault();
      if (!this.state.formFieldUpdate && !this.state.updateFlag) {
        this.setCustomerVisit(false, false);
      } else {
        this.setNovasoleVisit();
      }
    }
  };

  componentDidMount() {
    this.dateChangeHandler();
  }

  dateChangeHandler = () => {
    const date = new Date(
      document.querySelector('input[name="date"]').value
    ).getTime();
    const todayDate = new Date(
      new Date().toISOString().split("T")[0]
    ).getTime();
    if (date === todayDate) {
      let hours = new Date().getHours();
      let min = new Date().getMinutes();
      this.setState({ minimumTime: `${hours}:${min}` });
    } else if (date > todayDate) {
      this.setState({ minimumTime: "00:00" });
    } else {
      this.setState({ minimumTime: "00:00" });
    }
  };

  render() {
    const {theme} = this.state.themeColor;
    return (
      <React.Fragment>
        <NavigationPrompt
          when={(current, target) =>
            ![this.quick, this.next, current.pathname].includes(target.pathname)
          }
          afterConfirm={() => this.props.resetForm()}
        >
          {({ onConfirm, onCancel }) => (
            <ResetPopup
              showPopup={true}
              onConfirm={onConfirm}
              onCancel={onCancel}
            />
          )}
        </NavigationPrompt>
        <Popup
          messagePopup={this.state.popup.message}
          titlePopup={this.state.popup.title}
          showPopup={this.state.popup.show}
          handleClose={this.hidePopup}
        />
        <div className="section-logo flex-end flex-align-center">
          <h2 className="address=title" style={{color:theme}}>Vul een adres in</h2>
          <ContractorContainer />
        </div>
        {/* CONDITION WILL BE ADDED HERE  */}
        {!this.props.visit.quickScan && !this.props.visit.visitPageLocation ? (
          <ProgressBar progress={this.props.progress} />
        ) : null}
        <form
          onSubmit={(event) => {
            this.nextPage(event);
          }}
          id="visitPlan"
        >
          <div className="row-padding flex-start">
            <div className="column small-12">
              <h3 style={{color:theme}}>Adres gegevens</h3>
            </div>
          </div>
          <div className="row-padding flex-center">
            <div className="column small-12 medium-6">
              <StepFormInput
                type="text"
                required={true}
                name="street"
                label="Straat"
                placeholder="Straat"
                value={this.props.visit.customer.address.street}
                onChangeHandler={(event) => this.setState({ updateFlag: true })}
              />
              <div className="flex-space-between input-row">
                <StepFormInput
                  type="text"
                  required={true}
                  name="number-custom"
                  label="Huisnummer"
                  placeholder="Huisnummer"
                  value={this.props.visit.customer.address.number}
                  onChangeHandler={(event) =>
                    this.setState({ updateFlag: true })
                  }
                />

                <StepFormInput
                  type="email"
                  required={false}
                  name="addition-custom"
                  label="Toevoeging"
                  placeholder="Toevoeging"
                  value={this.props.visit.customer.address.extra}
                  onChangeHandler={(event) =>
                    this.setState({ formFieldUpdate: true })
                  }
                />
              </div>
            </div>
            <div className="column small-12 medium-6">
              <StepFormInput
                type="text"
                required={true}
                name="city"
                label="Plaats"
                placeholder="Plaats"
                value={this.props.visit.customer.address.city}
                onChangeHandler={(event) => this.setState({ updateFlag: true })}
              />

              <StepFormInput
                type="text"
                required={true}
                pattern={this.zipcodeRegex}
                name="zipcode-custom"
                label="Postcode"
                placeholder="Postcode"
                value={this.props.visit.customer.address.zipcode}
                onChangeHandler={(event) => this.setState({ updateFlag: true })}
              />
            </div>
          </div>
          <div className="row-padding flex-start">
            <div className="column small-12">
              <h3 style={{color:theme}}>Basis gegevens</h3>
            </div>
          </div>
          <div className="row-padding flex-center">
            <div className="column small-12 medium-6">
              <StepFormInput
                type="text"
                required={true}
                name="firstname"
                label="Voornaam"
                placeholder="Voornaam"
                // defaultValue={this.props.visit.customer.info.firstName}
                value={this.props.visit.customer.info.firstName}
                onChangeHandler={(event) =>
                  this.setState({ formFieldUpdate: true })
                }
              />

              <StepFormInput
                type="text"
                required={true}
                name="lastname"
                label="Achternaam"
                placeholder="Achternaam"
                value={this.props.visit.customer.info.lastName}
                onChangeHandler={(event) =>
                  this.setState({ formFieldUpdate: true })
                }
              />

              <StepFormInput
                type="email"
                required={true}
                name="email"
                label="E-mailadres"
                placeholder="E-mailadres"
                value={this.props.visit.customer.info.emailAddress}
                onChangeHandler={(event) =>
                  this.setState({ formFieldUpdate: true })
                }
              />

              <StepFormInput
                type="tel"
                required={true}
                name="phone"
                label="Telefoonnummer"
                // pattern={"^\d{10}$"}
                placeholder="Telefoonnummer"
                value={this.props.visit.customer.info.phoneNumber}
                onChangeHandler={(event) =>
                  this.setState({ formFieldUpdate: true })
                }
              />
            </div>
            <div className="column small-12 medium-6">
              <StepFormInput
                type="number"
                required={true}
                name="residents"
                label="Aantal bewoners"
                placeholder="Aantal bewoners"
                value={this.props.visit.customer.info.residents}
                onChangeHandler={(event) => this.setState({ updateFlag: true })}
              />
              <Input
                type="number"
                required={true}
                name="consumption"
                label="Jaarlijks verbruik (in kWh)"
                placeholder="Jaarlijks verbruik (in kWh)"
                defaultValue={this.props.visit.customer.info.consumption}
                onChangeHandler={(event) => this.setState({ updateFlag: true })}
              />

              <Input
                type="number"
                required={true}
                step={0.01}
                name="price"
                label="kWh prijs (in €)"
                placeholder="kWh prijs (in €)"
                defaultValue={this.props.visit.customer.info.price}
                onChangeHandler={(event) => this.setState({ updateFlag: true })}
              />

              <div
                className="flex-space-between input-row"
                style={{
                  display: this.props.visit.quickScan ? "none" : "flex",
                }}
              >
                <Input
                  type="date"
                  required={true}
                  name="date"
                  label="Datum bezoek"
                  min={new Date().toISOString().split("T")[0]}
                  // min={this.state.minimumTime}
                  defaultValue={this.props.visit.customer.info.date}
                  placeholder="Datum bezoek"
                  onChangeHandler={(event) => {
                    this.dateChangeHandler();
                    this.setState({ formFieldUpdate: true });
                  }}
                />
                <Input
                  type="time"
                  required={true}
                  name="time"
                  min={this.state.minimumTime}
                  label="Tijd bezoek"
                  defaultValue={this.props.visit.customer.info.time}
                  placeholder="Tijd bezoek"
                  onChangeHandler={(event) => {
                    this.setState({ formFieldUpdate: true});
                  }}
                />
              </div>
            </div>
          </div>
        </form>

        {!this.props.visit.visitPageLocation && (
          <div className="row-padding navigation-buttons">
            <Button
              icon={false}
              onClickHandler={this.fillMockData}
              size="small"
              color="blue"
              label="Mockdata"
              isDisabled={false}
            />

            {!this.props.visit.quickScan ? (
              <>
                {/* <Checkbox
                  name="info"
                  label="Get Design"
                  checked={this.state.persistFlag}
                  onChangeHandler={(event) =>
                    this.setState({ persistFlag: !this.state.persistFlag })
                  }
                /> */}
                <Button
                  icon={false}
                  size="large"
                  color="green"
                  label={`${
                    this.props.visit.newVisit ? "Start bezoek" : "Hervat bezoek"
                  }`}
                  type="submit"
                  form="visitPlan"
                  isDisabled={false}
                />
              </>
            ) : (
              <Button
                icon={false}
                size="large"
                color="green"
                label="Start Quick scan"
                type="submit"
                form="visitPlan"
                isDisabled={false}
              />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default VisitPlan;
