import React, { useState } from "react";
import Button from "../Ui/Buttons/Button";
import Input from "../Ui/Inputs/Input";
import { trackPromise } from "react-promise-tracker";
import { postWithDelay } from "../../global/PostWithDelay";
import constants from "../../global/Constants";
// import logo from '../../assets/images/logo.png'

const LoginCustomer = ({ setInstallerHandler, setToken }) => {
  const [disabled, checkForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCheckLogin = () => {
    const usernameInput = document.querySelector("#usernameText");
    const passwordInput = document.querySelector("#passwordPassword");
    const userCredentials = {
      emailId: usernameInput !== null ? usernameInput.value : "",
      password: passwordInput !== null ? passwordInput.value : "",
    };

    trackPromise(
      postWithDelay(constants.api.login, 1000, userCredentials).then((data) => {
        if (data.token !== undefined) {
          setToken(data);
          setInstallerHandler(data);
          localStorage.setItem("profileId", data.profileId);
        } else {
          setErrorMessage(
            data.status === 401
              ? constants.errorMessages.wrongLogin
              : constants.errorMessages.genericLogin
          );
        }
      })
    );
  };

  const handleFormCheck = () => {
    let isValid =
      document.querySelector("#usernameText").value !== "" &&
      document.querySelector("#passwordPassword").value !== "";

    if (isValid) {
      checkForm(true);
    } else checkForm(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleCheckLogin();
  };

  return (
    <div className="row-padding flex-center">
      <div className="column small-12 medium-8 large-6">
        {/* <div className="login-logo flex-center"> */}
          {/* <img src={require("../../assets/images/logo.png")} alt="Solar4All" /> */}
        {/* </div> */}
        <h3 style={{marginTop:"123px"}}>Login</h3>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Input
            type="text"
            onChangeHandler={handleFormCheck}
            name="username"
            label="Gebruikersnaam"
            placeholder="Gebruikersnaam"
            autoComplete="new-password"
          />
          <Input
            type="password"
            onChangeHandler={handleFormCheck}
            name="password"
            label="Wachtwoord"
            placeholder="Wachtwoord"
            autoComplete="new-password"
          />
          <div className="flex-space-between flex-center">
            <Button
              type="sbumit "
              icon={false}
              // onClickHandler={handleCheckLogin}
              size="large"
              color="green"
              label="Login"
              isDisabled={!disabled}
            />
            <a
              href="https://www.solar4all.eu/my-account/lost-password/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                type="button"
                icon={false}
                size="large"
                color="green"
                label="Wachtwoord vergeten"
              />
            </a>
          </div>
        </form>
        {errorMessage !== "" ? <p>{errorMessage}</p> : null}
      </div>
    </div>
  );
};

export default LoginCustomer;
