import { connect } from 'react-redux';
// import Table from "../components/Home/Table";
import CRMTable from '../components/CRM/CRMTable';
import {
    
    CUSTOMER_DETAILS
} from '../state/actions/actionTypes'

const mapStateToProps = (state) => {
    return {
        visit: state.visit,
        contractor: state.contractor
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        customerDetail: (customer) => {
            dispatch({
                type: CUSTOMER_DETAILS,
                customer: customer
            });
        }
    };
};

const TableContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CRMTable);

export default TableContainer;
