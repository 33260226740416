import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faHome, faCalendarAlt, faCog, faSignOutAlt , faBars, faClock, faProjectDiagram, faIdCard, faCalendar, faTachometerAlt,faUsers} from '@fortawesome/free-solid-svg-icons';

import { routePath } from '../../global/RouteConstants';
import "./SideBar.css";

class SideBar extends Component {
    constructor(props) {
        super(props);

        this.overlay = React.createRef();
        this.state = {
            visibility: 'none'
        };

        this.handleOverlayClick = this.handleOverlayClick.bind(this);
        this.setLogout = this.setLogout.bind(this);
    }

    handleOverlayClick(event) {
        if (event.target === this.overlay.current) {
            this.props.handleModalClose();
        }
    }

    setLogout(event) {
        event.preventDefault();

        sessionStorage.clear();
        this.props.history.go('/');
    }

    componentDidUpdate(prevProps) {
        if (this.props.show !== prevProps.show) {
            setTimeout(() => {
                this.setState({visibility: this.props.show ? 'block' : 'none'});
            }, 100);
        }
    }

    render() {
        const {
            handleModalClose,
            show,
            theme
        } = this.props;
       
        const overlayClassName = show ? 'sidebar sidebar--show' : 'sidebar';
        const ModalStyle = {
            display: this.state.visibility
        };

        return (
            <div ref={this.overlay} style={ModalStyle} onClick={this.handleOverlayClick} className={overlayClassName}>
                <div className="sidebar-menu" style={{backgroundColor:theme.theme}}>
                    <div className="sidebar-top">
                        <FontAwesomeIcon icon={faTimes} onClick={handleModalClose} className="button"/>
                        <h4>Menu</h4>
                    </div>
                    <Link to={"/home"}>
                        <div className="sidebar-menu-item img-icon-div">
                            <img src={require('../../assets/images/home.png')} className="icon-image-style" />
                            <span>Home</span>
                        </div>
                    </Link>
                    <Link to={routePath.afspraken}>
                        <div className="sidebar-menu-item img-icon-div">
                            {/* <FontAwesomeIcon icon={faCalendarAlt} /> */}
                            <img src={require('../../assets/images/visitor.png')} className="icon-image-style" />
                            <span>Afspraken</span>
                        </div>
                    </Link>
                       {/* <Link to={"/resourceplanner"}>
                        <div className="sidebar-menu-item img-icon-div">
                        <img src={require('../../assets/images/calendar.png')} className="icon-image-style" />
                            <span>Planning</span>
                        </div>
                    </Link> */}

                    <Link to={routePath.projecten}>
                        <div className="sidebar-menu-item img-icon-div">
                            <img src={require('../../assets/images/solar-house.png')} className="icon-image-style" />
                            <span>Projecten</span>
                        </div>
                    </Link>
                    
                    <Link to={routePath.oplevering}>
                        <div className="sidebar-menu-item img-icon-div">
                            <img src={require('../../assets/images/contract.png')} className="icon-image-style" />
                            <span>Oplevering</span>
                        </div>
                    </Link>

                    <Link to={routePath.taken}>
                        <div className="sidebar-menu-item img-icon-div">
                            <img src={require('../../assets/images/clipboard.png')} className="icon-image-style" />
                            <span>Taken</span>
                        </div>
                    </Link> 

                    <Link to={routePath.dashboard}>
                        <div className="sidebar-menu-item img-icon-div">
                            <img src={require('../../assets/images/statistics.png')} className="icon-image-style" />
                            <span>Dashboard</span>
                        </div>
                    </Link>

                    <Link to={routePath.tickets}>
                        <div className="sidebar-menu-item img-icon-div">
                            <img src={require('../../assets/images/tickets.png')} className="icon-image-style" />
                            <span>Tickets</span>
                        </div>
                    </Link>

                            <Link to={"/settings"}>
                                <div className="sidebar-menu-item bottom-down img-icon-div">
                                    <img src={require('../../assets/images/settings.png')} className="icon-image-style" />
                                    <span>Instellingen</span>
                                </div>
                            </Link>

                        <div className="sidebar-menu-item cursorP img-icon-div" onClick={(event) => this.setLogout(event)}>

                            <img src={require('../../assets/images/logout.png')} className="icon-image-style" />
                            <span>Uitloggen</span>
                        </div>
                    {/* </div> */}
                </div>
            </div>
        );
    }
}

export default SideBar;
