import React from "react";
import TopHeader from "../components/Ui/TopHeader";
import Footer from "../components/Ui/Footer";
import { useSelector } from "react-redux";


const Agenda = (props) =>  {
    const {installerId} = useSelector((state) => state.contractor);
    return (
      <React.Fragment>
        <TopHeader title="Projecten" history={props.history} />
        <div className="user-manager">
          <iframe
          allowFullScreen
          allowTransparency
          height="100%"
          width="100%"
            id="hubspot-frame"
            // https://platformvisions.retool.com/embedded/public/2688dd34-9dca-48d4-ada0-cb605d64c753
            src={`https://platformvisions.retool.com/embedded/public/e723d539-1aab-4391-b5f9-89f98a9db03f`}
          >
          </iframe>
        </div>
        <Footer image={false} />
        <script>
          
        </script>
      </React.Fragment>
    );
  
  };


export default Agenda
