import React from 'react'
import TopHeader from "../components/Ui/TopHeader";
import Footer from "../components/Ui/Footer";

const AssemblyPlanning = (props) => {
    return (
        <React.Fragment>
          <TopHeader title="Planning" history={props.history} />
          <div className="user-manager">
            <iframe
            allowFullScreen
            allowTransparency
            height="100%"
            width="100%"
              id="hubspot-frame"
              src="https://platformvisions.retool.com/embedded/public/c7cd2876-c02d-460d-bf74-82802bec9361"
            >
            </iframe>
          </div>
          <Footer image={false} />
          <script>
            
          </script>
        </React.Fragment>
      );
}

export default AssemblyPlanning
