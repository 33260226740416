import constants from "../../global/Constants";
import { CUSTOMER_DETAILS } from "../actions/actionTypes";



const crmPage = (state = constants.state.crmState, action) => {
    switch (action.type) {
        case CUSTOMER_DETAILS:
            return {
                ...state,
                customer : action.customer
            }
    
        default:
            return state;
    }

};


export default crmPage;