import { connect } from "react-redux";
import Table from "../components/Home/Table";
import {
  RESET_VISIT_FORM,
  SET_CUSTOMER_ADDRESS,
  SET_QUICK_SCAN,
  UPDATE_CUSTOMER_INFO,
  SET_VISIT_ID,
  ADD_ROOF_TYPES,
  SET_NEW_VISIT,
  UPDATE_DESIGN_IMAGE,
  SET_CALCULATION_ID,
  SET_CUSTOMER_QUOTE,
  RESET_FORM_PROGRESS,
  SET_VISIT_IMAGES,
  SET_VISIT_STATUS,
  SET_VISIT_PG_LOCATION,
  SET_FORM_PROGRESS,
  SET_QUOTE_STATUS,
} from "../state/actions/actionTypes";

const mapStateToProps = (state) => {
  return {
    visit: state.visit,
    contractor: state.contractor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setQuickScan: () => {
      dispatch({
        type: SET_QUICK_SCAN,
      });
    },
    setVisitPageLocation: (status) => {
      dispatch({
        type: SET_VISIT_PG_LOCATION,
        visitPageLocation: status,
      });
    },
    setFormProgress: (progress) => {
      dispatch({
        type: SET_FORM_PROGRESS,
        progress: progress,
      });
    },
    resetForm: () => {
      dispatch({
        type: RESET_VISIT_FORM,
      });
      dispatch({
        type: RESET_FORM_PROGRESS,
      });
    },
    setCustomerAddress: (address) => {
      dispatch({
        type: SET_CUSTOMER_ADDRESS,
        address: address,
      });
    },
    setCustomerInfo: (customer) => {
      dispatch({
        type: UPDATE_CUSTOMER_INFO,
        customer: customer,
      });
    },
    setRoofs: (roofs) => {
      dispatch({
        type: ADD_ROOF_TYPES,
        roofs: roofs,
      });
    },
    setVisitId: (id) => {
      dispatch({
        type: SET_VISIT_ID,
        id: id,
      });
    },
    setVisitStatusId: (visitStatusId) => {
      dispatch({
        type: SET_VISIT_STATUS,
        visitStatusId: visitStatusId,
      });
    },
    setIsNewVisit: (isNewVisit) => {
      dispatch({
        type: SET_NEW_VISIT,
        isNewVisit: isNewVisit,
      });
    },

    setDesign: (image) => {
      dispatch({
        type: UPDATE_DESIGN_IMAGE,
        design: image,
      });
    },
    setCalculationId: (id) => {
      dispatch({
        type: SET_CALCULATION_ID,
        id: id,
      });
    },
    setCustomerQuote: (quote) => {
      dispatch({
        type: SET_CUSTOMER_QUOTE,
        quote: quote,
      });
    },
    setCustomerImages: (images) => {
      dispatch({
        type: SET_VISIT_IMAGES,
        images: images,
      });
    },

    setQuoteStatus:(quoteStatus) => {
      dispatch({
        type:SET_QUOTE_STATUS,
        quoteStatus:quoteStatus
      })
    }
  };
};

const TableContainer = connect(mapStateToProps, mapDispatchToProps)(Table);

export default TableContainer;
