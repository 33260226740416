import React from 'react';
import { Link } from 'react-router-dom';
import { routePath } from '../../global/RouteConstants';

const Contractor = ({contractor}) => {
    return (
      <Link to={routePath.home}>
        <img
          src={
            contractor.companyLogo == "../assets/images/logo.png"
              ? require("../../assets/images/logo.png")
              : contractor.companyLogo
          }
          alt={contractor.name}
          key={contractor.companyLogo}
        />
      </Link>
    );
};

export default Contractor;
