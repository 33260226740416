import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Cookies from "universal-cookie";
import Button from "../../Ui/Buttons/Button";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import RoofDesignPopup from "../../Ui/RoofDesignPopup";

const RoofDesign = ({
  plainDesignUrl,
  reloadDesign,
  iframeUrl,
  calculationId,
  imgKey
}) => {
  const bodyElement = document.querySelector("body");
  const [overlay, setOverlay] = useState(false);
  const [popup, setPopup] = useState(false);

  const closeDesignModule = () => setPopup(false);
  const showPopup = () => setPopup(true);

  const openDesignModule = () => {
    disableBodyScroll(bodyElement);
    document.body.ontouchmove = (event) => {
      event.preventDefault();
      return false;
    };
    setOverlay(true);
  };

  const designModuleSubmit = () => {
    closeDesignModule();
    clearAllBodyScrollLocks();
    reloadDesign();
    setOverlay(false);
  };

  useEffect(() => {
    if (document.getElementById("novasol-design-module")) {
      //  const cookies = new Cookies();
      // let expireTime = new Date().toISOString()
      // let hours = new Date().getHours()
      // expireTime = new Date(expireTime).setHours(hours +3);
      // console.log(new Date(expireTime)
      // cookies.set('lang', 'nl-NL',  { expires: new Date(expireTime), sameSite: "none", secure: true });
    }
  });
  return (
    <React.Fragment>
      <RoofDesignPopup
        messagePopup={
          "Weet u zeker dat u deze pagina wilt sluiten? Om de offerte te genereren dient u eerst op 'Download' te klikken"
        }
        titlePopup={"Sluiten"}
        showPopup={popup}
        handleClose={closeDesignModule}
        handleSubmit={designModuleSubmit}
      />
      <div className="flex-space-between">
        <div className="column small-12 medium-12">
          {/* /? plainDesignUrl : require('../../../assets/images/logo.png') */}
          <img className="design-module-preview" id="img-tag-frame" src={plainDesignUrl} alt={`Roof solar panel image`} key={imgKey} />
        </div>
        <div className="column small-12 medium-6">
          <div className="design-module-options">
            <Button
              icon={false}
              iconRight={false}
              size="large"
              color="green"
              label="Open Ontwerp"
              onClickHandler={openDesignModule}
              isDisabled={iframeUrl !== "" ? false : true}
            />
          </div>
        </div>
      </div>
      {ReactDOM.createPortal(
        overlay ? (
          <div className="design-module-wrapper">
            <div className="row-padding flex-center container">
              <div className="column small-12">
                <div className="design-module-tools">
                  <Button
                    icon={false}
                    iconRight={false}
                    size="medium"
                    color="yellow"
                    label="Sluiten"
                    onClickHandler={() => showPopup()}
                    isDisabled={false}
                  />
                  <p>
                    <b>Let op:</b> Sla het design op voordat je deze pagina
                    sluit
                  </p>
                </div>
                <iframe id="novasol-design-module" src={iframeUrl}></iframe>
              </div>
            </div>
          </div>
        ) : null,
        document.querySelector("#root")
      )}
    </React.Fragment>
  );
};

export default RoofDesign;
