import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle, faCamera} from "@fortawesome/free-solid-svg-icons";
import Button from "../../Ui/Buttons/Button";
import Input from "../../Ui/Inputs/Input";
import {clearAllBodyScrollLocks, disableBodyScroll} from "body-scroll-lock";

const PhotoUpload = ({
    showPopup,
    uploadType,
    submitImageHandler,
    buildingId,
    setVisitPhotos,
    handleClose,
    // uploadDetails
}) => {
    const [preview, showPreview] = useState(false);
    const [base64string, setBase64string] = useState('');
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const bodyElement = document.querySelector('body');

    const handleCloseClick = () => {
        removePreviewImage();
        handleClose();
    };

    const handleImageUpload = () => {
        const photoDescription = document.getElementById('descriptionText').value;

        const typeMapping = {
            fuseBox: '09796bf4-d4b3-d0f5-0b69-006a5a9ad16b',
            cableRoute: 'a25987bb-e18f-734e-75c2-00807dd9bd9e',
            other: '1fc2f63c-08b1-7630-3858-00a0e7c57734'
        };

        const photoObject = {
            buildingId: buildingId,
            photoTypeId: typeMapping[uploadType],
            photoTitle: fileName,
            photoDescription: photoDescription,
            photoContent:file,
            isDeleted: false
        };
        
        submitImageHandler(photoObject, uploadType);
        
        setTimeout(() => {
            setVisitPhotos(false,[photoObject]);
        }, 200);
        handleCloseClick();
    };

    const showPreviewImage = (event) => {
        const previewImage = document.getElementById('previewImage');
        const fileReader = new FileReader();
        setFile(event.target.files[0]);
        previewImage.src = URL.createObjectURL(event.target.files[0]);
        previewImage.onload = () => {
            URL.revokeObjectURL(previewImage.src)
        };

        fileReader.onload = (e) => {
            setBase64string(e.target.result.split(';base64,')[1]);
        };

        fileReader.readAsDataURL(event.target.files[0]);

        setFileName(event.target.files[0].name);
        showPreview(true);
    };
    const removePreviewImage = () => {
        document.getElementById('file').value = '';

        setBase64string('');
        setFileName('');
        showPreview(false);
    };

    showPopup ? document.querySelector('html').classList.add('overlay') : document.querySelector('html').classList.remove('overlay');
    showPopup ? disableBodyScroll(bodyElement) : clearAllBodyScrollLocks();

    return ReactDOM.createPortal(
        showPopup ?
            <div className="popup-container">
                <div className="row-padding flex-center popup-wrapper">
                    <div className="column small-12 medium-8 large-6 popup-content">
                        <div className="popup-top">
                            <h2>Foto maken</h2>
                            <FontAwesomeIcon icon={faTimesCircle} className="popup-close" onClick={event => handleCloseClick(event)} />
                        </div>
                        <div className="popup-photo-upload">
                            <h3>Foto</h3>
                            <div className="upload-wrapper">
                                <input type="file" id="file" onChange={event => showPreviewImage(event)} accept="image/*"/>
                                <label htmlFor="file" className={preview ? 'show-preview' : ''}>
                                    <FontAwesomeIcon icon={faCamera} />
                                    Maak een foto
                                </label>
                                <div className={`upload-preview ${preview ? 'show-preview' : ''}`}>
                                    <FontAwesomeIcon icon={faTimesCircle} onClick={removePreviewImage} className="upload-remove"/>
                                    <img id="previewImage" alt="Preview"/>
                                </div>
                            </div>
                            <Input
                                type="text"
                                name="description"
                                label="Omschrijving"
                                placeholder="Omschrijving"
                            />
                            <div className="flex-space-between">
                                <Button
                                    icon={false}
                                    onClickHandler={handleCloseClick}
                                    size="medium"
                                    color="green"
                                    label="Annuleren"
                                />
                                <Button
                                    icon={false}
                                    onClickHandler={handleImageUpload}
                                    size="medium"
                                    color="green"
                                    label="Bevestigen"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null,
        document.querySelector('#root')
    );
};

export default PhotoUpload;
