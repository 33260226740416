import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import Button from "../Ui/Buttons/Button";
import { trackPromise } from "react-promise-tracker";
import { fetchWithDelay } from "../../global/FetchWithDelay";
import constants from "../../global/Constants";
import { routePath } from "../../global/RouteConstants";

function search(nameKey, myArray, searchFor) {
  for (var i = 0; i < myArray.length; i++) {
    if (myArray[i][searchFor] === nameKey) {
      return myArray[i];
    }
  }
}

class VisitTableToday extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visits: [],
      roofQualityData: [],
      roofTypeData: [],
      visit: {},
      visit_detail: {},
      tableRows: 10,
      tablePage:1,
      disableLoadMore: false,
      popup: {
        show: false,
        message: "",
        title: "",
      },
    };

    this.getMoreVisits = this.getMoreVisits.bind(this);
    this.getVisitsFromTomorrow = this.getVisitsFromTomorrow.bind(this);
    this.getCustomerVisit = this.getCustomerVisit.bind(this);
    this.goToCustomerVisit = this.goToCustomerVisit.bind(this);
  }

  getVisitsFromTomorrow() {
    if (this.props.installer && this.props.installer.token !== undefined) {
      const apiUrl = `${constants.api.visits}/${this.props.installer.installerId}/${this.state.tablePage}/10`;
      trackPromise(
        fetchWithDelay(apiUrl, 20, this.props.installer.token)
          .then((data) => {
            const ddate = data.sort(function (a, b) {
              return new Date(a.visitDate) - new Date(b.visitDate);
            });
            if (this.state.visits.length < data.length) {
              this.setState({
                disableLoadMore: false,
              });
            } else {
              this.setState({
                disableLoadMore: true,
              });
            }

            if(data.length !== 0) {
              this.setState({visits: [...this.state.visits, ...ddate]})
            }
          })
          .catch((error) => {
            this.showPopup(error.toString(), "Foutmelding");
          })
      );
    }
  }

  goToCustomerVisit() {
    const visitData = this.state.visit;
    const customerAddress = {
      street: visitData.customer.streetName,
      number: visitData.customer.houseNumber,
      addition: visitData.customer.houseNumberAddition,
      city: visitData.customer.city,
      zipcode: visitData.customer.postalCode,
      country: "nl",
      id: visitData.building !== null ? visitData.building.buildingId : 0,
    };

    let dateStr = visitData.visitDateDisplay.split("-");
    let date = `${dateStr[2]}-${dateStr[1]}-${dateStr[0]}`;
    const customerInfo = {
      firstName: visitData.customer.firstName,
      lastName: visitData.customer.lastName,
      emailAddress: visitData.customer.emailAddress,
      phoneNumber: visitData.customer.mobileNumber,
      residents: visitData.building.buildingAmountResidents,
      consumption: visitData.building.buildingYearlyUsage,
      price: visitData.building.buildingkWhPrice,
      // date: new Date(visitData.visitDate).toISOString().split('T')[0],
      date: date,
      visitDateDisplay: visitData.visitDateDisplay,
      time: visitData.visitTime,
      customerId: visitData.customer.customerId,
    };

    const roofsInfo = [];
    visitData.surfaces.map((roof) => {
      if (!roof.isDeleted && roof.isActive) {
        roofsInfo.push({
          id: roof.roofSurfaceId,
          title: roof.roofName,
          type: roof.roofTypeId
            ? search(roof.roofTypeId, this.state.roofTypeData, "roofTypeId")
                .roofTypeName
            : "",
          status: roof.roofQualityId
            ? search(
                roof.roofQualityId,
                this.state.roofQualityData,
                "roofQualityId"
              ).roofQualityName
            : "",
          panels: roof.numberOfPanels,
          height: "",
          width: "",
          length: "",
          tilt: roof.roofInclination,
          tiles: "",
          side: roof.roofOrientation,
          area: roof.roofArea,
          roofTypeId: roof.roofTypeId ? roof.roofTypeId : "",
          roofTile: roof.roofTile,
          roofQualityId: roof.roofQualityId ? roof.roofQualityId : "",
        });
      }
    });

    const customerDesign = {
      plain: visitData.building.surfaceDesign[0].surfaceDesignUrl,
      marked: visitData.building.surfaceDesign[0].surfaceDesignUrl,
    };

    this.props.setCustomerAddress(customerAddress);
    this.props.setCustomerInfo(customerInfo);
    this.props.setDesign(customerDesign);
    this.props.setRoofs(roofsInfo);
    this.props.setVisitId(visitData.visitId);
    this.props.setVisitStatusId(visitData.visitStatusId);
    this.props.setCalculationId(visitData.calculationId);
    this.props.setCustomerQuote(visitData.quote);
    this.props.setIsNewVisit(false);
    this.props.setVisitPageLocation(false);
    this.props.setQuoteStatus(visitData.quoteStatus);

    if (constants.qouteStatusIdArray.includes(visitData.quote.qouteStatusId)) {
      this.props.setFormProgress([1, 2, 3, 4]);
      setTimeout(() => {
        this.props.history.push(routePath.visitResume);
      }, 200);
    } else {
      this.props.history.push(routePath.visitPlan);
      this.props.setFormProgress([]);
    }
  }

  getCustomerVisit(visitId) {
    const apiUrl = `${constants.api.visit}/${visitId}`;
    if (this.props.installer) {
      trackPromise(
        fetchWithDelay(apiUrl, 0, this.props.installer.token)
          .then((data) => {
            this.setState({
              visit: data,
            });
          })
          .then(() => {
            this.goToCustomerVisit();
          })
          .catch((error) => {
            this.showPopup(error.toString(), "Foutmelding");
          })
      );
    }
  }

  setRoofTypes = () => {
    const apiUrl = `${constants.api.api}/rooftype`;
    if (this.props.installer) {
      trackPromise(
        fetchWithDelay(apiUrl, 0, this.props.installer.token)
          .then((data) => {
            this.setState({ roofTypeData: data });
          })
          .catch((error) => {
            this.showPopup(error.toString(), "Foutmelding");
          })
      );
    }
  };

  setRoofStatus = () => {
    const apiUrl = `${constants.api.api}/roofqualities`;
    if (this.props.installer) {
      trackPromise(
        fetchWithDelay(apiUrl, 0, this.props.installer.token)
          .then((data) => {
            this.setState({ roofQualityData: data });
          })
          .catch((error) => {
            this.showPopup(error.toString(), "Foutmelding");
          })
      );
    }
  };

  getMoreVisits() {
    this.setState({ tableRows: this.state.tableRows + 10, tablePage:this.state.tablePage + 1 }, () => {
      // console.log(this.state.tablePage, this.state.tableRows)
      this.getVisitsFromTomorrow();
    });
    
  }

  isDateInPast(firstDate, secondDate) {
    return (
      new Date(firstDate).setHours(0, 0, 0, 0) <=
      new Date(secondDate).setHours(0, 0, 0, 0)
    );
  }

  hideSignPopup = () => {
    this.setState({
      signPopup: {
        show: false,
      },
    });
  };

  componentDidMount() {
    this.getVisitsFromTomorrow();
    this.setRoofTypes();
    this.setRoofStatus();
  }

  render() {
    const {theme} = this.props.theme;

    const visitsList = this.state.visits.map((visit, index) => {
      const visitDate = new Date(visit.visitDate).toISOString().split("T")[0];
      const todayDate = new Date().toISOString().split("T")[0];
      if (this.state.visits.length > 0) {
        return (
          <Fragment>
            <tr
              key={index}
              onClick={() => this.getCustomerVisit(visit.visitId)}
            >
              <td>{`${visit.customer.firstName} ${visit.customer.lastName}`}</td>
              <td>{visit.customer.mobileNumber}</td>
              <td>{visit.customer.city}</td>
              <td>{`${visit.customer.streetName} ${visit.customer.houseNumber} ${visit.customer.houseNumberAddition}`}</td>
              <td>{`${visit.visitDateDisplay} - ${visit.visitTime}`}</td>
            </tr>
          </Fragment>
        );
      }
    });

    return (
      <React.Fragment>
        <h2 style={{color:theme}}>Vanaf morgen</h2>
        <table id="visitsTomorrow" className="visits-other">
          <thead>
            <tr>
              <th  style={{backgroundColor:theme}}>Contactpersoon</th>
              <th  style={{backgroundColor:theme}}>Tel. Nr</th>
              <th  style={{backgroundColor:theme}}>Plaats</th>
              <th  style={{backgroundColor:theme}}>Adres</th>
              <th  style={{backgroundColor:theme}}>Datum - Tijd</th>
            </tr>
            {visitsList[0] !== undefined ? (
              <React.Fragment>{visitsList}</React.Fragment>
            ) : null}
          </thead>
          {visitsList[0] === undefined ? <p>Geen resultaten gevonden</p> : null}
        </table>
        <div className="flex-center">
          <Button
            icon={<FontAwesomeIcon icon={faSyncAlt} />}
            size="small"
            color="yellow"
            label="Laad meer"
            onClickHandler={this.getMoreVisits}
            isDisabled={
              this.state.disableLoadMore || visitsList[0] === undefined
            }
          />
        </div>
      </React.Fragment>
    );
  }
}

export default VisitTableToday;
