import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, Fragment } from "react";
import { trackPromise } from "react-promise-tracker";
import constants from "../../global/Constants";
import { fetchWithDelay } from "../../global/FetchWithDelay";
import UserDetailPopup from "../Ui/UserDetailPopup";
import { faShoppingCart, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { postWithDelay } from "../../global/PostWithDelay";
import Popup from "../Ui/Popup";

const UserDetailList = ({ visitList, token, reduxProps , themeColor}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [visitData, setVisitData] = useState(null);
  const [installer, setInstaller] = useState(reduxProps.contractor.installer.token !== '' ? reduxProps.contractor.installer : JSON.parse(sessionStorage.getItem('installer')))
  
  const [popup, setPopup] = useState({
    message:"",
    title:"",
    show:false
  })

  const handlePopupClose = () => setShowPopup(false);
  const hidePopup = () => setPopup({
    message:"",
    title:"",
    show:!popup.show
  })


  const visitDetail = (visitId , from) => {
    const apiUrl = `${constants.api.visit}/${visitId}`;
    trackPromise(
      fetchWithDelay(apiUrl, 0, token)
        .then((data) => {
          if(from == "details") updateVisitData(data);
          else if(from == "addToCart") {
            addToCart(data);
          }else if(from == 'pdf') {
            getPdf(data);
          }
        })
        .catch((error) => {
          this.showPopup(error.toString(), "Foutmelding");
        })
    );
  };

  const updateVisitData = (visitData) => {
    const customerAddress = {
      street: visitData.customer.streetName,
      number: visitData.customer.houseNumber,
      addition: visitData.customer.houseNumberAddition,
      city: visitData.customer.city,
      zipcode: visitData.customer.postalCode,
      country: "nl",
      id: visitData.building !== null ? visitData.building.buildingId : 0,
    };

    let dateStr = visitData.visitDateDisplay.split("-");
    let date = `${dateStr[2]}-${dateStr[1]}-${dateStr[0]}`;
    const customerInfo = {
      firstName: visitData.customer.firstName,
      lastName: visitData.customer.lastName,
      emailAddress: visitData.customer.emailAddress,
      phoneNumber: visitData.customer.mobileNumber,
      residents: visitData.building.buildingAmountResidents,
      consumption: visitData.building.buildingYearlyUsage,
      price: visitData.building.buildingkWhPrice,
      date: date,
      visitDateDisplay: visitData.visitDateDisplay,
      time: visitData.visitTime,
      customerId: visitData.customer.customerId,
    };

    const customerDesign = {
      plain: visitData.building.surfaceDesign[0].surfaceDesignUrl,
      marked: visitData.building.surfaceDesign[0].surfaceDesignUrl,
    };

    reduxProps.setCustomerAddress(customerAddress);
    reduxProps.setCustomerInfo(customerInfo);
    reduxProps.setDesign(customerDesign);
    // reduxProps.setRoofs(roofsInfo);
    reduxProps.setVisitId(visitData.visitId);
    reduxProps.setVisitStatusId(visitData.visitStatusId);
    reduxProps.setCalculationId(visitData.calculationId);
    reduxProps.setCustomerQuote(visitData.quote);
    reduxProps.setIsNewVisit(false);
    reduxProps.setVisitPageLocation(true);

    setShowPopup(true);
  };


  const addToCart = (visitData) => {
    var addToCart = []
    if(visitData.quote.qouteProduct.length !== 0) {
        
        visitData.quote.qouteProduct.map((value) => {
          if(value.installerProduct.installerProductSku !=="-") {
            addToCart.push({
                quantity:value.qouteProductQuantity,
                sku:value.installerProduct.installerProductSku
            })
          }
        });
        
    };
    const cartObj = {
        installerId:installer.installerId,
        cartProducts:addToCart
    }
    
     const apiUrl = `${constants.api.api}/Cart/additems`
    trackPromise(
        postWithDelay(apiUrl, 0, cartObj, installer.token)
        .then((data) => {
          if(data && data.cartProducts) {
            setPopup({
              message:"Added to Cart!",
              title:"Success!",
              show:true
            })
          }
          if(data && !data.cartProducts) {
            setPopup({
              message:data.message,
              title:"Foutmelding",
              show:true
            })
          }
          
        })
        .catch((error) => {
            // this.showPopup(error.toString(), 'Foutmelding');
            setPopup({
              message:error.toString(),
              title:"Foutmelding",
              show:true
            })
        })
    )
  }

  const getPdf = (visitId) => {

    const apiUrl = `${constants.api.api}/Visit/sendquotemail/${visitId}`;
    trackPromise(
        fetchWithDelay(apiUrl, 0,installer.token)
        .then((data) => {
            console.log(data);
            if(!data.isSuccess) {
              setPopup({
                message:"Something went worng !",
                title:"Foutmelding",
                show:true
              })
            }else  {
              setPopup({
                message:"Mail sent!",
                title:"Success!",
                show:true
              })
            }
            
        })
        .catch((error) => {
          setPopup({
            message:error.toString(),
            title:"Foutmelding",
            show:true
          })
        })
        )
  }
  const {theme} = themeColor;
  
  const visitsList = visitList.map((visit, index) => {
    if (visitList.length > 0) {
      return (
        <Fragment>
          <tr key={index}>
            <td>{`${visit.customer.firstName} ${visit.customer.lastName}`}</td>
            <td>{visit.customer.mobileNumber}</td>
            <td>{visit.customer.city}</td>
            <td>{`${visit.customer.streetName} ${visit.customer.houseNumber} ${visit.customer.houseNumberAddition}`}</td>
            <td>{`${visit.visitDateDisplay} - ${visit.visitTime}`}</td>
            <td>
              <button
                className="blue detail-btn"
                style={{backgroundColor:theme}}
                onClick={() => visitDetail(visit.visitId, "details")}
              >
                Detail
              </button>
            </td>
            <td style={{textAlign:"center"}}>
                <FontAwesomeIcon  icon={faShoppingCart} className="button" onClick={() => visitDetail(visit.visitId, "addToCart")} />
            </td>
            <td style={{textAlign:"center"}}>
            <FontAwesomeIcon  icon={faFilePdf} className="button"  onClick={() => getPdf(visit.visitId)} />
            </td>
          </tr>
        </Fragment>
      );
    }
  });

  

  return (
    <Fragment>
      <UserDetailPopup showPopup={showPopup} handleClose={handlePopupClose} />
      <table id="visitsTomorrow" className="visits-other">
        <thead>
          <tr className="table-header-color">
            <th>Contactpersoon</th>
            <th>Tel. Nr</th>
            <th>Plaats</th>
            <th>Adres</th>
            <th>Datum - Tijd</th>
            <th>Details</th>
            <th>In winkelwagen</th>
            <th>Verstuur contract</th>
          </tr>
          {visitsList[0] !== undefined && <Fragment>{visitsList}</Fragment>}
        </thead>
        {visitsList[0] === undefined && <p>Geen resultaten gevonden</p>}
      </table>
      <Popup
          messagePopup={popup.message}
          titlePopup={popup.title}
          showPopup={popup.show}
          handleClose={hidePopup}
      />
      <style>
          {`
          \
          .table-header-color th{\
              background-color:${theme} !important;\
          }\
          \
          table tr:hover{\
              background-color:${theme} !important;\
          }\
          
          
          `}
        </style>
    </Fragment>
  );
};

export default UserDetailList;
