import { connect } from 'react-redux';
import Contractor from "../components/Ui/Contractor";

const mapStateToProps = (state) => {
    return {
        contractor: state.contractor
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

const ContractorContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Contractor);

export default ContractorContainer;
