import React from 'react';
import Select from "../../Ui/Inputs/Select";
import constants from "../../../global/Constants";
import Radio from "../../Ui/Inputs/Radio";

const RoofStatus = ({
    roof,
    roofTypes,
    roofStatus
}) => {
    const roofTiles = {
        name: roof.tiles,
        value: roof.tiles
    };
    return (
        <React.Fragment>
            <div className="flex-space-between flex-direction-column">
                <Select
                    options={roofTypes.length > 0 ? roofTypes : ['-']}
                    defaultOption={roof.type !== '' ? roof.type : undefined}
                    name={`type-${roof.id}`}
                    label="Type dak"
                    isRow={true}
                />
                <Select
                    options={roofStatus.length > 0 ? roofStatus : ['-']}
                    defaultOption={roof.status !== '' ? roof.status : undefined}
                    name={`status-${roof.id}`}
                    label="Status dak"
                    isRow={true}
                />
            </div>
            <Radio
                options={constants.roofTiles}
                defaultOption={roof.tiles !== '' ? roofTiles : constants.roofTiles[0]}
                name={`tiles-${roof.id}`}
                label="Dakpannen"
            />            
        </React.Fragment>
    )
};

export default RoofStatus;
