import React, { Component } from 'react';
import ContractorContainer from "../../containers/ContractorContainer";
import Button from "../Ui/Buttons/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import ResetPopup from "../Ui/ResetPopup";
import NavigationPrompt from "react-router-navigation-prompt";
import SignPopup from "../Ui/SignPopup";
import Calculation from "./Calculation/Calculation";
import Input from "../Ui/Inputs/Input";
import Checkbox from "../Ui/Inputs/Checkbox";
import {trackPromise} from "react-promise-tracker";
import constants from "../../global/Constants";
import Popup from "../Ui/Popup";
import  ProgressBar  from "../Ui/ProgressBar";
import {putWithDelay} from '../../global/PutWithDelay';
import { fetchWithDelay } from '../../global/FetchWithDelay';
import { postWithDelay } from '../../global/PostWithDelay';
import { routePath } from '../../global/RouteConstants';

class VisitResume extends Component {
    constructor(props) {
        super(props);

        this.submit = '/home';
        this.change = '/visit-plan';
        this.redirect = '/visit-plan';

        this.state = {
            formStep: 4,
            signPopup: {
                show: false
            },
            popup: {
                show: false,
                message: '',
                title: '',
            },
            installer: this.props.contractor.installer.token !== '' ? this.props.contractor.installer : JSON.parse(sessionStorage.getItem('installer')),
            themeColor:this.props.contractor,
            getMailFlag:false,
            addToCartFlag:false,
            disableCart:false
        };

        this.quotationEndpoint = `${constants.api.api}/${constants.api.quotation}`;
    }

    submitCompleteForm = () => {
        this.getMail();
        return this.updateVisit();
    };

    getMail = () => {
        const {visit} = this.props;
        const apiUrl = `${constants.api.api}/Visit/sendquotemail/${visit.id}`;
        trackPromise(
            fetchWithDelay(apiUrl, 0,this.state.installer.token)
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                this.showPopup(error.toString(), 'Foutmelding');
            })
            )
    }

    addToCart = () => {
        const {visit} = this.props;
        var addToCart = []
        if(visit.quote.qouteProduct.length !== 0) {
            
            visit.quote.qouteProduct.map((value) => {
                addToCart.push({
                    quantity:value.qouteProductQuantity,
                    name:value.installerProduct.installerProductName
                })
            });
            
        };
        const cartObj = {
            installerId:this.state.installer.installerId,
            cartProducts:addToCart
        }
         const apiUrl = `${constants.api.api}/Cart/additems`
        trackPromise(
            postWithDelay(apiUrl, 0, cartObj, this.state.installer.token)
            .then((data) => console.log(data))
            .catch((error) => {
                this.showPopup(error.toString(), 'Foutmelding');
            })
        )
        
    }
    updateVisit = () => {
        const {visit} = this.props;

        const updateVisit = {
            visit:{
                visitId: visit.id,
                visitStatusId: visit.visitStatusId
            },
            customer:{
                customerId: visit.customer.info.customerId,
                newsletter: visit.newsletter
            },
            roofSurface:[],
            quote:{
                qouteId: visit.quote.qouteId,
                qouteSignatureUrl: visit.signature,
                remark: this.props.visit.comments === '' ? 'N.v.t.' : this.props.visit.comments,
                deliveryDate: "2021-04-22T14:36:48.646Z",
                qouteStatusId: visit.quote.qouteStatusId
            }
        };
        visit.roofs.map((roof, index) => {
            updateVisit.roofSurface.push({
                    roofSurfaceId: roof.id,
                    roofTypeId: roof.roofTypeId,
                    roofQualityId: roof.roofQualityId,
                    roofTile: roof.roofTile
                })
        });
        trackPromise(
            putWithDelay(constants.api.updatevisit, 0, updateVisit,  this.state.installer.token)
                .then((data) => {
                    if(data.isSuccess) {
                        this.showPopup("Bezoek is succesvol opgeslagen".toString(), 'Versturen gelukt');
                        
                    }else if(!data.isSuccess){
                        this.showPopup('Het is niet gelukt het bezoek op te slaan'.toString(), 'Foutmelding');
                    }
                   
                })
                .catch(error => {
                    this.showPopup(error.toString(), 'Foutmelding');
                })
        );
    }

    showSignPopup = () => {
        this.setState({
            signPopup: {
                show: true
            }
        });
    };

    hideSignPopup = () => {
        this.setState({
            signPopup: {
                show: false,
            }
        });
    };

    showPopup = (message, title) => {
        this.setState({
            popup: {
                show: true,
                message: message,
                title: title
            }
        });
    };

    hidePopup = () => {
        if(this.state.popup.message === "Bezoek is succesvol opgeslagen"){
            this.props.history.push(this.submit);
            this.props.resetForm();
        }
        this.setState({
            popup: {
                show: false,
                message: '',
                title: ''
            }
        });
    };

    changeQuote = () => {
        this.props.setFormProgress([]);
        this.props.history.push(this.change);
    };

    
    // submitForm = () => this.showSignPopup();
    componentDidMount() {
        this.props.resetQuickScan();
        // disableCart
        const {visit} = this.props;
        if(visit && visit.quote && visit.quote.qouteProduct && visit.quote.qouteProduct.length === 0) {
            this.setState({disableCart:true});
        }
    }

//   componentDidUpdate(prevProps) {
//       if(this.props.progress !== prevProps.progress) {
//           if (!this.props.progress.includes(this.state.formStep)) this.props.history.push(this.redirect)
//       }
//   }
    render() {
        const {theme} = this.state.themeColor;
        if (!this.props.progress.includes(this.state.formStep)) {
            this.props.history.push(this.redirect);

            return null;
        } else {
            const roofs = this.props.visit.roofs.map((roof, index) => (
                <div className="flex-space-between input-row summary" key={index}>
                    <Input
                        type="text"
                        readonly={true}
                        name={roof.title}
                        label="Dakvlak"
                        placeholder="Dakvlak"
                        defaultValue={roof.title}
                    />
                    <Input
                        type="number"
                        readonly={true}
                        name={`panels${roof.title}`}
                        label="Panelen"
                        placeholder="Panelen"
                        defaultValue={roof.panels}
                    />
                </div>
                )
            );

            return (
                <React.Fragment>
                    <NavigationPrompt
                        when={(current, target) => {
                            return ![this.change, this.submit, current.pathname, routePath.visitInfo].includes(target.pathname)
                        }}
                        afterConfirm={() => this.props.resetForm()}
                    >
                        {({ onConfirm, onCancel }) => (
                            <ResetPopup
                                showPopup={true}
                                onConfirm={onConfirm}
                                onCancel={onCancel}
                            />
                        )}
                    </NavigationPrompt>
                
                    <Popup
                        messagePopup={this.state.popup.message}
                        titlePopup={this.state.popup.title}
                        showPopup={this.state.popup.show}
                        handleClose={this.hidePopup}
                    />
                    <div className="section-logo flex-end flex-align-center">
                        <h2 className="address-title" style={{color:theme}}>
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                            {`${this.props.visit.customer.address.street} ${this.props.visit.customer.address.number}, ${this.props.visit.customer.address.city}, ${this.props.visit.customer.address.country.toUpperCase()}`}
                        </h2>
                        <ContractorContainer/>
                    </div>
                    {!this.props.visit.quickScan ? (
                        <ProgressBar progress={this.props.progress} />
                    ) : null}
                    <div className="row-padding flex-center">
                        <div className="column small-12 medium-7">
                            <h3 style={{color:theme}}>Persoonlijke gegevens</h3>
                            <div className="flex-space-between input-row">
                                <Input
                                    type="text"
                                    readonly={true}
                                    name="firstname"
                                    label="Voornaam"
                                    placeholder="Voornaam"
                                    defaultValue={this.props.visit.customer.info.firstName}
                                />
                                <Input
                                    type="text"
                                    readonly={true}
                                    name="lastname"
                                    label="Achternaam"
                                    placeholder="Achternaam"
                                    defaultValue={this.props.visit.customer.info.lastName}
                                />
                            </div>
                            <Input
                                type="email"
                                readonly={true}
                                name="email"
                                label="E-mailadres"
                                placeholder="E-mailadres"
                                defaultValue={this.props.visit.customer.info.emailAddress}
                            />
                            <Input
                                type="tel"
                                readonly={true}
                                name="phone"
                                label="Telefoonnummer"
                                placeholder="Telefoonnummer"
                                defaultValue={this.props.visit.customer.info.phoneNumber}
                            />
                            <Input
                                type="text"
                                readonly={false}
                                name="comments"
                                label="Opmerkingen"
                                placeholder="Opmerkingen"
                                defaultValue={this.props.visit.comments === '' ? 'N.v.t.' : this.props.visit.comments}
                            />
                            <Checkbox
                                name="info"
                                label="Klant ontvangt graag meer informatie"
                                checked={this.props.visit.newsletter}
                                onChangeHandler={(event => this.props.submitNewsletter(event.target.checked))}
                            />
                            <div className="flex-space-between input-row summary">
                                <Input
                                    type="text"
                                    readonly={true}
                                    name="consumption"
                                    label="Jaarlijks verbruik (in kWh)"
                                    placeholder="Jaarlijks verbruik (in kWh)"
                                    defaultValue={this.props.visit.customer.info.consumption}
                                />
                                <Input
                                    type="number"
                                    readonly={true}
                                    name="price"
                                    label="kwh prijs"
                                    placeholder="kwh prijs"
                                    defaultValue={this.props.visit.customer.info.price}
                                />
                            </div>
                            <h3 style={{color:theme}}>Dakvlakken</h3>
                            {roofs}
                        </div>
                        <div className="column small-12 medium-3">
                            <h3 style={{color:theme}}>Overzicht offerte</h3>
                            <div className="calculation-table-wrapper">
                                <Calculation
                                    roofs={this.props.visit.roofs}
                                    quote={this.props.visit.quote}
                                />
                                {
                                    this.props.visit.quoteStatus && this.props.visit.quoteStatus !== "" &&   <table className="calculation-table">
                                    <tbody>
                                       <tr>
                                           <td>Contract Status</td>
                                           <td>{this.props.visit.quoteStatus}</td>
                                       </tr>
                                    </tbody>
                                    </table>
                                }
                                
                                <Button
                                    icon={false}
                                    size="medium"
                                    color="yellow"
                                    label="Offerte aanpassen"
                                    onClickHandler={this.changeQuote}
                                    isDisabled={false}
                                />
                                <Button
                                    icon={false}
                                    size="medium"
                                    color="green"
                                    label="Verstuur opdrachtbevestiging"
                                    // onClickHandler={this.submitForm}
                                    onClickHandler={this.submitCompleteForm}
                                    isDisabled={false}
                                />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }
}

export default VisitResume;
