import {SET_CONTRACTOR_INSTALLER, UPDATE_CONTRACTOR_INFORMATION} from '../actions/actionTypes';
import constants from '../../global/Constants';

const contractor = (state = constants.state.contractor, action) => {
    switch (action.type) {
        case UPDATE_CONTRACTOR_INFORMATION:
            return action.contractor;

        case SET_CONTRACTOR_INSTALLER:
            return {
            ...state,
            companyLogo:action.installer.companyLogo, 
            installerId:action.installer.installerId,
            profileId:action.installer.profileId ,
            token:action.installer.token,
            installer: action.installer,
            theme:action.installer.colorCode
        };
        default:
            return state;
    }
};

export default contractor;
