import {
  UPDATE_VISIT_IMAGES,
  REMOVE_VISIT_IMAGES,
  UPDATE_DESIGN_IMAGE,
  UPDATE_ROOF_INFO,
  ADD_ROOF_TYPES,
  SET_MARKED_DESIGN,
  SET_PLAIN_DESIGN,
  SET_CUSTOMER_ADDRESS,
  UPDATE_CUSTOMER_INFO,
  RESET_VISIT_FORM,
  SET_MOCK_DATA,
  SET_PRODUCT_CHOISE,
  SET_COMMENTS,
  SET_CUSTOMER_SIGNATURE,
  SET_CUSTOMER_NEWSLETTER,
  REMOVE_CUSTOMER_SIGNATURE,
  SET_QUICK_SCAN,
  SET_QUICK_SCAN_INFO,
  SET_VISIT_ID,
  SET_NEW_VISIT,
  SET_CALCULATION_ID,
  SET_CUSTOMER_QUOTE,
  SET_VISIT_IMAGES,
  RESET_QUICK_SCAN,
  SET_VISIT_STATUS,
  SET_VISIT_PG_LOCATION,
  SET_QUOTE_STATUS
} from "../actions/actionTypes";
import constants from "../../global/Constants";

const removePhoto = (state, action) => {
  const removedPhotos = state.images[action.upload].map((photo) => {
    if (photo.buildingPhotoId === action.identifier) {
      photo.isDeleted = true;
    }

    return photo;
  });

  return {
    ...state,
    images: { ...state.images, [action.upload]: removedPhotos },
  };
};

const visit = (state = constants.state.visit, action) => {
  switch (action.type) {
    case RESET_VISIT_FORM:
      return constants.resetState;

    case SET_QUICK_SCAN:
      return {
        ...state,
        quickScan: true,
      };
    case SET_QUOTE_STATUS:  
    return {
        ...state,
        quoteStatus:action.quoteStatus
      }
    case SET_QUICK_SCAN_INFO:
      return {
        ...state,
        roofs: constants.quickScanDefaults.roofs,
        products: constants.quickScanDefaults.products,
      };

    case SET_MOCK_DATA:
      return Object.assign(state, action.mock);

    case SET_CUSTOMER_ADDRESS:
      return {
        ...state,
        customer: { ...state.customer, address: action.address },
      };

    case UPDATE_CUSTOMER_INFO:
      return {
        ...state,
        customer: { ...state.customer, info: action.customer },
      };

    case SET_VISIT_IMAGES:
      return {
        ...state,
        images: action.images,
      };

    case UPDATE_VISIT_IMAGES:
      return {
        ...state,
        images: {
          ...state.images,
          [action.upload]: [...state.images[action.upload], action.photo],
        },
      };

    case REMOVE_VISIT_IMAGES:
      return removePhoto(state, action);

    case SET_PLAIN_DESIGN:
      return {
        ...state,
        design: { ...state.design, plain: action.design },
      };

    case SET_MARKED_DESIGN:
      return {
        ...state,
        design: { ...state.design, marked: action.design },
      };

    case UPDATE_DESIGN_IMAGE:
      return {
        ...state,
        design: action.design,
      };

    case ADD_ROOF_TYPES:
      return {
        ...state,
        roofs: action.roofs,
      };

    case UPDATE_ROOF_INFO:
      return {
        ...state,
        roofs: action.roofs,
      };

    case SET_COMMENTS:
      return {
        ...state,
        comments: action.comments,
      };

    case SET_PRODUCT_CHOISE:
      return {
        ...state,
        products: { ...state.products, [action.property]: action.product },
      };

    case SET_CUSTOMER_NEWSLETTER:
      return {
        ...state,
        newsletter: action.newsletter,
      };

    case SET_CUSTOMER_SIGNATURE:
      return {
        ...state,
        signature: action.signature,
      };

    case REMOVE_CUSTOMER_SIGNATURE:
      return {
        ...state,
        signature: "",
      };

    case SET_VISIT_ID:
      return {
        ...state,
        id: action.id,
      };
    case SET_VISIT_STATUS:
      return {
        ...state,
        visitStatusId: action.visitStatusId,
      };
    case SET_CALCULATION_ID:
      return {
        ...state,
        calculationId: action.id,
      };

    case SET_NEW_VISIT:
      return {
        ...state,
        newVisit: action.isNewVisit,
      };

    case SET_CUSTOMER_QUOTE:
      return {
        ...state,
        quote: action.quote,
      };
    case SET_VISIT_PG_LOCATION:
      return {
        ...state,
        // This state will determine were is the page opened from
        visitPageLocation: action.visitPageLocation,
      };

    case RESET_QUICK_SCAN:
      return {
        ...state,
        quickScan: false,
      };

    default:
      return state;
  }
};

export default visit;
